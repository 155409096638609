//import React, { useState } from "react"
import React, { Link, useState, useEffect } from "react"
import '../../stylesheets/Amenities.css'
import AOS from 'aos'
import Modal from './ImageCollectionViewer/Modal';
import { v1 as uuidv1 } from 'uuid';

import { Container, Button, Row } from "react-bootstrap"
import Navbar from "./../google-drive/Navbar"
import CalendarModal from "./CalendarModal"

import bar1 from "./../../images/bar1.jpg"
import bar2 from "./../../images/bar2.jpg"
import bar3 from "./../../images/bar3.jpg"

import { storage } from "./../../firebase"
import { useAuth } from "../../contexts/AuthContext"

import "./Amenities.css"

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');
console.log(bootstrap)

//$('#List').find('tbody').html('')

//setTimeout(function () { AOS.init(); }, 5000);

export default function AmenitiesBlock({name, title, desc, 
                                        timeDuration, beginTime, restrictionSpec, 
                                        containerID,  amenitiesNotSub, UpdateSubscription}) {

//  const [ images, setImages ] = useState([])
//  const [ one, setOne ] = useState("")
//  const [ ready, setReady ] = useState(false)

//  const refImages = [bar1, bar2, bar3]

  const { currentUser, logout } = useAuth()

  const [selectedImg, setSelectedImg] = useState(null);
  const [subOnFlag, setSubOnFlag] = useState(true);

  const rimages = [
      "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/PUBLIC%2Fsys_amenities%2FPool%2Fbar2.jpg?alt=media&token=15c8272d-fff2-439a-ad6e-c2b5c4792a0e", 
      "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/PUBLIC%2Fsys_amenities%2FPool%2Fbar3.jpg?alt=media&token=8b248e84-bbf4-48a1-b398-2bbc05a4c326", 
      "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/PUBLIC%2Fsys_amenities%2FPool%2Fbar1.jpg?alt=media&token=4b6f5272-f938-498b-bb0f-e2a0d18df985"   
  ]

  useEffect(() => {
    console.log("Updating Subscriber btn for Block",amenitiesNotSub, name)
    if( amenitiesNotSub.length > 0  && amenitiesNotSub.indexOf(name) > -1 ) // name of amenity is in the array -> then clear flag
    {
      setSubOnFlag(false)
      console.log("Set FAlse as unSub is true")
    }
  }, [amenitiesNotSub]);
  
  const alerting = () => {       
//    setSelectedImg(fileUrl)
    console.log("SIMPLE REPORTING:")
  } 

  useEffect(() => {

    AOS.init({
        easing: 'ease-in-out-sine',
/*         offset: 60, once: false,  disable: 'phone', duration: 300,
        initClassName: 'aos-init',
        animatedClassName: 'aos-animate',
        useClassNames: false,
        disableMutationObserver: false,
        debounceDelay: 50,
        throttleDelay: 99,
 */    });
    AOS.refresh();

        let urls = []
        let aaa="AAA"
        console.log("COMPONENT STARTING: ", amenitiesNotSub)

        const imgRef = storage.ref(`PUBLIC/sys_amenities`)
        const imgList = imgRef.child(`${name}`)
//        const imgList = imgRef.child("Pool")
        imgList.listAll().then((res) => {
            res.items.forEach((itemRef, index) => {
                itemRef.getDownloadURL().then(url => {
                    const iid = uuidv1();
                    let fade = ''
                    console.log("Retrieved NAME: ", iid, url)
                    if(index==0)
                        fade += 'fade-right'
                    if(index==1)
                        fade += 'fade-up'
                    if(index==2)
                        fade += 'fade-left'

                    let arr_html = ''
                    arr_html = '<div id="view-img-'+iid+'" className="viewmg" data-aos="'+fade+'" data-aos-duration="2100">'
                    arr_html += '<img id="viewimg" src="'+url+'"width="480vw" height="413vw" alt="bar" />'
                    arr_html += '</div>'

                    var el = $(arr_html);
////                    el.setAttribute("id", "rcorners-img")
//                    $(`#${name}`).find('container').append(arr_html)
                    $(`#${containerID}`).find('container').append(el)

                    document.getElementById("view-img-"+iid).style.marginLeft = "6px";
                    document.getElementById("viewimg").setAttribute("id", "rcorners-img");

                    console.log("INJECTING: ", arr_html)

                    let viewImgOverlay = (e) => {
                      console.log("Got HITITTIT: ",e.target.src)
                      setSelectedImg(e.target.src)
                    }
                    document.getElementById(`view-img-${iid}`).addEventListener('click', viewImgOverlay );
                    
                    /*                     let new_html = ''
                    new_html += '<tr>'
                    new_html += '<td>'
                    new_html += index
                    new_html += '</td>'
                    new_html += '<td>'
                    new_html += '<img src="'+url+'" width="100px" style="float:right">'
                    new_html += '</td>'
                    new_html += '</tr>'
                    $('#List').find('tbody').append(new_html)
 */
//                    urls.push(url)
//                    setReady(true)
                })
            })
        })
  }, []);
  
  const reportdata = () => {
    console.log("SIMPLE REPORTING:")
  } 
  const handleCheckSub = (e) => {
    console.log("handleCheckSub: ", e.target.checked)
    setSubOnFlag(e.target.checked)
    const action = e.target.checked ? "add" : "del"
    UpdateSubscription(name, action)
  } 

/*   if( !(images && images.length > 2) )
   return <h1>Loading data ...</h1>
 */
  return (
    <>
{/*     <span>{images[0]}</span> <hr/>
    <span>{images[1]}</span> <hr/>
    <span>{images[2]}</span> <hr/>
    <hr/>
    <button onClick={reportdata}></button>

    <hr/>
    <table id="List">
        <tbody>

        </tbody>
    </table>

    <a href={images[2]} target="_blank">
      <Button onClick={reportdata}> {images[2]} </Button>
    </a>
 */}
{/* {ready && <Link to={images[2]}>{images[2]}</Link>}
 */}
{/*  {ready && <span>{images[2]}</span>}
<hr/>
 */} 
      <section id="images" className="images">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between">
            <h1 data-aos="fade-down" data-aos-duration="2200">{name ? name : "LOADING"}</h1>

{/*             <CalendarModal resourceId="bar"
                           name={name}
                           timeDuration={timeDuration}
                           beginTime={beginTime}
                           restrictionSpec={restrictionSpec} />
 */}
             { currentUser && 
              <div class="checkbox">
                <label>
                  <input type="checkbox" checked={subOnFlag} onClick={(e)=>handleCheckSub(e)}/>
                  Subscribed
                </label>
              </div> 
            }
          </div>
        </Container>
        <div id={containerID} className="row" style={{maxWidth: "98vw"}}>

          <div id="ImgOverLay" style={{zIndex: 10}}>
            { selectedImg && (
              <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
            )}          
          </div>

          <container style={{display: 'flex'}}>
{/*           { images && images[0] && 
            <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
                <img src={images[0]} className="w-100" alt="bar"/>
            </div>
          }
          { images && images[1] && 
            <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
                <img src={images[0]} className="w-100" alt="bar"/>
            </div>
          }
          { images && images[2] && 
            <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
                <img src={images[0]} className="w-100" alt="bar"/>
            </div>
          }
 */}        


{/*           <div>
            <img src={images[0]} alt="bar"/>
          </div>
          <div>
            <img src={images[1]} alt="bar"/>
          </div>
 */} 
{/*           <div>
            <img src={refImages[2]} alt="bar"/>
          </div>
 */}{/*           <div className="col-md-4">
            <img src={images[2]} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4">
            <img src={images[0]} className="w-100" alt="bar"/>
          </div>
 */}

{/*         <Container>
            <Image 
                style={{width: 150, height: 150 }}
                src={require('./../../images/bar1.jpg')}/>
            <Image 
                style={{width: 150, height: 150 }}
                src={images[2]}/>
            {images && images.length > 2 && 
              <Image 
                style={{width: 150, height: 150 }}
                src={rimages[2]}/>
            }
        </Container>
 */}
{/*         <div style={{display: 'flex'}}>
            <img src={rimages[1]} className="w-40" alt="bar"/>
            <img src={rimages[2]} className="w-30" alt="bar"/>
            <img src={rimages[0]} className="w-30" alt="bar"/>
        </div>
 */}
{/*           <div className="col-md-4" data-aos="fade-right" data-aos-duration="1000">
            <img src={rimages[2]} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={images[1]} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="3000">
            <img src={images[2]} className="w-100" alt="bar"/>
          </div>
 */}


 
{/*           <div className="col-md-4" data-aos="fade-right" data-aos-duration="1000">
            <img src={rimages[0]} width="50" height="60" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={rimages[1]} width="50" height="60" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="3000">
            <img src={rimages[2]} width="50" height="60" alt="bar"/>
          </div>
 */}
         </container>
        </div>
      </section>

      <section className="one" data-aos="fade-left" data-aos-duration="2000">
        <h1>{title ? title : "LOADING"}</h1>
{/*         <h1>TESTING</h1>
 */}
        <p> {desc ? desc : "LOADING"}</p>
      </section>
      <hr/>
    </>
  )
}