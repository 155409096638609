import React, {useEffect} from 'react';
// https://material-ui.com/components/checkboxes/
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function RestrictionPeriods({setRestrictionArray, restrictionsPeriods}) {
  const classes = useStyles();

/*   const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
 */
  const [state, setState] = React.useState({
    mf: false,
    ss: false,
    hd: false,
  });

  const handleChange = (event) => {
    console.log("handleChange: ",event.target.name)
    console.log("handleChange: ",event.target.checked)
    setState({ ...state, [event.target.name]: event.target.checked });
    console.log("StateArrayOfRestrictions: ",state)
  };

/*   const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;
 */  
  const { mf, ss, hd } = state;
//  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  useEffect(() => {
    setRestrictionArray(state)
  }, [state]);

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
{/*       <FormHelperText>{restrictionsPeriods}</FormHelperText>
 */}{/*       <FormLabel component="legend">Select Types of days being restricted</FormLabel>
 */}        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={mf} onChange={handleChange} name="mf" />}
            label="Weekdays (mon-fri)"
          />
          <FormControlLabel
            control={<Checkbox checked={ss} onChange={handleChange} name="ss" />}
            label="Weekends (sat-sun)"
          />
          <FormControlLabel
            control={<Checkbox checked={hd} onChange={handleChange} name="hd" />}
            label="Holidays"
          />
        </FormGroup>
{/*         <FormHelperText>No selection implies NO RESERVATION ENABLED</FormHelperText>
        <FormHelperText>{restrictionsPeriods}</FormHelperText>
 */}      </FormControl>
    </div>
  );
}


























/*   const [state, setState] = React.useState({
    mf: true,
    ss: false,
    hd: false,
  });
 */

/*   const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
 */
/*   const { mf, ss, hd } = state;
 */

/*   const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select Types of days being restricted</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={mf} onChange={handleChange} name="Weekdays (Mon/Fri)"/>}
            label="Weekdays (Mon/Fri)"
          />
          <FormControlLabel
            control={<Checkbox checked={ss} onChange={handleChange} name="Weekends (Sat/Sun)"/>}
            label="Weekends (Sat/Sun)"
          />
          <FormControlLabel
            control={<Checkbox checked={hd} onChange={handleChange} name="Holidays"/>}
            label="Holidays"
          />
        </FormGroup>
        <FormHelperText>No selection implies NO RESERVATION ENABLED</FormHelperText>
      </FormControl>
    </div>
  );
} */