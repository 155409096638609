import React from "react"
import Navbar from "./Navbar"
import { useRef, useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { database } from "../../firebase"
import moment from 'moment'
import firedb from "../../firebase" 
import { storage } from "../../firebase"

import MaterialTable from 'material-table'
import CallForAction from './CallForAction'
// https://stackoverflow.com/questions/63799347/material-table-select-row-export

//import { CsvBuilder } from "filefy";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Form, Card } from "react-bootstrap"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Container, Button } from "react-bootstrap"


import './Townhall.css';
import $ from 'jquery';
import { SwapVerticalCircleRounded } from "@material-ui/icons"
window.jQuery = $;
window.$ = $;
global.jQuery = $;

// These are the listings we are pulling
// 1) In 'Emails' we are looking for items with subject=REGISTRATION

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const dataArg = [
  createData('Csupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
];

export default function Townhall() {
  const { currentUser } = useAuth()
  const [selectedValue, setSelectedValue] = useState("")
  const [noteTitle, setNoteTitle] = useState("")
  const [noteBody, setNoteBody] = useState("")

  const [cards, setCards] = useState([])

  const datasetNames = ["aaa","bbb"]

  const mCollectionUsers = database.users // FireStore Database
  const mCollectionTownhall = database.townhall // FireStore Database
  const mCollectionTownhallNotes = database.townhall_notes // FireStore Database
  const [noteList, setNoteList] = useState([])
  const [showNoteListing, setShowNoteListing] = useState(false)

  ////////////////////////////////////////////
  const [townhallToken, setTownhallToken] = useState("")
  const [ready, setReady] = useState(false)
  const [thEmails, setThEmails] = useState([])
  const [myTownhallID, setMyTownhallID] = useState("na")
  const [meetingDisabled, setMeetingDisabled] = useState(true)

  //////////////////////////////////////////////////
  const myFileDiv = useRef(null);
  const myFileDivHeader = useRef(null);
  //////////////////////////////////////////////////
  const [statusStr, setStatusStr] = useState("Entered - video is off.")
  const [onOffVideo, setOnOffVideo] = useState(false)
  const [sidebarConnectionStr, setSidebarConnectionStr] = useState("")
  const [sidebar, setSidebar] = useState(false)
  const [currentCaller, setCurrentCaller] = useState("")

  useEffect(() => {
    if( currentUser && currentUser.email.includes("@"))
    {
      window.addEventListener('beforeunload', function(event) {
        database.townhall.doc(currentUser.email).delete()
        console.log('f9: I am the 1st one.');
      });
      /////////////////////////////////////////////////
      console.log('------------->> Add me as a name in the townhall')
      const ts = Date.now()
      database.townhall.doc(currentUser.email).set({
        host: currentUser.email,
        receiver: "none",
        calltoken: "none",
        status: "none",
        valid: 1,
        created: ts
      })
      .then(() => {
          console.log("Document successfully written!");
          if( currentUser && currentUser.email == "gm@sixty01.com")
          {
            mCollectionUsers.where("email", "==", currentUser.email)
            .get()
            .then(function(querySnapshot) {
              querySnapshot.forEach(function(doc) 
              {
                  if( doc.data().email == "gm@sixty01.com" && doc.data().meeting )
                  {
                    const meetingStr = doc.data().meeting
                    if( meetingStr.length > 0 )
                    {
                      const parts = meetingStr.split("@")
                      let meetingToken = ""
                      if( parts.length > 2 )
                      {
                        setMeetingDisabled(false)
                        meetingToken = `${parts[1]}-th-77a545e5-b054-473d-9d9c-d7563ab08575`
                        console.log("Meeting f10: String!", meetingToken );
                        const token = `https://meet.jit.si/hres123-${meetingToken}`
                        setTownhallToken(token)
                        database.townhall.doc(currentUser.email).update({meeting: token});
                      }
                    }
                  }
              });
            })  
          }
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });
      ////////////////////////////////////////////////////
//      const def = "th-77a545e5-b054-473d-9d9c-d7563ab08575"
//      const token = `https://prev.allhavenhomes.com/${def}`
//      const token = `https://prev.allhavenhomes.com/${def}`

      // https://meet.jit.si/hres123-  
//      const token = `https://meet.jit.si/hres123-${def}`
//      setTownhallToken(token)
      UpdateNoteListing()
    }

    const unSub_Townhall = mCollectionTownhall.onSnapshot(snapshot => {
      const def = "th-77a545e5-b054-473d-9d9c-d7563ab08575"
      let token = ""
      let tmpThEmails = []
      let tmpArrID = []
      console.log("f9: mCollectionTownhall: Handling change - currentCaller" )
      if( snapshot.empty && !(currentUser && currentUser.email == "gm@sixty01.com") )
      {
        document.location.replace("https://sixty01.allhavenhomes.com")
        console.log("f9: Exiting based on empty Database !")
      }
      if( currentUser && currentUser.email.includes("@"))
      {
        snapshot.docs.map( doc => {
          const tmpObj = { guid: doc.id, host: doc.data().host, ...doc.data() }
          if( doc.data().receiver == currentUser.email )
          {
            if( doc.data().status == "CALLING" ) {
              console.log("f9: mCollectionTownhall: I'm being called by:", doc.data().host )
              AnswerPerson( doc.data().host, doc.data().callToken )
            }
          }
          if( !tmpThEmails.includes(doc.data().host) )
          {
            if( doc.data().valid == 1 && doc.data().host != currentUser.email )
              tmpThEmails.push(doc.data().host)
          }
          if( doc.data().host == "gm@sixty01.com" && doc.data().meeting ) {
            const meetingStr = doc.data().meeting
            if( meetingStr.length > 0 )
            {
              console.log("f9: mCollectionTownhall: GM is present with a meeting:", meetingStr )
              setTownhallToken(meetingStr)
              setMeetingDisabled( false )
            }
          }
        })
        setThEmails(tmpThEmails)
        console.log("mCollectionTownhall: DONE: ")
      }
    })
    return function cleanup() {
      console.log("f9: GM CLEARING TOWNHALL ON TIMEOUT!")
      if( currentUser && currentUser.email.includes("@"))
      {
        database.townhall.doc(currentUser.email).delete().then(() => {
          const timer = setTimeout( () => {
            unSub_Townhall() } , 2000)
          })
      }
      else
      {
        const timer = setTimeout( () => {
          unSub_Townhall() } , 2000)
      }
    };
  }, [])

  useEffect(() => {
    /////////////////////////////////////////////////
    console.log("f9: mCollectionTownhall - Token: REPORT: ", townhallToken)
    console.log("f9: mCollectionTownhall - ID: REPORT: ", myTownhallID)
    setReady(true)
  }, [townhallToken])

  useEffect(() => {
    console.log("my..", myFileDiv.current, myFileDivHeader.current);
  })

/*   useEffect(() => {
    console.log("on loading notifications", dispIndex)
    handleBtn(dispIndex)
  }, [ready])
 */
  ///////////////////////////////////////////////////////////////////////////////////
  function UpdateNoteListing() {
    mCollectionTownhallNotes.where( 'owner', '==', currentUser.email )
      .get()
      .then(snapshot => {
      let tmpArr1 = []
      snapshot.docs.map( doc => {
        const tmpObj = { guid: doc.id, ...doc.data() }
        console.log("f9: mCollectionTownhallNotes: iteration: ",doc.data().owner, doc.id)

        tmpArr1.push(tmpObj)
      })
      setNoteList(tmpArr1.sort(function(a, b){return (b.updated)-(a.updated)}))
    })
  }

  function enableDisableMeeting( bool ) {
    if( bool )
      alert("Meeting Enabled")
    else
      alert("Meeting Disabled")
  }

  function handleBtn(index) {
    console.log("handle Btn", index)
  }

  const handleChange = (event) => {
    console.log("onHandleChange: ",event.target.value)
  };

  const classes = useStyles();

  ///////////////////////////////////////////////////////////////
  function myLeaveTownhall() {
    console.log("f9: myLeaveTownhall(): ",myTownhallID)
    if( currentUser.email == "gm@sixty01.com" )
    {
      database.townhall.get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log("f9: myLeaveTownhall() Iteration: ")
          doc.ref.delete();
        })
        const timer = setTimeout( () => {
          document.location.replace("https://sixty01.allhavenhomes.com")
          console.log("f9: GM CLEARING TOWNHALL ON TIMEOUT!") }
                                                                    , 3000)  
      })
    }
    else
    {
      database.townhall.doc(currentUser.email).delete().then(() => {
        console.log("Document successfully deleted!");
      }).catch((error) => {
          console.error("Error removing document: ", error);
      });
      const timer = setTimeout( () => {
          document.location.replace("https://sixty01.allhavenhomes.com")
          console.log("GM CLEARING TOWNHALL ON TIMEOUT!") }
                                                                    , 1000)  
    }
  }
  
  function OnOffVideo() {
    console.log("Toogle Video Feed")
    if( onOffVideo )
    {
      if( currentCaller.length > 0 )
        TerminateCall()
      // find the element with innerHTML = caller
      const eleArr = document.getElementsByClassName("th-people")
      // https://stackoverflow.com/questions/3871547/js-iterating-over-result-of-getelementsbyclassname-using-array-foreach
      Array.prototype.forEach.call(eleArr, function(el) {
        // Do stuff here
        if( el.innerHTML == currentCaller )
          el.style.background ='green';
        console.log(el.tagName);
      });
      setStatusStr("Video is off")
      /////////////////////////////////////////////////////////
    }
    else
    {
      console.log("f9: Toogle Video Feed", townhallToken)
      setStatusStr("Attending Townhall. ")
    }  
    setOnOffVideo(!onOffVideo)
  }

  function ToggleNoteListing() {
    console.log("f9: ToggleNoteListing")
    UpdateNoteListing()
    setShowNoteListing(!showNoteListing)
  }

  function HandleSelectNote(k, note) {
    console.log("f9: HandleSelectNote", k, note.guid)
    const card = document.getElementById("proto-card");
    var copy = card.cloneNode(true)
    copy.style.display = "block"
    copy.firstChild.setAttribute("Id", note.guid)
    copy.firstChild.getElementsByTagName('input')[0].value = note.title;
    copy.firstChild.getElementsByTagName('time')[0].innerHTML = moment(note.updated).format('LLLL');
    copy.firstChild.getElementsByTagName('textarea')[0].value = note.body;
    var btnClose = copy.querySelector('.close-note');
    btnClose.addEventListener("click", CloseNote);
    var btnDelete = copy.querySelector('.delete-note');
    btnDelete.addEventListener("click", DeleteNote);
    var btnSave = copy.querySelector('.save-note');
    btnSave.addEventListener("click", SaveNote);
    const container = document.getElementById("my-file-div-body");
    container.append(copy)
  }

  function NewCard() {
    const id = "na" //cards.length + 1
    const card = document.getElementById("proto-card");
    var copy = card.cloneNode(true)
    copy.style.display = "block"
    copy.firstChild.setAttribute("Id", id)
    copy.firstChild.getElementsByTagName('input')[0].value = "";
    copy.firstChild.getElementsByTagName('time')[0].innerHTML = "--:--";
    copy.firstChild.getElementsByTagName('textarea')[0].value = "";
    var btnClose = copy.querySelector('.close-note');
    btnClose.addEventListener("click", CloseNote);
    var btnDelete = copy.querySelector('.delete-note');
    btnDelete.addEventListener("click", DeleteNote);
    var btnSave = copy.querySelector('.save-note');
    btnSave.addEventListener("click", SaveNote);
    const container = document.getElementById("my-file-div-body");
    container.append(copy)
  }
  function myParticipate() {
  //  document.getElementById("page-hdr").innerHTML = "Participating";
    if( document.getElementById("switch").innerHTML == "Turn Video Feed On" )
    {
      document.getElementById("switch").innerHTML = "Turn Video Feed Off";
      document.getElementById("video-grid").style.visibility = 'visible';
    }
    else
    {
      document.getElementById("switch").innerHTML = "Turn Video Feed On";
      document.getElementById("video-grid").style.visibility = 'hidden';
    }
  }
  function CloseNote(e) {
    console.log("function CloseNote() ----> ",e.target.parentElement.id )
    e.target.parentElement.parentElement.remove()
  }
  function DeleteNote(e) {
    console.log("function DeleteNote() ----> ",e.target.parentElement.id )
    database.townhall_notes.doc(e.target.parentElement.id).delete()
    e.target.parentElement.parentElement.remove()
    UpdateNoteListing()
  }
  function SaveNote(e) {
    const elTitle = e.target.parentElement.getElementsByTagName('input');
    const titleStr = elTitle[0].value
    const elTime = e.target.parentElement.getElementsByTagName('time');
    const timeStr = elTime[0].innerHTML
    const elBody = e.target.parentElement.getElementsByTagName('textarea');
    const bodyStr = elBody[0].value
    console.log("function SaveNote() ----> ",e.target.parentElement.id, titleStr, timeStr, bodyStr )
    if(e.target.parentElement.id == 'na')
    { // new note
      console.log(' New note being stored')
      const ts = Date.now()
      const newNote = {
        owner: currentUser.email,
        title: titleStr,
        body: bodyStr,
        created: ts,
        updated: ts
      }
      const ref = database.townhall_notes.add(newNote).then((docRef) => {
        var dateTime = new Date();
        var hours = dateTime.getHours();
        var minutes = dateTime.getMinutes();

        if (hours === 0) { 
          hours = 12;
        }

        const time = (hours > 12 ? hours - 12 : hours) + ":" +
           (minutes < 10 ? "0" : "") + minutes + (hours > 12 ? "PM" : "AM");

        const friendlyTimestamp = moment(dateTime).format('LLLL');
        elTime[0].innerHTML = friendlyTimestamp;
        e.target.parentElement.setAttribute("Id", docRef.id )
        console.log("Document written with ID: ", docRef.id);
      })
    }
    else
    {
      console.log("Updating by saving() ----> ",e.target.parentElement.id, titleStr, bodyStr )
      database.townhall_notes.doc(e.target.parentElement.id).update({
        title: titleStr,
        body: bodyStr,
        updated: Date.now()
      })
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });
    }
    UpdateNoteListing()
  }
  ///////////////////////////////////////////////////////////////
//  document.getElementById("FilesLoad3").addEventListener("click", FilesLoad3);
  function FilesLoad3() {
///    console.log("f10: : ", meetingDisabled);
//    setMeetingDisabled(!meetingDisabled)
    $('#my-file-div').fadeIn(500);
  //  document.location.replace("https://sixty01.allhavenhomes.com")
  }
  function FilesLoad4() {
    $('#my-file-div-test').fadeIn(500);
  }
    
  function TerminateCall() {
    console.log("f9: CallBeingTerminated: ", currentCaller);
    if( onOffVideo )
      setStatusStr("Attending Townhall. ")
    else
      setStatusStr("Video is off")

    setCurrentCaller("")
    database.townhall.doc(currentUser.email).update({ calltoken: "none", receiver: "none", status: "none" })
    setSidebar( false )
    setReady( true )
  }
  function ExitFileDlg() {
    $('#my-file-div').fadeOut(750);
  }
  function ExitFileDlg4() {
    $('#my-file-div-test').fadeOut(750);
  }

  function AnswerPerson(caller, callTokenIn) {
    console.log("f9: Answer call from: ", caller);
    setStatusStr(`In call with: ${caller}`)
    setOnOffVideo(true)

    // find the element with innerHTML = caller
    const eleArr = document.getElementsByClassName("th-people")
    // https://stackoverflow.com/questions/3871547/js-iterating-over-result-of-getelementsbyclassname-using-array-foreach
    Array.prototype.forEach.call(eleArr, function(el) {
      // Do stuff here
      if( el.innerHTML == caller )
        el.style.background ='red';
      console.log(el.tagName);
    });
/*     $('#my-file-div').fadeIn(500);
 */
      setCurrentCaller(caller)
//    database.townhall.doc(currentUser.email).update({ status: "CALLING" })
//    database.townhall.doc(currentUser.email).update({ receiver: caller })      
//    database.townhall.doc(e.target.innerHTML).update({ invite: currentUser.email })
//    const callToken = `th-75fd1b09-5f5e-424c-8c9f-67387da833d2` //`th-${sender}---${receiver}`
//    const callToken = `th-75fd1b09-5f5e-424c-8c9f-67387da833d2` //`th-${sender}---${receiver}`
    const callToken = `th-${caller}` //`th-${sender}---${receiver}`
    const connectionStr = `https://prev.allhavenhomes.com/${callToken}` //`th-${sender}---${receiver}`
    const timer = setTimeout( () => {
      setSidebarConnectionStr( connectionStr )
      setReady( false )
      setSidebar( true )
      console.log("GM CLEARING TOWNHALL ON TIMEOUT!") }
                                                                , 1000)  
    console.log("f9: Answer Connecting with Conection String: ", connectionStr);
  }
  function ChoosePerson(e) {
    setOnOffVideo(true)
    if( currentCaller.length > 0 )
    {
      e.target.style.background ='green';
      TerminateCall()
      if( onOffVideo )
        setStatusStr("Attending Townhall. ")
      else
        setStatusStr("Video is off")
    }
    else
    {
      e.target.style.background ='red';
      const receiver = e.target.innerHTML
      setCurrentCaller(receiver)
      setStatusStr(`In call with: ${receiver}`)
      console.log("f9: Connecting with: ", receiver);
  /*     $('#my-file-div').fadeIn(500);
   */
      database.townhall.doc(currentUser.email).update({ status: "CALLING" })
      database.townhall.doc(currentUser.email).update({ receiver: receiver })
      const sender = currentUser.email
//      const callToken = `th-75fd1b09-5f5e-424c-8c9f-67387da833d2` //`th-${sender}---${receiver}`
      const callToken = `th-${currentUser.email}` //`th-${sender}---${receiver}`
      database.townhall.doc(currentUser.email).update({ calltoken: callToken })

//      https://meet.jit.si/hres123
      const connectionStr = `https://prev.allhavenhomes.com/${callToken}` //`th-${sender}---${receiver}`
//      const connectionStr = `https://meet.jit.si/hres123-${callToken}` //`th-${sender}---${receiver}`
      console.log("f9: Connecting String: ", connectionStr);
      setSidebarConnectionStr( connectionStr )
      setReady( false )
      setSidebar( true )  
    }
  }
/*   let drag = false;

  document.addEventListener('mousedown', () => drag = false);
  document.addEventListener('mousemove', () => drag = true);
  document.addEventListener('mouseup', () => console.log(drag ? 'drag' : 'click'));
 */
//  useEffect(() => {
//    const timer = setTimeout(() => {
//      dragElement(document.getElementById("my-file-div"))
//    },1000)
//    const timer1 = setTimeout(() => {
//      dragElement(document.getElementById("my-file-div-test"))
//    },1000)
//    return () => {
//      clearTimeout(timer)
//      clearTimeout(timer1)
//    }
//  }, [])
  let elDrag = null;

  useEffect(() => {
    const timer = setTimeout(() => {
      elDrag = document.getElementById("my-file-div")
      dragElement(document.getElementById("my-file-div"))
    },1000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      ///if present, the header is where you move the DIV from:  // here in this implemetation we are using the header
      console.log("f12: OnMouseDown_1: ", elmnt.id);
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      document.getElementById(elmnt.id + "header").ontouchstart = dragMouseDown;
      document.getElementById(elmnt.id + "header").ontouchmove = elementDrag;
    } else {
      ///otherwise, move the DIV from anywhere inside the DIV:
      console.log("f12: OnMouseDown_2: ");
      elmnt.onmousedown = dragMouseDown;
      elmnt.ontouchstart = dragMouseDown;
      elmnt.ontouchmove = elementDrag;
    }
    function dragMouseDown(e) {
      console.log("f12: dragMouseDown_3: ", elmnt.id )
      e = e || window.event;
      e.preventDefault();

      e.target.addEventListener("touchmove", elementDrag);

      console.log("f12: dragMouseDown_3_1: ", e.target, e.target.parentElement )
      console.log("f12: dragMouseDown_3_2: ", e.target.parentElement.parentElement )
      console.log("f12: dragMouseDown_3_3: ", e.target.parentElement.parentElement.id )

      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.ontouchend = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
      document.ontouchmove = elementDrag;
    }
    function elementDrag(e) {
      console.log("f12: dragMouseDown_4: ", elmnt.id )
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      if (e.clientX) {
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      } else {
          // touchmove - assuming a single touchpoint
//          moving.style.left = e.changedTouches[0].clientX - moving.clientWidth/2;
//          moving.style.top = e.changedTouches[0].clientY - moving.clientHeight/2;
          const stLeft = e.changedTouches[0].clientX - elmnt.clientWidth/2
          const stTop = e.changedTouches[0].clientY - elmnt.clientHeight/2
          console.log("f12: MOVE TOUCH TOUCH: ", stLeft, stTop )
          elmnt.style.left = `${stLeft}px`
          elmnt.style.top = `${stTop}px`
      }
    }
    function closeDragElement() {
      console.log("f12: dragMouseDown_5: ");
      ///stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchend = null;
      document.ontouchmove = null;
    }
  }
  ///////////////////////////////////////////////////////////////

  function dragBegin(e) {
    if( elDrag )
    {
      console.log("f13: dragMouseDown_3: ", elDrag.id, e.target )
      e = e || window.event;
      e.preventDefault();  
    }
    // get the mouse cursor position at startup:
/*     pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.ontouchend = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
    document.ontouchmove = elementDrag;
 */  }
  function dragGing(e) {
    if( elDrag )
    {
      console.log("f13: dragMouseDown_4: ", elDrag.id, e.target )
      e = e || window.event;
      e.preventDefault();
    }
    // calculate the new cursor position:
/*     pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
 */  }
  function dragEnd(e) {
    if( elDrag )
    {
      console.log("f13: dragMouseDown_5: ", elDrag.id, e.target);
    }
    ///stop moving when mouse button is released:
//    document.onmouseup = null;/
//    document.onmousemove = null;
 //   document.ontouchend = null;
 //   document.ontouchmove = null;
  }

  const handleSubmit = event => {
    event.preventDefault();
    console.log("Submitting: " )
  }

  const [inviteCode, setInviteCode] = useState("")
  function InviteByCode(code) 
  {
    if(code.includes("#"))
    {
      console.log("f17: InviteByCode", code )
      const ts = Date.now()
      database.townhall.doc(code).set({
        host: code,
        receiver: "none",
        calltoken: "none",
        status: "none",
        valid: 0,
        created: ts
      })
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });  
      setInviteCode(code)
      const token = `https://prev.allhavenhomes.com/${code}`
      setTownhallToken(token)
      setOnOffVideo(true)
    }
    if( code.length == 0 )
    {
      console.log("f9: Clearing Code", code )  
      database.townhall.doc(inviteCode).delete()
      const def = "th-77a545e5-b054-473d-9d9c-d7563ab08575"
      //const retoken = `https://prev.allhavenhomes.com/${def}`
      // https://meet.jit.si/hres123-
      const retoken = `https://meet.jit.si/hres123-${def}`
      setTownhallToken(retoken)
      setInviteCode("")
    }
  }
  function EnterCode(code) 
  {
    if(code.includes("#"))
    {
      console.log("f9: EnterCode", code )
      mCollectionTownhall.where( 'host', '==', code )
        .get()
        .then(snapshot => {
        snapshot.docs.map( doc => {
          if( doc.id == code )
          {
            console.log("f9: mCollectionTownhall: CODE AUTH: ")
            const token = `https://prev.allhavenhomes.com/${code}`
            setTownhallToken(token)
            setOnOffVideo(true)
            // Hide the outPopUp
            document.getElementById("outPopUp").style.display="none"
          }
        })
      })
    }
  }

  const meetingReady = () => {
    return (meetingDisabled)
  }


  const handleEvent = (event) => {
    console.log("f12: handleEvent: ", event.target)
/*     if (event.type === "mousedown") {
           this.setState({ message: "Mouse Down"});
       } else {
           this.setState({ message: "Mouse Up"});
       }
 */
  }

  let moving = null;
  let movingDialog = null;

  function pickup(event) {
      moving = event.target;
      console.log("f12: PICKUO: ", event.target, event.target.parentElement )
      console.log("f12: PICKUO 1: ", event.target.parentElement.parentElement )
      console.log("f12: PICKUO 2: ", event.target.parentElement.parentElement.id )
      movingDialog = event.target.parentElement.parentElement
      const elHeight = movingDialog.clientHeight
      const elWidth = movingDialog.clientWidth

      console.log("f12: PICKUO 3: ", elHeight, elWidth)

//      https://stackoverflow.com/questions/55913642/react-how-to-set-width-and-height-of-an-element-in-react-component-programatica

/*       moving.style.height = elHeight;
      moving.style.width = elWidth;
 */
      movingDialog.style.height = `${elHeight}px`;
      movingDialog.style.width = `${elWidth}px`;

      movingDialog.style.position = 'fixed';
  }
  
  function move(event) {
    console.log("f12: MOVE MOVE MOVE: " )
    if (movingDialog) {
        if (event.clientX) {
          const stLeft = event.clientX - moving.clientWidth/2
          const stTop = event.clientY - moving.clientHeight/2
          console.log("f12: MOVE MOUSE MOUSE: ", stLeft, stTop )
          // mousemove
          movingDialog.style.left = `${stLeft}px`
          movingDialog.style.top = `${stTop}px`
        } else {
          const stLeft = event.changedTouches[0].clientX - moving.clientWidth/2
          const stTop = event.changedTouches[0].clientY - moving.clientHeight/2
          console.log("f12: MOVE TOUCH TOUCH: ", stLeft, stTop )
            // touchmove - assuming a single touchpoint
/*             moving.style.left = event.changedTouches[0].clientX - moving.clientWidth/2;
            moving.style.top = event.changedTouches[0].clientY - moving.clientHeight/2;
 */            
          movingDialog.style.left = `${stLeft}px`
          movingDialog.style.top = `${stTop}px`
        }
    }
  }

  function drop(event) {
    if (movingDialog) {
        // reset our element
        moving.style.left = '';
        moving.style.top = '';
        moving.style.height = '';
        moving.style.width = '';
        moving.style.position = '';

        moving = null;
        movingDialog.style.left = '';
        movingDialog.style.top = '';
        movingDialog.style.height = '';
        movingDialog.style.width = '';
        movingDialog.style.position = '';

        movingDialog = null;
    }
  }

  return (
    <div id="townhall-center">

      <Navbar title='Town Hall'/>

{/*       { currentUser && currentUser.email.includes("@") &&
        <Navbar title='Town Hall' /> }
 */}
        { ( currentUser && currentUser.email.includes("@") ) ?
          (
            <div>
              <div id="container" class="container-fluid">
                <span class="input-group-btn ml-2 mr-2">
                  <button class="btn btn-info" id="leave" onClick={()=>myLeaveTownhall()}>Leave</button>
                </span>
                { currentUser.email == "gm@sixty01.com" &&
                  <>
                    <span>
                      <input id="code-input-str" type="text" size="12"
                        class="pl-2 pt-1"
                        placeholder="Invite Code"
                        onChange={e => InviteByCode(e.target.value)}>
                      </input>
                    </span>
                  </>
                }
                <span class="input-group-btn ml-2">
                  <button id="notes-btn" class="btn btn-info ml-1" onClick={()=>FilesLoad3()}>Notes</button>
                </span>
{/*                 <span class="input-group-btn ml-2">
                  <button id="notes-btn" class="btn btn-info ml-1" onClick={()=>FilesLoad4()}>TEST</button>
                </span>
 */}
                { currentUser && currentUser.email.includes("@") &&
                  <span id="one-span">
                    <span class="input-group-btn ml-2 mr-2">
                      <button disabled={meetingReady()} 
                              class="btn btn-info" 
                              id="video" 
                              onClick={()=>OnOffVideo()}>Video On/Off
                      </button>
                    </span>
                    <span class="ml-3">{statusStr}</span> 
                  </span>
                }

                <span id="acct" className="ml-2 mr-3 mt-2">{currentUser.email}</span> 





{/*                 <span class="input-group-btn ml-2 mr-2">
                  <button class="btn btn-info" id="leave" onClick={()=>myLeaveTownhall()}>Leave</button>
                </span>
                { currentUser.email == "gm@sixty01.com" &&
                  <span id="code-input1">
                    <input type="text"
                      class="pl-2 pt-1"
                      placeholder="Invite Code"
                      onChange={e => InviteByCode(e.target.value)}>
                    </input>
                  </span>
                }
                <span id="code-input" class="input-group-btn ml-2 mr-2">
                  <button class="btn btn-info" id="video" onClick={()=>OnOffVideo()}>Video On/Off</button>
                </span>

                { currentUser && currentUser.email.includes("@") &&
                  <span id="one-span">
                    <span class="ml-3">{statusStr}</span> 
                    <span class="input-group-btn ml-2">
                      <button id="notes-btn" class="btn btn-info ml-4" onClick={()=>FilesLoad3()}>Notes</button>
                    </span>
                  </span>
                }

                <span id="acct" className="ml-2 mr-3 mt-2">{currentUser.email}</span> 
 */}














  
              </div>
            </div>
           ):(
            <div id="outPopUp">
              <input type="text" class="form-control w-75 ml-3 mt-3"
                placeholder="Enter Access Code"
                onChange={e => EnterCode(e.target.value)}>
              </input>
              <span id="tooltip">This is a code you would have been given by the General Manager of the Sixty01 HOA Administration</span>    
            </div>
          )
        }

 
      { thEmails.length > 0 && 
        thEmails.map(email => (
        <button id="my-file-div-close1" className="th-people"
          onClick={(e)=>ChoosePerson(e)}>{email}</button>
        )) }

      { onOffVideo && ready && 
          <>
            <span>-------{townhallToken}</span> 
            <iframe src={townhallToken} allow="camera;microphone" 
                    width="100%" height="100%">
            </iframe>
          </>
      }
      { onOffVideo && sidebar && 
          <>
            <span>-------{sidebarConnectionStr}</span> 
            <iframe src={sidebarConnectionStr} allow="camera;microphone" 
                    width="100%" height="100%">
            </iframe>
          </>
      }

      <div id="filemodal"></div>


      <div ref={myFileDiv} id="my-file-div">
        <button id="my-file-div-close1" 
                onClick={()=>ExitFileDlg()}>x</button>
        <div ref={myFileDivHeader} id="my-file-divheader">
            <span class="disableSelection">Press here to reposition the window</span></div>
 
        <button id="my-file-div-new1" class="ml-2"
                onClick={()=>NewCard()}>New</button>
        <button id="my-file-div-prev-notes1" class="ml-2"
                onClick={()=>ToggleNoteListing()}>Notes</button>
        <div id="my-file-div-title">Create meeting notes here</div>

        <Card.Text>
          {showNoteListing && noteList &&
          <>
            <span>Notes listed by Title (recent edits first)</span>
            <ul className="ml-5">
              { noteList.map((note, k) => (
                  <li style={{marginRight: "20px"}} >
                    <div className="d-flex justify-content-between">
                      <a href="#" onClick={ () => HandleSelectNote(k, note)}>
                        {noteList[k].title}
                      </a>
                      <span className="ml-3">{moment(noteList[k].updated).format('ll')}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </>
          }
        </Card.Text>

{/*         <section id="graphs">
            <span id="tooltip">thisistest</span>    
            <canvas id="myChart" width="550" height="350"></canvas>
        </section>
 */}
        <div id="my-file-div-body">
        </div>
      </div>

      <div id="proto-card" class="card" style={{width: "auto", margin: "5px"}}>
        <div class="card-body">
          <input id="title" class="form-control width100 mt-1"
                    placeholder="Title of note"
                    />
          <time datetime="2018-07-07T20:00:00">--:--</time>
          <textarea id="body"
                    placeholder="Meeting note body here"
                    class="form-control" 
                    onChange={e => setNoteBody(e.target.value)}
                    id="exampleFormControlTextarea1" 
                    rows="3"></textarea>
          <a href="#" id="" class="close-note btn btn-primary mt-3">close</a>
          <a href="#" id="" class="delete-note btn btn-primary mt-3 ml-1">delete note</a>
          <a href="#" id="" class="save-note btn btn-primary mt-3 ml-1">save note</a>
        </div>

      </div>

      <div id="my-file-div-test">
        <button id="my-file-div-close1" 
                onClick={()=>ExitFileDlg4()}>x</button>
        <div ref={myFileDivHeader} id="my-file-divheader">
            <span class="disableSelection">Press here to reposition the window</span></div>
 
        <button id="my-file-div-new1" class="ml-2"
                onClick={()=>NewCard()}>New</button>
        <button id="my-file-div-prev-notes1" class="ml-2"
                onClick={()=>ToggleNoteListing()}>Notes</button>
        <div id="my-file-div-title">Create meeting notes here</div>

      </div>





{/* 
      <div id="move-container">
        <div id="left-parent">
          <div id="movable-element"
                  onMouseDown={(event) => pickup(event)}
                  onTouchStart={(event) => pickup(event)}
                  onMouseMove={(event) => move(event)}
                  onTouchMove={(event) => move(event)}
                  onMouseUp={(event) => drop(event)}
                  onTouchEnd={(event) => drop(event)}
              ></div>
        </div>

        <div id="right-parent"></div>
      </div>
 */}

    </div>    
  )
}
