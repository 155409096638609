import React, { useRef, useState, useEffect } from "react"
import ReactDOM from "react-dom"
import DragDropFiles from './DragDropFiles'

import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { database } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
/* import { ROOT_FOLDER } from "../../hooks/useFolder"
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
 */
import { storage } from "../../firebase"


export default function FileShareGM() {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState("no file")
  const [note, setNote] = useState("")
  const { currentUser } = useAuth()
  const [loading,setLoading] = useState(true)

  const [files, setFiles] = useState([])
  const [fileNames, setFileNames] = useState([])

  useEffect(() => {
    console.log("loading files from GM share -> ")
    if( loading || files.length == 0)
    {
      const fileRef = storage.ref(`sys_gm_share`)
      const fileList = fileRef.child(`${currentUser.email}`)
      let tmpFiles = []
      let tmpNames = []
      fileList.listAll().then((res) => {
        res.items.forEach((itemRef, index) => {
          console.log("loading files from GM share -> 1",itemRef)
          console.log("loading files from GM share -> 2",itemRef.name)
          tmpFiles.push(itemRef.name)
          tmpNames.push(itemRef.name)
        })
      })
      setFiles(tmpFiles)
      setFileNames(tmpNames)
      setLoading(false)
    }
  }, [loading])

  const handleSetImage = (i) => {
    console.log("handleSetImage: ",i)
  }

  const handleDrop = (files) => {
    if( files )
    {
      // Single upload
      console.log("Uploading: ",files, files[0].name)
      setFileName(files[0].name)
      setFile(files[0])
    }
  }

  function openModal() {
    setOpen(true)
    setFileName('no file')
    setFile()
  }

  function closeModal() {
    setOpen(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if( file && fileName.length > 0 ) 
    {
      console.log("Uploading file/note: ",file, fileName, note, currentUser)

      await storage
        .ref(`sys_gm_share/${currentUser.email}/${fileName}`)
        .put(file)
      const urlTmp = await storage.ref(`sys_gm_share/${currentUser.email}`)
                              .child(`${fileName}`)
                              .getDownloadURL()
      console.log("Uploaded and setting image url: ", urlTmp, currentUser.email )

      // Set Notification
      const ts = Date.now()
      const newNotification = {
        fileName: fileName,
        event: "file-share-gm", 
        from: currentUser.email, 
        note: note,
        linkURL: urlTmp,
        ts: ts
      }
      database.notifications.add(newNotification)
    }
    setFileName("no file")
    setLoading(true)
    closeModal()
  }

  return (
    <>
      <Button title="This shows the content shared with GM - you can add and remove content anytime" 
        onClick={openModal} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
        <span>GM</span>
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Here you can manage files shared with GM</Form.Label>

              <hr/>

              <Form.Label>GM will be notified when ever a new file is uploaded. If you wish, you can enter a message below, which will be delivered with the notification when a file is upload</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your note for GM here"
/*                 value={name}
 */                onChange={e => setNote(e.target.value)}
              />
              <div>
                <label class="custom-file-upload">
                  <input type="file" onChange={(e) => 
                    { handleDrop(e.target.files) }}/>
                    <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                      Choose file
                </label>
                {fileName}
                <Button variant="success" type="submit" className="btn float-right btn-sm ml-3">
                  Upload File
                </Button>
              </div>
              <hr/>
              <Form.Label>Files currently in the shared folder: </Form.Label>
              <div style={{display: "flex"}}>
                <DragDropFiles handleDrop={handleDrop} filecount={fileNames.length}>
                  <div style={{height: 90, width: 190, marginLeft: "20px"}}>
{/*                     {fileNames.map((file,i) => <div key={i} onClick={handleSetImage(i) ---- This breaks the rendering  }>{file}</div>)}
 */}                    {fileNames.map((file,i) => <div key={i} onClick={()=>handleSetImage(i)}>{file}</div>)}
                  </div>
                </DragDropFiles>
{/*                 <div>
                  { ( image > 0 && selection.length > 0 ) && <img src={selection[image-1].url} width="150px" alt="BigCo Inc. logo"/>}
                </div>
 */}              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
