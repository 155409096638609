import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"


const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

const firestore = app.firestore()
export const firedb = app.database()

export const database = {
  events: firestore.collection("events"),
  townhall: firestore.collection("townhall"),
  townhall_notes: firestore.collection("townhall_notes"),
  sys_actions: firestore.collection("sys_actions"),
  contactus: firestore.collection("contactus"),
  notifications: firestore.collection("notifications"),
  signups: firestore.collection("signups"),
  users: firestore.collection("users"),
  emails: firestore.collection("emails"),
  folders: firestore.collection("folders"),
  files: firestore.collection("files"),
  tickets: firestore.collection("tickets"),
  ticketitems: firestore.collection("ticketitems"),
  formatDoc: doc => {
    return { id: doc.id, ...doc.data() }
  },
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
}
export const storage = app.storage()
export const auth = app.auth()
export default app
