import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from "./CenteredContainer"
import { database } from "../../firebase" 

export default function Signup() {

  const [occupied, setOccupied] = useState("")
  const ownerRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const { currentUser } = useAuth()
  const [user, setUser] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  function logSignups(email, uid) {
    console.log("2 logging user NOW -> ", email, uid)
    const ts = Date.now()
    const newUser = {
      email: email, 
      uid: uid,
      event: "SIGNUP",
      ts: ts
    }
    database.signups.add(newUser)
  }

  useEffect(() => {
    if( currentUser && currentUser.email.length > 0 )
    {
      // log user attempt in signups regardless of if we keep them
      const email = currentUser.email
      const uid = currentUser.uid
      console.log("3 logging user NOW -> ", email, uid)
      logSignups(email, uid)
      setLoading(false)
    }
  }, [currentUser])

  useEffect(() => {
    if( loading == false && user.length > 0 )
    {
      if( user == "DELETE")
      {
        currentUser.delete()
      }
      history.push("/")
    }
  }, [loading, user])

  function handleChange(e) {
    setOccupied(e.target.value)
    console.log("OCCUPIED SET:", e.target.value)
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if( occupied.length < 1 ) {
      return setError("Please choose 'Owner' or 'Tenant' below!")
    }
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }
    if (emailRef.current.value.includes('sixty01.com')) {
      return setError("Restricted email address")
    }
    if (passwordRef.current.value.length < 6 ) {
      return setError("Password must be 6 chars or longer")
    }    

    try {
      setError("")
      setLoading(true)
      const email_login = emailRef.current.value.toLowerCase()
      await signup(email_login, passwordRef.current.value)
      console.log("User is authed and will now check against valid user obj")
      database.users.where("email", "==", email_login)
      .get()
      .then((querySnapshot) => {
          if( querySnapshot.empty) 
          {
            console.log("User is not present: ");
            setUser("DELETE")
          }
          else
          {
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log("VALIDATED: ",doc.id, " => ", doc.data());
              setUser(email_login)
              // Update occupation for 
              database.users.doc(doc.id).update({
                occupied: occupied
              });
            });
          }
      })
      .catch((error) => {
          console.log("Error getting documents for USER: ", error);
      });
    } catch {
      setError("Failed to create an account (see admin)")
    }
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="owner">
              <Form.Label>Are you an Owner or a Tenant?</Form.Label>
                <div class="d-flex ml-5" onChange={event => handleChange(event)}>
                  <div>
                    <input class="form-check-input" type="radio" value="OWNER" name="occupied"/>
                    <label class="form-check-label" for="flexRadioDefault1">
                      Owner
                    </label>
                  </div>
                  <div class="ml-5">
                    <input class="form-check-input" type="radio" value="TENANT" name="occupied"/>
                    <label class="form-check-label" for="flexRadioDefault1">
                      Tenant
                    </label>
                  </div>
                </div>

{/*                 <div class="form-check" >

                  <input class="form-check-input mr-2" type="radio" name="flexRadioDefault" value="OWNER" id="flexRadioDefault1"/>
                  <label class="form-check-label" for="flexRadioDefault1">
                    Owner
                  </label>
                </div>
                <div class="form-check ml-4">
                  <input class="form-check-input mr-2" type="radio" name="flexRadioDefault" value="TENANT" id="flexRadioDefault2" checked/>
                  <label class="form-check-label" for="flexRadioDefault2">
                    Tenant
                  </label>
                </div>
 */}
              <Form.Label>(Subject to verification)</Form.Label>
            </Form.Group>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required/>
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password (min. 6 characters)</Form.Label>
              <Form.Control type="password" ref={passwordRef} required/>
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required/>
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </CenteredContainer>
  )
}
