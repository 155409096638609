import React, { useState } from "react"
import DragDropFiles from './DragDropFiles'

import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { database } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import { ROOT_FOLDER } from "../../hooks/useFolder"
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";


export default function RequestTicketButton({onRequestNewTicket, setTicketCnt, onAddNewTicket, users}) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [toEmail, setToEmail] = useState("")
  const [ fileObjs, setFileObjs ] = useState([])
  const [ fileNames, setFileNames ] = useState([
/*     'nice.pdf',
    'verycool.jpg',
    'amazing.png',
    'goodstuff.mp3',
    'thankyou.doc'
 */  ])
  const [selection, setSelection] = useState([])
  const [image,setImage] = useState(0)

  const { currentUser } = useAuth()

  const handleSetImage = (i) => {
    console.log("handleSetImage: ",i)
    setImage(i+1)
  }

  const handleDrop = (files) => {
    if( files )
    {
        let listObjs = [...fileObjs]
        let listNames = [...fileNames]
        let listSelection = [...selection]
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return
          listObjs.push(files[i])
          listNames.push(files[i].name)
          listSelection.push({
            id: listSelection.length,
            value: files[i].name,
            url: URL.createObjectURL(files[i])
          })
        }
        setFileNames(listNames)
        setFileObjs(listObjs)
        setSelection(listSelection)
        setImage(listSelection.length)
        console.log("handleDrop: ",listNames,listObjs,listSelection)
      }
  }

  function openModal() {
    setFileNames([])
    setFileObjs([])
    setSelection([])
    setImage(0)
    setOpen(true)
}

  function closeModal() {
  setFileNames([])
  setFileObjs([])
  setSelection([])
  setImage(0)
  setOpen(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    onRequestNewTicket(name,desc,fileObjs)
//    onRequestNewTicket()
    closeModal()
  }

  return (
    <>
      <Button onClick={openModal} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
        <span>Request to open New Ticket</span>
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Title of new ticket</Form.Label>
              <Form.Control
                type="text"
                required
                onChange={e => setName(e.target.value)}
              />
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                onChange={e => setDesc(e.target.value)}
              />
              <div>
                <label class="custom-file-upload">
                  <input type="file" onChange={(e) => 
                    { handleDrop(e.target.files) }}/>
                    <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                      Drop Image Files Below
                </label>
              </div>

              <div style={{display: "flex"}}>
                <DragDropFiles handleDrop={handleDrop} filecount={fileNames.length}>
                  <div style={{height: 90, width: 190, marginLeft: "20px"}}>
                    {fileNames.map((file,i) => <div key={i} onClick={()=>handleSetImage(i)}>{file}</div>)}
                  </div>
                </DragDropFiles>
                <div>
                  { ( image > 0 && selection.length > 0 ) && 
                    <img src={selection[image-1].url} width="150px" alt="BigCo Inc. logo"/>
                  }
                </div>
              </div>

            </Form.Group>

{/*             <MuiThemeProvider>
                    <SelectField
                      floatingLabelText="Select Email"
                      onChange={(event, index, value) =>
                        setToEmail(value)
                      }
                    >
                    { users.map(iterObj => ( <MenuItem value={iterObj.obj.email} primaryText={iterObj.obj.email}  /> )) }
                  </SelectField>
            </MuiThemeProvider>
 */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Request New Ticket
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
