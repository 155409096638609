import React, {useEffect, useState} from 'react';
import Compose from './Compose/Compose';
import Toolbar from '../Toolbar/Toolbar';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import Message from './Message/Message';
import moment from 'moment';
import firedb from "../../../firebase" 
import { useAuth } from "../../../contexts/AuthContext"

import './MessageList.css';

const MY_USER_ID = 'apple';

export default function MessageList({showItem, user}) {
  const [ title, setTitle ] = useState('')
  const [ messages, setMessages ] = useState([])
  const [ reply, setReply ] = useState([])
  const { currentUser } = useAuth()

  const [ loading, setLoading ] = useState(false)
  const [ communicationEnabled, setCommunicationEnabled ] = useState(false)

  const SendReply = (reply) => {
    console.log("BY: ",user)
    const timeNow = Date.now()
    const obj = { From: currentUser.email,
                  ProfileURL: (user? user.profileURL: ""),
                  Reply: reply, ts: timeNow }
    firedb.database().ref(`0_tickets_convo/${showItem.ref_key}`).push(obj);
    setLoading(true)
  }

  useEffect(() => {
    if( loading )
    {
      console.log("RELOADING MESSAGES")
      firedb.database().ref(`0_tickets_convo/${showItem.ref_key}`)
        .on('value', (snapshot) => {
          const dbs = snapshot.val();
          let resList = [];
          for (let id in dbs) {
//            console.log("GETTING MESSAGES: ",dbs[id], id)
            resList.push(
/*                {
                id: 1,
                author: 'apple',
                message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
                timestamp: new Date().getTime()
              }
 */ 
              {
                id: id,
                author: dbs[id].From,
                url: dbs[id].ProfileURL,
                message: dbs[id].Reply,
                timestamp: dbs[id].ts

              }
            )
          }
//          console.log("BUILD LISt: ",resList)
          setMessages(resList)
          setLoading(false)
        });  
    }
  },[loading])

  useEffect(() => {
    if( title.length > 0 )
      setLoading(true)
    else
      getMessages();
  },[title])

  useEffect(() => {
    setMessages([])
    if( showItem && showItem.subject )
    {
      if( showItem.state == "ENABLED" )
      {
        setTitle(showItem.subject);
        setCommunicationEnabled(true)
      }
      else
      {
        setTitle("(Texting has been disabled by GM)");
        setCommunicationEnabled(false)
      }
    }
    else
    {
      getMessages()
      setTitle("");
    }
  },[showItem])
  
  const getMessages = () => {
     var tempMessages = [
        {
          id: 1,
          author: 'apple',
          message: 'This conversation panel becomes active after a staff member has processed the Ticket/Work Order Request.',
          timestamp: new Date().getTime()
        },
        {
          id: 2,
          author: 'apple',
          message: 'In the panel to the left residents can follow the work order progress as it is being serviced. Here in this conversation panel private conversation between Requester and Staff is possible.',
          timestamp: new Date().getTime()
        },
        {
          id: 3,
          author: 'apple',
          message: 'GM reserves the right to disable any or all parts of this messaging system for a given Ticket/Work Order at any time without notice.',
          timestamp: new Date().getTime()
        },
/*         {
          id: 2,
          author: 'orange',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 13,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 18,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 19,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 22,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 23,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 24,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 25,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 26,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 27,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 28,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
 */      ]
      setMessages([...tempMessages])
  }

  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let profURL = messages[i].url;
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author === "gm@sixty01.com";
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.author === current.author;
        
        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          url={profURL}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  }

    return(
      <div className="message-list">
        <Toolbar
          title={title}
          rightItems={[
            <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />
          ]}
        />

        <div className="message-list-container">{renderMessages()}</div>

        { title.length > 0 && communicationEnabled && <Compose SendReply={SendReply} rightItems={[
              <ToolbarButton key="emoji" icon="ion-ios-happy" />
            ]}/>
        }

{/*         <Compose rightItems={[
          <ToolbarButton key="photo" icon="ion-ios-camera" />,
          <ToolbarButton key="image" icon="ion-ios-image" />,
          <ToolbarButton key="audio" icon="ion-ios-mic" />,
          <ToolbarButton key="money" icon="ion-ios-card" />,
          <ToolbarButton key="games" icon="ion-logo-game-controller-b" />,
          <ToolbarButton key="emoji" icon="ion-ios-happy" />
        ]}/>
 */}      </div>
    );
}