import React from "react"
import Navbar from "./Navbar"
import { useRef, useState, useEffect } from "react"

import { useAuth } from "../../contexts/AuthContext"
import { database } from "../../firebase"
import firedb from "../../firebase" 
import { storage } from "../../firebase"

import MaterialTable from 'material-table'
import CallForAction from './CallForAction'
// https://stackoverflow.com/questions/63799347/material-table-select-row-export
import { CsvBuilder } from "filefy";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import { Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import NewComp from './Comps/NewComp'

import './AdminJK.css';

import { Container, Button } from "react-bootstrap"

// These are the listings we are pulling
// 1) In 'Emails' we are looking for items with subject=REGISTRATION

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// This is the old set of imports
//import { useAuth } from "../../contexts/AuthContext"
//import { storage, database } from "../../firebase"
//import firedb from "../../firebase" 

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const dataArg = [
  createData('Csupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('HdrArgDataArgrozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Hooneycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

export default function MyTickets() {
  const { currentUser } = useAuth()
  const staticObj = {f1: "f1", f2: "f2", f3: "f3", f4: "f4", f5: "f5"}
  const staticArr = [1,2,3,4,5,6,7,8,9,0]
  const [ report, setReport ] = useState("None")
  const [ searchTerm, setSearchTerm ] = useState("")
  const [ objArr, setObjArr] = useState([])
  const [ bldArr, setBldArr] = useState([])
  const [ items, setItems ] = useState([])
  const [ filteredItems, setFilteredItems ] = useState([])

  const [ registrationArr, setRegistrationArr] = useState([])
  
  const [ loadingMC, setLoadingMC ] = useState(false)
  const [ loadingMR, setLoadingMR ] = useState(false)
  const [ loadingMS, setLoadingMS ] = useState(false)

  const [ contactHdr, setContactHdr ] = useState([])
  const [ contactArr, setContactArr ] = useState([])
  const [ signupsHdr, setSignupsHdr ] = useState([])
  const [ signupsArr, setSignupsArr ] = useState([])
  const [ usersHdr, setUsersHdr ] = useState([])
  const [ usersArr, setUsersArr ] = useState([])
  const [ announcementsHdr, setAnnouncementsHdr ] = useState([])      //////////////
  const [ announcementsArr, setAnnouncementsArr ] = useState([])       /////////////
  const [ ticketsHdr, setTicketsHdr ] = useState([])      //////////////
  const [ ticketsArr, setTicketsArr ] = useState([])       /////////////

  const mCollectionContactUs = database.contactus // FireStore Database
  const mCollectionSignups = database.signups // FireStore Database
  const mCollectionUsers = database.users // FireStore Database
  const mCollectionAnnouncements = database.emails // FireStore Database

  const mRealTimeTickets = firedb.database().ref(`0_tickets`); // Realtime Database
  const mStorageTickets = storage.ref(`tickets`); //   const mStorageTickets = storage.ref(`${dbFilesName}/${newKey}/${fileObjs[i].name}`);

  let cntMS = useRef(0);
  let cntRepliesMS = useRef(0); // might need to utilize - useRef - https://stackoverflow.com/questions/58174893/variable-set-within-function-not-defined-in-other-function

  const [ tableTitle, setTableTitle] = useState("Contact Us")
  const [ dispIndex, setDispIndex] = useState(0)
  const [ dispTableName, setDispTableName] = useState("")
  const [ dispNames, setDispNames] = useState([])
  const [ dispHdr, setDispHdr] = useState([])
  const [ dispData, setDispData] = useState([])
  const [ currSelData, setCurrSelData] = useState([])
  const [ ready, setReady ] = useState(false)

  const [selectedUnitsFromCloud, setSelectedUnitsFromCloud] = useState("None")
  const [datasetKey, setDatasetKey] = React.useState("");
  const [datasetBucketKey, setDatasetBucketKey] = React.useState("");
  const [datasetNamesInDB, setDatasetNamesInDB] = React.useState([]);
  const [datasetsInDB, setDatasetsInDB] = React.useState([]);
  const [dataArray, setDataArray] = React.useState(dataArg); 
  const [dataArrayFiltered, setDataArrayFiltered] = React.useState(dataArg); 

  const [open, setOpen] = useState(false)
  const [gmId, setGmId] = React.useState('');
  const [groupNames, setGroupNames] = React.useState([]);
  const [newGroupName, setNewGroupName] = React.useState("");
  const [selectedGroup, setSelectedGroup] = React.useState("");

  function closeModal() {
    setOpen(false)
    console.log("f1: Resetting selection on close")
    setNewGroupName('')
    setSelectedGroup('')
  }

  useEffect(() => {
    const indexOfName = datasetNamesInDB.indexOf(selectedUnitsFromCloud)
    if( indexOfName != -1 )
    {
      const dbBucketRef = firedb.database().ref(`sys_unit_datasets/${datasetsInDB[indexOfName].obj.id}/`);
      setDatasetKey(datasetsInDB[indexOfName].key)
      setDatasetBucketKey(datasetsInDB[indexOfName].obj.id)
      console.log("NICE NICE loading from named dataset ",indexOfName, selectedUnitsFromCloud, datasetKey, datasetBucketKey)
      // Let's load the dataarrays
      dbBucketRef.on('value', (snapshot) => {
        const dbs = snapshot.val();
        let tmpArr = []
        let dataArr = []
        for (let id in dbs) {
          if( tmpArr.length == 0 )
          {
            Object.keys(dbs[id]).forEach(function(key) {
              console.log("ITERATION: ",key)

              tmpArr.push( 
                { title: key, field: key })


/*               tmpArr.push({ 
                "id": key,
                "numeric": "false", 
                "disablePadding": "true", 
                "label": key})
 */            });
            setDispHdr(tmpArr)   /////////////////////////
            console.log("NEEEEEWWWWW loading from named dataset 2 ",dbs[id],tmpArr)
          }
          dataArr.push(dbs[id])
        }
        setDataArray(dataArr)
        setDispData(dataArr)  /////////////////////////
        console.log("NEEEEEWWWWW loading from named dataset 3 ",selectedUnitsFromCloud, dataArr)
        setDataArrayFiltered(dataArr)
        setTableTitle(selectedUnitsFromCloud)
      })
    }
  }, [selectedUnitsFromCloud])

  useEffect(() => {
    setLoadingMC(true)
    setLoadingMR(true)
    setLoadingMS(true)
    setDispNames(["Contact Us", "Signups", "Users", "Announcements", "Tickets"])
    console.log("AdminJK INIT", )

    ///// Getting the avaiable dataset names in Units
    const mRealtimeUnits = firedb.database().ref(`sys_unit_names/`);
    mRealtimeUnits.on('value', (snapshot) => {
      const dbs = snapshot.val();
      let namesList = [];
      let tmpList = [];
      for (let id in dbs) {
        tmpList.push({ key: id, obj: dbs[id]})
        namesList.push(dbs[id].name)
      }
      setDatasetsInDB(tmpList)
      setDatasetNamesInDB(namesList)
      ////
      setDataArray([])
      setDataArrayFiltered([])
      console.log("INITIALIZING setDatasetsInDB w/ ",tmpList, namesList, datasetKey)
    })

    /////////////////////////////////////////////////
    const unSub_ContactUs = mCollectionContactUs.onSnapshot(snapshot => {
      setReady(false)
      let tmpArr = []
      snapshot.docs.map( doc => {
        const tmpObj = { guid: doc.id, ...doc.data() }
        tmpArr.push(tmpObj)
/*         if( doc.data().subject_text == "REGISTRATION" ) {  } */
      })
      setContactHdr([
        { title: 'GUID', field: 'guid', hidden: true },
        { title: 'Name', field: 'name_text' },
        { title: 'Email', field: 'email_text' },
        { title: 'Subject', field: 'subject_text' },
        { title: 'Body', field: 'body_text' }
      ])
      setContactArr(tmpArr)
      setDispHdr([
        { title: 'GUID', field: 'guid', hidden: true },
        { title: 'Name', field: 'name_text' },
        { title: 'Email', field: 'email_text' },
        { title: 'Subject', field: 'subject_text' },
        { title: 'Body', field: 'body_text' }
      ])
      setDispData(tmpArr)
      setDispTableName(dispNames[0])
      console.log("mCollectionContact: Completed: ",dispNames[0], tmpArr)
      setReady(true)
    })
    /////////////////////////////////////////////////
    const unSub_Signups = mCollectionSignups.onSnapshot(snapshot => {
      setReady(false)
      let tmpArr = []
      snapshot.docs.map( doc => {
        if( tmpArr.length == 0 )
        {
          let tmpKeyArr = [{ title: 'GUID', field: 'guid', hidden: false }]
          Object.keys(doc.data()).forEach(function(key,index) {
            tmpKeyArr.push({ title: key, field: key })
          })
          setSignupsHdr(tmpKeyArr)
        }
        const tmpObj = { guid: doc.id, ...doc.data() }
        tmpArr.push(tmpObj)
      })
      setSignupsArr(tmpArr)
      console.log("mCollectionSignups: Completed: ",tmpArr)
      setReady(true)
    })
    /////////////////////////////////////////////////////
    const unSub_Users = mCollectionUsers.onSnapshot(snapshot => {
      setReady(false)
      let tmpArr = []
      snapshot.docs.map( doc => {
        if( tmpArr.length == 0 )
        {
          let tmpKeyArr = [{ title: 'GUID', field: 'guid', hidden: true }]
          Object.keys(doc.data()).forEach(function(key,index) {
            tmpKeyArr.push({ title: key, field: key })
          })
          setUsersHdr(tmpKeyArr)
        }
        const tmpObj = { guid: doc.id, ...doc.data() }
        tmpArr.push(tmpObj)
        if( doc.data().email == 'gm@sixty01.com' )
        {
          setGmId( doc.id )
          console.log("f1: Getting GM groups", doc.data().group)
          setGroupNames(doc.data().group)
        }
      })
      setUsersArr(tmpArr)
      console.log("mCollectionUsers: Completed: ",tmpArr)
      setReady(true)
    })
    ///////////////////////////////////////////////
    const unSub_Announcements = mCollectionAnnouncements.onSnapshot(snapshot => { //////////////
      setReady(false)
/*       let tmpArr = []
      snapshot.docs.map( doc => {
        if( tmpArr.length == 0 )
        {
          let tmpKeyArr = [{ title: 'GUID', field: 'guid', hidden: true }]
          Object.keys(doc.data()).forEach(function(key,index) {
            tmpKeyArr.push({ title: key, field: key })
          })
          setAnnouncementsHdr(tmpKeyArr)              //////////////////
        }
        const tmpObj = { guid: doc.id, ...doc.data() }
        tmpArr.push(tmpObj)
      })
      setAnnouncementsArr(tmpArr)                     /////////////////
      console.log("mCollectionAnnouncements: Completed: ",tmpArr) /////////
 */      setReady(true)
    })
    ///////////////////////////////////////////////
    const unSub_Tickets = mRealTimeTickets.on('value', (snapshot) => {
      setReady(false)
      let tmpArr = []
      const dbs = snapshot.val();
      for (let id in dbs) {
        const doc = dbs[id]
        if( tmpArr.length == 0 )
        {
          let tmpKeyArr = [{ title: 'GUID', field: 'guid', hidden: true }]
          Object.keys(doc).forEach(function(key,index) {
            tmpKeyArr.push({ title: key, field: key })
          })
          setTicketsHdr(tmpKeyArr)              //////////////////
        }
        const tmpObj = { guid: id, ...dbs[id] }
        tmpArr.push(tmpObj)
        console.log("Iterating Tickets Snapshot: ",dbs[id], id)
      }
      setTicketsArr(tmpArr)                     /////////////////
      console.log("mRealTimeTickets: Completed: ",tmpArr) /////////
      setReady(true)
    })

    ///////////////////////    const fileList = mStorageTickets.child(`${currentUser.email}`)    let tmpFiles = []
/*     mStorageTickets.listAll().then((res) => {
      cntMS = res.prefixes.length
      cntRepliesMS = 0
      console.log("1 mStorageTickets.listAll() ", res, cntMS, cntRepliesMS)
      res.prefixes.forEach((itemRef, index) => {
        console.log("2 res.prefixes.forEach", itemRef, itemRef.name, index)
        itemRef.listAll().then((res) => {
          let tmpCnt = cntRepliesMS+1
          cntRepliesMS = tmpCnt
          console.log("3 ----- 4 times ---- itemRef.listAll().then((res)", res, cntMS, cntRepliesMS)
          res.items.forEach((itemRef, index) => {
            console.log("4 res.items.for------------------Each", itemRef, itemRef.name, index)
          })
          if( cntMS > 0 && cntRepliesMS == cntMS) {
            console.log("WE ARE  DONE----> ", cntMS, cntRepliesMS)
            setLoadingMS(false)
          }
        })
      })
      res.items.forEach((itemRef, index) => {
        console.log("loading files from GM share -> 1")
      })
    })
    console.log("AdminJK INIT2", )
 */
/*     bldArr.forEach((item, index) => {
      console.log("ITERATING bldArr: ", item)
    })
    staticArr.forEach((item, index) => {
      console.log("ITERATING staticArr: ", item)
    })
 */    //    const timer = setTimeout(() => console.log("Hello, World!"), 5000);
    const timer = setTimeout( () => {
      setLoadingMC(false)
      setLoadingMR(false)
      console.log("CLEARING LOADING SIGNALS ON TIMEOUT!") }
                                                                , 5000)
//    return () => clearTimeout(timer);
    return function cleanup() {
      unSub_ContactUs()
      unSub_Signups()
      unSub_Users()
      unSub_Announcements()
      mRealTimeTickets.off()  //      unSub_Tickets()
      mRealtimeUnits.off()
      clearTimeout(timer)
    };
  }, [])

  useEffect(() => {
    console.log("on loading notifications", dispIndex)
    handleBtn(dispIndex)
  }, [ready])

  function handleBtn(index) {
    setCurrSelData([])
    setDispIndex(index)
    setAge("");
    setTableTitle(dispNames[index])
    switch(index) {
      case 0:
        setDispHdr(contactHdr)
        setDispData(contactArr)
        // code block
        break;
      case 1:
        setDispHdr(signupsHdr)
        setDispData(signupsArr)
        // code block
        break;
      case 2:
        setDispHdr(usersHdr)
        setDispData(usersArr)
        // code block
        break;
      case 3:
        setDispHdr(announcementsHdr)   /////////////////////////
        setDispData(announcementsArr)  /////////////////////////
        // code block
        break;
      case 4:
        setDispHdr(ticketsHdr)   /////////////////////////
        setDispData(ticketsArr)  /////////////////////////
        // code block
        break;
      default:
      // code block
    }
  }

  //https://material-table.com/#/docs/get-started
  const data1 = [
    {name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63},
    {name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34},
    {name: 'ja kj', surname: 'kris', birthYear: 2045, birthCity: 63},
    {name: 'Zerya Betül', surname: 'sdfdfg', birthYear: 2023, birthCity: 34},
    {name: 'Zerya Betül', surname: 'dsfksdf', birthYear: 2012, birthCity: 34},
  ]
  const data2 = [
    {name: 'JAri1', email: 'jk@jk.com', msg: "hwfjskldfj"},
    {name: 'JAri2', email: 'jk@jk.com', msg: "hwfjskldfj"}
  ]
  function handleSelectionChange(data) {
    setCurrSelData(data)
  }



/*   <div>
  <MuiThemeProvider>
          <TextField
            hintText="Name of new Amenity"
            floatingLabelText="New Amenity"
            value={newAmenityName}
            onChange={e => setNewAmenityName(e.target.value)}
            floatingLabelFixed
          />
          <SelectField
            floatingLabelText="Select Amenity to Edit"
            value={editAmenity}
            onChange={(event, index, value) =>
                  handleSelectAmenity(value)
            }
          >
          { namedAmenities.map(name => ( <MenuItem value={name} primaryText={name}  /> )) }
        </SelectField>
  </MuiThemeProvider>
</div>


 */







  function handleEdit(data) {
    console.log("handleEdit(data)", data )
    alert('You want to edit  ' + data.length + ' rows')
    data.forEach((item, index) => {
      console.log("f1: ITERATING - building loist of ID's: ", item)
    })  
    setOpen(true)
  }

  function handleDelete(data) {
    setReady(false)
    switch(dispIndex) {
      case 0:
        data.forEach((item, index) => {
          console.log("handleDelete 00000", item )
          mCollectionContactUs.doc(item.guid).delete()
        })  
        break;
      case 1:
        data.forEach((item, index) => {
          console.log("handleDelete 11111", item )
          mCollectionSignups.doc(item.guid).delete()
        })  
        break;
      case 2:
        data.forEach((item, index) => {
          console.log("handleDelete 11111", item )
          mCollectionUsers.doc(item.guid).delete()
        })  
        // code block
        break;
      case 3:
        data.forEach((item, index) => {
          console.log("handleDelete 11111", item )
          mCollectionAnnouncements.doc(item.guid).delete()
        })  
        // code block
        break;
      case 4:
        setDispHdr(ticketsHdr)   /////////////////////////
        setDispData(ticketsArr)  /////////////////////////
        // code block
        break;
      default:
      // code block
    }
  }
  function togglePanel1() {
    console.log("togglePanel1()")
  }

  const wbToken = currentUser.email.replace('@', 'at').replace('.', 'at');
  const wbUser = `https://wb.allhavenhomes.com/boards/6sd96hzHhgacJfFBrfrMCe640${wbToken}`

  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    console.log("onHandleChange: ",event.target.value)
    setAge(event.target.value);
    console.log("onSetSelectedUnitsFromCloud: ",event.target.value)
    setSelectedUnitsFromCloud(event.target.value)
  };

  const handleSelectGroup = (event) => {
    console.log("on handleSelectGroup: ",event.target.value)
    setSelectedGroup(event.target.value);
  };

  const handleCreateGroup = (event) => {
    if( newGroupName.length > 0 )
    {
      // Make the name all CAPS
      const str = newGroupName.toUpperCase()
      console.log("f1: on handleCreateGroup: ",str)
      // Add to gm group array - GM is member of ALL groups
      const groups = groupNames? [ ...groupNames, str ] : [ str ]
      database.users.doc(gmId).update("group", groups)
      setNewGroupName('')
    }
  };
  const handleAddGroup = () => {
    console.log("f1: on handleAddGroup: ", selectedGroup, currSelData )
    if( selectedGroup.length > 0 )
    {
      const str = selectedGroup.toUpperCase()
      console.log("f1: on handleAddGroup: ",str)
      currSelData.forEach((item, index) => {
        let tempGroups = []
        if( item.group )
          tempGroups = item.group
        const check = tempGroups.find(x => x === str);
        if( !check ) // str is NOT in tempGroups - do the following
        {
          console.log("handleDelete f1: on handleAddGroup ITERATING:", item, tempGroups )
          const groups = [ ...tempGroups, str ]
          database.users.doc(item.guid).update("group", groups)  
        }
      })
    }
    closeModal()
  };
  const handleDelGroup = () => {
    console.log("f1: on handleDelGroup: ", selectedGroup, currSelData )
    if( selectedGroup.length > 0 )
    {
      const str = selectedGroup.toUpperCase()
      console.log("f1: on handleAddGroup: ",str)
      currSelData.forEach((item, index) => {
        let tempGroups = []
        // Remove str from tempGroups and store the update
        tempGroups = item.group.filter(function(value, index, arr){ 
          return value !== str;
        });
        console.log("handleDelete f1: on handleAddGroup ITERATING:", item, tempGroups )
        database.users.doc(item.guid).update("group", tempGroups)  
      })
    }
    closeModal()
  };

  const [prevUserId, setPrevUserId] = React.useState('');
  const [currUserId, setCurrUserId] = React.useState('');
  const ReAssignUserID = () => {
    console.log("f5: on ReAssignUserID: ", prevUserId, currUserId )
    ReAssignUserID1(prevUserId, currUserId)
  }

  const handleChangePage = (page) => {
    console.log("f5: handleChangePage: ", page )
  }

  const ReAssignUserID1 = (prev, curr) => {
    console.log("f5: on ReAssignUserID1: ", prev, curr )
    database.files.where('userId', '==', prev).get().then(function (querySnapshot) {
      console.log("f5: on ReAssignUserID1-2: ", prev, curr )
      querySnapshot.forEach(function (doc) {
          console.log(doc.id, 'f5:  Replacing files ', doc.data())
          database.files.doc(doc.id).update("userId", curr)
        })
    })
    database.folders.where('userId', '==', prev).get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
          console.log(doc.id, 'f5:  Replacing folders ', doc.data())
          database.folders.doc(doc.id).update("userId", curr)
        })
    })
  }

  return (
    <div id="data-center">
      <Navbar title='AdminJK Panel' />
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Units</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            onChange={handleChange}
          >
          { datasetNamesInDB.map(name => ( <MenuItem value={name}>{name}</MenuItem> )) }
          </Select>
        </FormControl>
      </div>

      <Container fluid>
        <Button variant="success" 
                style={{ marginLeft: "1em", marginBottom: "1em" }}
                onClick={()=>handleBtn(0)}>
          Contact Us
        </Button>
        <Button variant="success" 
                style={{ marginLeft: "1em", marginBottom: "1em" }}
                onClick={()=>handleBtn(1)}>
          SignUps
        </Button>
        <Button variant="success" 
                style={{ marginLeft: "1em", marginBottom: "1em" }}
                onClick={()=>handleBtn(2)}>
          Users
        </Button>
        <Button variant="success" 
                style={{ marginLeft: "1em", marginBottom: "1em" }}
                onClick={()=>handleBtn(3)}>
          Announcements
        </Button>
        <Button variant="success" 
                style={{ marginLeft: "1em", marginBottom: "1em" }}
                onClick={()=>handleBtn(4)}>
          Tickets
        </Button>      
        <CallForAction dataSelection={currSelData}/>
      </Container>

      <Container fluid>
        <MaterialTable    /*  https://material-table.com/#/ */
          title={tableTitle}
          
          
          onSelectionChange={data => {handleSelectionChange(data)}}
          columns={dispHdr}
/*           columns={[
            { title: 'Adress', field: 'name' },
            { title: 'Soyadı', field: 'surname' },
            { title: 'Doğum Yılı', field: 'birthYear', type: 'numeric' },
            { title: 'Doğum Yeri', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' } }
          ]}
 */  
          actions={[
            {
              tooltip: 'Edit Selected Users',
              icon: 'edit',
              onClick: (evt, data) => handleEdit(data)
            },
            {
              tooltip: 'Remove All Selected Users',
              icon: 'delete',
/*               onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows'),
 */              onClick: (evt, data) => handleDelete(data)
            },
            {
              position: "toolbarOnSelect",
              icon: "save",
              tooltip: "Export the selected rows!",
              onClick: (e, rowData) => {
                  const fileName = "TestDate_Table";
                  const builder = new CsvBuilder(
                      fileName + ".csv"
                  );
                  builder
                      .setColumns(
                          dispHdr.map(
                              (columnDef) => columnDef.title
                          )
                      )
                      .addRows(
                          rowData.map((rowData) =>
                              dispHdr.map(
                                  (columnDef) =>
                                      rowData[columnDef.field]
                              )
                          )
                      )
                      .exportFile();
              }
            }
          ]}
 
         data={dispData}

         options={{
          actionsColumnIndex: -1,
          selection: true,
          exportButton: true,
          showFirstLastPageButtons: true,
          pageSize: 5,
          pageSizeOptions: [5, 20, 50]
        }}

/*             options={{
              pageSize: 20,
              filtering: true,
              selection: true,
              exportButton: true
            }}
 */
                        detailPanel={rowData => {
                          const wbToken = rowData.email_text.replace('@', 'at').replace('.', 'at');
                          const wbUser = `https://wb.allhavenhomes.com/boards/6sd96hzHhgacJfFBrfrMCe640${wbToken}`
                    
                          console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", wbUser)

                          return (
                            <iframe
                              width="100%"
                              height="515"
/*                               src="https://www.youtube.com/embed/C0DPdy98e4c"
 */                              src={wbUser}
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            />
                          )
                        }}
                        onRowClick={(event, rowData, togglePanel) => togglePanel1()}
        /> 
      </Container>
 
      <hr/>
      <Container fluid>

testing {dispNames[dispIndex]}


{/*         <ul>
          { staticArr.map((item,index) => (
            <>
              <li key={index}> hello: {item}</li>
              <button>
                Press me2
              </button>
            </>
          ))
          }
        </ul>
 */}      </Container>
<hr/>
      
      <Container fluid>
        <ul>
          { staticArr.map((item,index) => (
            <div key={index}>
              <li key={index}> hello: {item}</li>
              <button>
                Press me2
              </button>
            </div>
          ))
          }
        </ul>
      </Container>
<hr/>
      <Container fluid>
        <ul>
          { loadingMR && bldArr.map((item,index) => (
            <li key={index}> LOADING FOR: {item}</li>
          ))
          }
        </ul>
      </Container>

      <Container fluid>
        <div className="d-flex align-items-center">
            <h2>Prototyping new Pages</h2> 
            <hr/>
        </div>

        <Form.Label>Previous ID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Previous ID"
          value={prevUserId}
          onChange={e => setPrevUserId(e.target.value)}
        />
        <Form.Label>Current ID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Current ID"
          value={currUserId}
          onChange={e => setCurrUserId(e.target.value)}
        />

        <Button variant="success" onClick={ReAssignUserID}>
          Replace
        </Button>

        


        <div id="messenger-panel" className="messenger-panel-show">
          <div className="scrollable sidebar">
{/*             <ItemListing
              tickets={filteredTickets} 
              currTicket={showTicket}
              setTicket={setTicket} 
              setSearchTerm={setSearchTerm} />
 */}          </div>
          <div className="scrollable content">
{/*             <MessageList dbRef={dbRef} showItem={showTicket} user={userAcct}/>
 */}          </div>
        </div>
          
      </Container>

      <Modal show={open} onHide={closeModal}>
        <Form >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Edit Group Association for Selection of Users</Form.Label>
                <MuiThemeProvider>
                  <div className="d-flex">
                    <Form.Control
                      placeholder="Enter a group name to create new"
                      type="text"
                      required
                      value={newGroupName}
                      onChange={e => setNewGroupName(e.target.value)}
                    />
                    <Button variant="success" onClick={handleCreateGroup}>
                      Create
                    </Button>
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Select a group</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedGroup}
                        onChange={handleSelectGroup}
                      >
                      { groupNames && groupNames.map(name => ( <MenuItem value={name}>{name}</MenuItem> )) }
                      </Select>
                    </FormControl>
                  </div>
                </MuiThemeProvider>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mt-5" variant="secondary" onClick={closeModal}>
              Close
            </Button>
            { selectedGroup && selectedGroup.length>0 ? (
                <>
                  <Button className="mt-5" variant="success" onClick={handleDelGroup}>
                    Remove user(s)
                  </Button>
                  <Button className="mt-5" variant="success" onClick={handleAddGroup}>
                    Add user(s) to group
                  </Button>
                </>
                ):(
                <>
                  <Button className="mt-5" disabled variant="success" onClick={handleDelGroup}>
                    Remove user(s)
                  </Button>
                  <Button className="mt-5" disabled variant="success" onClick={handleAddGroup}>
                    Add user(s) to group
                  </Button>
                </>
            ) }
          </Modal.Footer>
        </Form >
      </Modal >

      <NewComp title='NewPage' />

    </div>
    
  )
}
