import React, { useState } from "react"
import DragDropFiles from './DragDropFiles'

import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { database } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import { ROOT_FOLDER } from "../../hooks/useFolder"

export default function DelSelectionButton({onDelSelection,cnt}) {
/*   export default function AddFolderButton({ currentFolder }) {
 */
  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [ fileObjs, setFileObjs ] = useState([])
  const [ fileNames, setFileNames ] = useState([
/*     'nice.pdf',
    'verycool.jpg',
    'amazing.png',
    'goodstuff.mp3',
    'thankyou.doc'
 */  ])
  const [selection, setSelection] = useState([])
  const [image,setImage] = useState(0)

  const { currentUser } = useAuth()

  const handleSetImage = (i) => {
    console.log("handleSetImage: ",i)
    setImage(i)
  }

  const handleDrop = (files) => {
    if( files )
    {
        let listObjs = [...fileObjs]
        let listNames = [...fileNames]
        let listSelection = [...selection]
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return
          listObjs.push(files[i])
          listNames.push(files[i].name)
          listSelection.push({
            id: listSelection.length,
            value: files[i].name,
            url: URL.createObjectURL(files[i])
          })
        }
    //    this.setState({files: fileList})
        setFileNames(listNames)
        setFileObjs(listObjs)
        setSelection(listSelection)
        setImage(listObjs.length-1)
        console.log("handleDrop: ")
      }
  }

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    onDelSelection(name,desc)

/*     if (currentFolder == null) return

    const path = [...currentFolder.path]
    if (currentFolder !== ROOT_FOLDER) {
      path.push({ name: currentFolder.name, id: currentFolder.id })
    }

    database.folders.add({
      name: name,
      parentId: currentFolder.id,
      userId: currentUser.uid,
      path: path,
      createdAt: database.getCurrentTimestamp(),
    })
 */    setName("")
    closeModal()
  }

  return (
    <>
      <Button onClick={openModal} variant="outline-success" size="sm">
        <span>Delete Selection ({cnt})</span>
{/*         <FontAwesomeIcon icon={faFolderPlus}  />
 */}      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Confirm Deletion of Selection</Form.Label>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              Delete
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
