import React, { useState, useEffect } from "react"
import DragDropFiles from './DragDropFiles'

import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus, faEnvelope } from "@fortawesome/free-solid-svg-icons"
//import { database } from "../../firebase"
//import { useAuth } from "../../contexts/AuthContext"
//import { ROOT_FOLDER } from "../../hooks/useFolder"
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";


export default function AddEmailButton({setTicketCnt, onAddNewEmail, hdrArray, users}) {
  const [open, setOpen] = useState(false)
  const [selectedColumnWithEmails, setSelectedColumnWithEmails] = useState(0)
  const [selectedColumnWithEmailsValue, setSelectedColumnWithEmailsValue] = useState()
  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("Yes")
  const [attachments, setAttachments ] = useState([])
  const [attachmentNames, setAttachmentNames ] = useState([])
//  const [ fileObjs, setFileObjs ] = useState([])
//  const [ fileNames, setFileNames ] = useState([])

  useEffect(() => {
    console.log("selectedColumnWithEmails w/ ", selectedColumnWithEmails, users)
  }, [selectedColumnWithEmails])

  const handleDrop = (files) => {
    if( files )
    {
        let listObjs = [...attachments]
        let listNames = [...attachmentNames]
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return
          listObjs.push(files[i])
          listNames.push(files[i].name)
/*           listSelection.push({
            id: listSelection.length,
            value: files[i].name,
            url: URL.createObjectURL(files[i])
          })
 */     }
        setAttachmentNames(listNames)
        setAttachments(listObjs)
        console.log("handleDrop: ",listNames,listObjs)
      }
  }

  function openModal() {
  //    setTicketCnt(1)
      setOpen(true)
  //    setFileNames([])
  //    setFileObjs([])
  //    setSelection([])
  //    setImage(0)
  }

  function closeModal() {
    console.log("AdNewEmail REPORTING: ",hdrArray,selectedColumnWithEmails)
    //    setTicketCnt(10)
    setOpen(false)
  }

  function handleSaveEmail() {
    onAddNewEmail(subject,body,attachments,selectedColumnWithEmails,"draft")
    setSubject("")
    closeModal()
  }
  function handleSaveSendEmail() {
    onAddNewEmail(subject,body,attachments,selectedColumnWithEmails,"send")
    setSubject("")
    closeModal()
  }

  return (
    <>
{/*         <Tooltip title="Edit">
          <IconButton aria-label="edit"
                    onClick={() => handleAction("edit")}
                    >
            <MailOutlineIcon />
          </IconButton>
        </Tooltip>
 */}
      <Button onClick={openModal} variant="outline-success" size="sm">
{/*         <span>Create Email</span>
 */}
         <FontAwesomeIcon icon={faEnvelope}  />
      </Button>
      <Modal show={open} onHide={closeModal}>
{/*         <Form onSubmit={handleSubmit}>
 */}    
 
        <MuiThemeProvider>
          <SelectField
            style={{ marginLeft: "1em" }}
            floatingLabelText="Select Column with Email Addresses"
            value={selectedColumnWithEmailsValue}
            onChange={(event, index, value) =>
              {
                setSelectedColumnWithEmails(index)
                setSelectedColumnWithEmailsValue(value)
              }
            }
          >
           { hdrArray.map(obj => ( <MenuItem value={obj.id} primaryText={obj.id}  /> )) }
  
           <MenuItem value="test1" primaryText="No a valid entry - debug"/>
          </SelectField>
        </MuiThemeProvider>

 
 
        <Form >
          <Modal.Body>
            <Form.Group>



              <Form.Label>Subject:</Form.Label>
              <Form.Control
                type="text"
                required
/*                 value={name}
 */                onChange={e => setSubject(e.target.value)}
              />
              <Form.Label>Body:</Form.Label>
              <Form.Control
                type="text"
                required
/*                 value={name}
 */                onChange={e => setBody(e.target.value)}
              />
              <div>
                <label class="custom-file-upload">
                  <input type="file" onChange={(e) => 
                    { handleDrop(e.target.files) }}/>
                    <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                      Drop Image Files Below
                </label>
              </div>

              <div style={{display: "flex"}}>
                <DragDropFiles handleDrop={handleDrop} filecount={attachmentNames.length}>
                  <div style={{height: 90, width: 190, marginLeft: "20px"}}>
                    {attachmentNames.map((file,i) => <div key={i} >{file}</div>)}
                  </div>
                </DragDropFiles>
{/*                 <div>
                  { ( image > 0 && selection.length > 0 ) && <img src={selection[image-1].url} width="150px" alt="BigCo Inc. logo"/>}
                </div>
 */} 
              </div>


            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" onClick={handleSaveEmail}>
              Save Email
            </Button>
            <Button variant="success" onClick={handleSaveSendEmail}>
              Save 'nd Send Email
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
