import React, { useState } from "react"
import { Button, Modal, Form } from "react-bootstrap"

export default function ReplyTicketButton({onReplyTicket}) {
  // All this simple Modal does is get a String reply, which is passed up through onReplyTicket
  const [open, setOpen] = useState(false)
  const [replyMsg, setReplyMsg] = useState("")

  function openModal() {
    setOpen(true)
  }
  function closeModal() {
    setOpen(false)
  }
  function handleSubmit(e) {
    e.preventDefault()
    onReplyTicket(replyMsg)
    closeModal()
  }
  return (
    <>
      <Button onClick={openModal} variant="outline-success" size="sm">
        <span>Reply to Ticket</span>
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Reply</Form.Label>
              <Form.Control
                type="text"
                onChange={e => setReplyMsg(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Save Reply
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
