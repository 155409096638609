import React from "react"
import Navbar from "./Navbar"
import Ticket from "./Ticket"
import { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"

import { Container } from "react-bootstrap"
import { faExpand, faFile, faLock, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddTicketButton from "./AddTicketButton"
import DelSelectionButton from "./DelSelectionButton"

import { storage } from "../../firebase"
import firedb from "../../firebase" 
import './Ticket.css'; 

const componentName = "0_tickets"
// Item (obj), <named> lightbulb, <in> tickets, <by> gm, <ts> 3423463453

export default function Tickets() {
  const [ viewEditTicket, setViewEditTicket] = useState(null)
  const [ previewURL, setPreviewURL] = useState(null)
  const [ dbRef, setDbRef] = useState(null)
  const { currentUser } = useAuth()
  const [ ticketArray, setTicketArray] = useState([])
  const [ ticketCnt, setTicketCnt] = useState(10)
  const [ ticketSelection, setTicketSelection] = useState([])
  const [ fileObjList, setFileObjList] = useState([])

  const [ ticketNames, setTicketNames] = useState([])
  const [ selection, setSelection] = useState([])
  const [ users, setUsers] = useState([])

  const handleOnTicketSelection = (dbRef, action) => {
    console.log("handleOnTicketSelection->: ",dbRef,action)
    console.log("handleOnTicketSelection->SELECTION->: ",ticketSelection)
    let ticketSel = []
    if(action == "add")
    {
      ticketSel = [...ticketSelection, dbRef]
    }
    else
    { 
      if( ticketSelection.length > 1 )
        ticketSel = ticketSelection.splice(ticketSelection.indexOf(dbRef), 1);
    }
    setTicketSelection(ticketSel)

//    const ticketRef = firedb.database().ref(`${e}/`);
//    ticketRef.remove();
  }

  const onAddNewTicket = (value, desc, fileObjs) => {
    setFileObjList(fileObjs)
    const ts = Date.now()
    const ticketRef = firedb.database().ref(`${dbRef}`);
    var newRef = ticketRef.push(); // this does *not* call the server
    var newKey = ""
    if (fileObjs.length > 0) 
      newKey = newRef.key;  // Perhps only key as a property
    const newObj = {
      body: desc,
      company: "HOA",
      email: currentUser.email,
      files: newKey,
      group: "HOA",
      name: currentUser.email,
      priority: "0",
      ref_key: newKey,
      reply_cnt: "0",
      subject: value,
      tag: "na",
      timestamp: ts,
      uid: currentUser.uid
    }
    ticketRef.push(newObj);
    // Store files in storage under <newKey>
    if (fileObjs.length > 0) 
    {
      for (var i = 0; i < fileObjs.length; i++)
      {
        console.log("Uploading: ")
        const uploadJob = storage
          .ref(`sys_tickets/${newKey}/${fileObjs[i].name}`)
          .put(fileObjs[i])
        uploadJob.on(
          "state_changed",
          snapshot => {},
          error => {
            console.log(error)
          },
          () => {
            console.log("UPLOAD COMPLETE")
            setTicketCnt(ticketCnt+1)
          }
        )  
      }
    }
    /////////////////////////
  }

  const onDelSelection = () => {
    ticketSelection.map((ticket, index) =>
    {
      console.log("onDelSelection-SUCCESS: ",ticket)
      const ticketRef = firedb.database().ref(`${ticket}`);
      ticketRef.remove();
    })
    setTicketCnt(ticketCnt-1)
    setTicketSelection([])
    setPreviewURL()
  }

  const onEdit = (key) => {
    console.log("onEdit: ",key)
    ////////////////////////////////0
//    const ticketRef = firedb.database().ref(`sys_${componentName}/${key}`);
//    ticketRef.remove();
  }

  const onDelete = (db) => {
    console.log("onDelete: ",db)
    const ticketRef = firedb.database().ref(db);
    ticketRef.remove();
    setTicketCnt(ticketCnt-1)
    setSelection([])
  }

  const onReply = (key) => {
    console.log("onReply: ",key)
    const ts = Date.now()
    const replyObj = {
      named: "reply",
      in: "reply", 
      by: currentUser.email,
      ts: ts
    }
    const ticketRef = firedb.database().ref(`${dbRef}/${key}`);
    ticketRef.push(replyObj);
  }

  useEffect(() => {
    console.log("INITIALIZING component: ",componentName)
    
    const ticketRef = firedb.database().ref(`${componentName}/`);
    setDbRef(`${componentName}`)
    ticketRef.on('value', (snapshot) => {
      const dbs = snapshot.val();
      let ticketList = [];
      let namesList = [];
      for (let id in dbs) {
        console.log("INITIALIZING-ITERATION: ",dbs[id])
        ticketList.push({ key: id, obj: dbs[id]})
        let first = dbs[id]
        console.log("INITIALIZING-ITERATION-ticketList: ",ticketList, first)
        Object.keys(first).forEach(key => {
          namesList.push(first[key].subject)
          console.log("FOUND DATA-",key, first[key])
        }); 
      }
      console.log("INITIALIZING-REPORT: ",ticketList)
//      console.log("INITIALIZING-REPORT1: ",namesList)
      setTicketArray(ticketList)
//      setTicketNames(namesList)

      const dbRef = firedb.database().ref(`sys_users/`);
      dbRef.on('value', (snapshot) => {
        const dbs = snapshot.val();
        let tmpList = [];
        for (let id in dbs) {
          console.log("INITIALIZING-ITERATION: ",dbs[id])
          tmpList.push({ key: id, obj: dbs[id]})
        }
        console.log("INITIALIZING-REPORT: ",tmpList)
        setUsers(tmpList)
      });

      // List all Vendors

    });  
  }, []);

  return (
    <>
      <Navbar title='Tickets' />
      <Container fluid>
        <div className="d-flex align-items-center">
          <AddTicketButton setTicketCnt={setTicketCnt} onAddNewTicket={onAddNewTicket} users={users}/>
          { ticketSelection.length > 0 ? 
          <DelSelectionButton onDelSelection={onDelSelection} cnt={ticketSelection.length}/> : ""}
        </div>
        <div>
          <Container id="outerbox">
            { viewEditTicket == null ? (
              <Container id="innerbox">
                { previewURL && previewURL.length > 0 ? (
                    <img src={previewURL} height="250px" width="auto" />
                  ) : (
                    <>
                      <h2>Preview of images. </h2>
                      <h4>Click an image of any Ticket</h4>
                    </>
                  )
                }
              </Container>
              ) : (
                <h1>Preview of images. </h1>
              )
            }            
          </Container>
 
          <h1> Listing of {ticketArray.length} tickets( dbgMsg:{ticketCnt} )</h1>
 
          {ticketArray
            ? ticketArray.map((ticket, index) =>
             <Ticket  setPreviewURL={setPreviewURL}
                      currentUser={currentUser}
                      dbRef={dbRef} 
                      onEdit={onEdit} 
                      onDelete={onDelete} 
                      onReply={onReply} 
                      ticket={ticket}
                      ticketCnt={ticketCnt}
                      handleOnTicketSelection={handleOnTicketSelection} 
                      index={index} />)
            : ''}
        </div>

      </Container>
    </>
  )
}
