import React, { useState, useEffect } from 'react';
// https://www.youtube.com/watch?v=8r1Pb6Ja90o
//import { firedb, storage } from "./../../../firebase"
// This beow breaks the api
//import storage from "./../../../firebase"
import { storage } from "./../../../firebase"
import firedb from "./../../../firebase"

import DragDropFiles from './DragDropFiles'
import aTimePicker from './aTimePicker/aTimePicker'
import RestrictionPeriods from './RestrictionPeriods'

import { Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";



import ICVpage from "./../../google-drive/ImageCollectionViewer/ICVpage"

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
//////////////////////////
//////////////////////////////////////
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
/////////////////////////////////////

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
//    marginTop: theme.spacing(3),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const fileTable = {
  height: 90,
  width: 190,
  marginLeft: "20px",
  overflowY: "scroll"
};

const styleDiv = {
  margin: '20px',
//  width: '250px',
//  height: '250px',
//  backgroundColor: 'yellow',
};
const styleDesc = {
  position: 'absolute',
//  margin: '20px',
  top: 130,
  left: 23,
  width: '627px',
  height: '50px',
//  backgroundColor: 'yellow',
};

const listAmenities = [
  { id: 'bar', numeric: false, disablePadding: true, label: 'Gus' },
  { id: 'pool', numeric: true, disablePadding: false, label: 'Pool' },
];

const restrictionsPeriods = 
  [ "All Days",
    "Only Mon/Fri",
    "Only Sat/Sun",
    "Only Holidays",
    "Any Day Except Holidays",
    "Weekdays and Holidays",
    "Weekends and Holidays"] 

const beginTimeArray = 
  [ "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "Noon",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM"] 

const timeDurationArray = 
  [ "All the time",
    "2 hours",
    "3 hours",
    "4 hours",
    "5 hours",
    "6 hours",
    "7 hours",
    "8 hours",
    "9 hours",
    "10 hours"]     

//export default function ManageAmenities({databasename, dataHeader, dataPlaceHolders}) {
export default function ManageAmenities() {
  const [ title, setTitle ] = useState('');
  const [ fileObjs, setFileObjs ] = useState([])
  const [ fileNames, setFileNames ] = useState([
/*     'nice.pdf',
    'verycool.jpg',
    'amazing.png',
    'goodstuff.mp3',
    'thankyou.doc'
 */  ])
  const [selection, setSelection] = useState([])
  const [image,setImage] = useState(null)
//  const [descTitle,setDescTitle] = useState("Please Provide a Title")
//  const [desc,setDesc] = useState("Enter a delightful description")
  const [descTitle,setDescTitle] = useState("")
  const [desc,setDesc] = useState("")
  const [mode, setMode] = React.useState('new'); ////////////////////
  const [newAmenityName, setNewAmenityName] = React.useState('');
  const [editAmenity, setEditAmenity] = React.useState('');
  const [namedAmenities, setNamedAmenities] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");

  const [timeDuration, setTimeDuration] = React.useState(timeDurationArray.length);
  const [beginTime, setBeginTime] = React.useState(beginTimeArray.length);
  const [restrictionArray, setRestrictionArray] = React.useState([]);
  const [restrictionSpec, setRestrictionSpec] = React.useState(0);
  const [restrictionVector, setRestrictionVector] = React.useState([100,100,100]);


  const reporta = () => {
    console.log("Mode: ",mode)
    console.log("newAmenityName: ",newAmenityName)
    console.log("editAmenity: ",editAmenity)
    console.log("DescriptionTitle: ",descTitle)
    console.log("Description: ",desc)
    console.log("fileObjs: ",fileObjs)
    console.log("----> fileNames: ",fileNames)
  }

  useEffect(() => {
    setRestrictionVector([timeDuration, beginTime, restrictionSpec])
//    if( restrictionSpec == 0 )
//    {
//      setTimeDuration(timeDurationArray.length)
//      setBeginTime(beginTimeArray.length)
//    }
    console.log("Reporting RECEIVED restrictionArray: ",restrictionArray)
  }, [timeDuration, beginTime, restrictionSpec]);

  const handleTimeDurationSelect = (index,value) => {
    console.log("Index: ",index)
    console.log("Value: ",value)
//    if( timeDuration == timeDurationArray.length &&
//      (!restrictionArray['mf'] && !restrictionArray['ss'] && !restrictionArray['hd'])
//      )
//      {

//      }
    setTimeDuration(index)
  }
  const handleBeginTimeSelect = (index,value) => {
    console.log("Index: ",index)
    console.log("Value: ",value)
    setBeginTime(index)
  }

  const handleClickOpen = (btn) => {
//    console.log("OPERATION to perform: ",btn)
    if(btn=="delete")
    {
      setDialogTitle("Deleting")
      setDialogText("This operation will DELETE the amenity permanently from the database.")
    }
    else
    {
      if(mode=="new")
      {
        console.log("Mode: ",mode)
        console.log("newAmenityName: ",newAmenityName)
        console.log("editAmenity: ",editAmenity)
    
        console.log("Description: ",desc)
        console.log("fileObjs: ",fileObjs)
    
        console.log("restrictionSpec: ",restrictionSpec)
        console.log("timeDuration: ",timeDuration)
        console.log("beginTime: ",beginTime)
        console.log("restrictionVector: ",restrictionVector)
        if( newAmenityName.length > 0 )
        {
          setDialogTitle("Saving")
          setDialogText("A new amenity is being created and will appear on the public site.")
        }
        else
          return
      }
      else
      {
        setDialogTitle("Updating")
        setDialogText("Amenity title and description is being updated.")
      }
    }
    setOpen(true);
  };

  const handleClose = (btn) => {
/*     if(btn=="cancel")
    {
      alert("Cancel")
    }
 */
    if(btn=="ok")
    {
      if(dialogTitle=="Saving")
//        alert("SAVING")
        handleSave()
      if(dialogTitle=="Updating")
//        alert("UPDATING")
        handleSave()
      if(dialogTitle=="Deleting")
//        alert("DELETING")
        handleDelete(true)
    }
    setOpen(false);
  };

  const handleToggle = (event, newMode) => {
    console.log("handleToggle: 265",newMode)
    if (newMode !== null) {
      setMode(newMode);
      if(newMode=="new") // reset edit resources
      {
        setNewAmenityName("")
        setNamedAmenities([])
        setEditAmenity("")
        setDescTitle("")
        setDesc("")
        // Clear out pictures
        setSelection([])
        setFileNames([])
      }
      if(newMode=="edit") // reset new resources
      {
        setTimeDuration(timeDurationArray.length);
        setBeginTime(beginTimeArray.length);
        setRestrictionSpec(0);
        setNewAmenityName("")
        setDescTitle("")
        setDesc("")
        setFileNames([])
        setFileObjs([])
        setSelection([])
      // Get Amenity names from DB
        const dbsRef = firedb.database().ref('sys_amenities')
        dbsRef.on('value', (snapshot) => {
          const dbs = snapshot.val();
          let namedList = [];
          for (let id in dbs) {
            console.log("REPORTING NAME: ",dbs[id].name)
            namedList.push(dbs[id].name);
          }
          console.log("FINISHED ITERATION: ",namedList)
          setNamedAmenities(namedList)
        });
      }
    }
    else{
      setTimeDuration(timeDurationArray.length);
      setBeginTime(beginTimeArray.length);
      setRestrictionSpec(0);
      setNewAmenityName("")
      setNamedAmenities([])
      setEditAmenity("")
      setDescTitle("")
      setDesc("")
      setFileNames([])
      setFileObjs([])
      setSelection([])
      setTimeDuration(timeDurationArray.length)
      setBeginTime(beginTimeArray.length)
      setRestrictionSpec(0)

    }
  };

  const classes = useStyles();

//  const handleOnChange = (e,key) => {
  const handleDrop = (files) => {
    console.log("handkleDrop: ",files[0])
    if( files )
    {
        let listObjs = [...fileObjs]
        let listNames = [...fileNames]
        let listSelection = [...selection]
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return
          setImage(files[i])
          listObjs.push(files[i])
          listNames.push(files[i].name)
          listSelection.push({
            id: listSelection.length,
            value: files[i].name,
            url: URL.createObjectURL(files[i])
          })
        }
    //    this.setState({files: fileList})
        setFileNames(listNames)
        setFileObjs(listObjs)
        setSelection(listSelection)
    }
  }

  const handleSelectAmenity = (value) =>
  {
//    alert("Selected Amenity")
    console.log("Selected Amenity: ",value)
    setEditAmenity(value)
    // Load up UI with the data of said selection
    const dbsRef = firedb.database().ref()
    const query = firedb.database().ref('sys_amenities').orderByKey()
    query.once("value")
      .then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        let pkey = childSnapshot.key
        let chval = childSnapshot.val()
        let nameToEdit = value
        //check if remove this child
        if(chval.name == nameToEdit){
          // we have the ref now
          //////dbsRef.child("sys_amenities/"+pkey).remove();
          console.log("Selected Amenity - DATA: ",chval.descriptionTitle)
          setDesc(chval.description)
          setDescTitle(chval.descriptionTitle)
          setFileNames([])
          setFileObjs([])
            return true;
        }
      });
    });
  }

  const handleDelete = (flag) => {
//    alert("Deleting Amenity")
    // Deleting the realtime database entry of name and the storage dir of name
    // https://stackoverflow.com/questions/49881809/how-to-delete-item-from-firebase-list
    const dbsRef = firedb.database().ref()
    const query = firedb.database().ref('sys_amenities').orderByKey()
    query.once("value")
      .then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        let pkey = childSnapshot.key
        let chval = childSnapshot.val()
        let nameToDelete = editAmenity
        //check if remove this child
        if(chval.name == nameToDelete){
          dbsRef.child("sys_amenities/"+pkey).remove();
          return true;
        }
      });
    });
    if(flag)
    {
      setNewAmenityName("")
      setNamedAmenities([])
      setEditAmenity("")
      setDescTitle("")
      setDesc("")
      // Clear out pictures
      setSelection([])
      setFileNames([])
      setMode("new")
    }
  }

  const handleSave = (event) => {
//    alert("Storing Amenity")
    const newName = mode=="edit" ? editAmenity : newAmenityName

    console.log("Mode: ",mode)
    console.log("newAmenityName: ",newAmenityName)
    console.log("editAmenity: ",editAmenity)
    console.log("----> newName: ",newName)

    console.log("Description: ",desc)
    console.log("fileObjs: ",fileObjs)

    console.log("restrictionSpec: ",restrictionSpec)
    console.log("timeDuration: ",timeDuration)
    console.log("beginTime: ",beginTime)
    handleDelete(false)

    for (var i = 0; i < fileObjs.length; i++) {
      if (fileObjs[i].name == fileNames[i]) 
      {
        console.log("Uploading: ",fileNames[i])
        const uploadJob = storage
          .ref(`PUBLIC/sys_amenities/${newName}/${fileObjs[i].name}`)
          .put(fileObjs[i])
        uploadJob.on(
          "state_changed",
          snapshot => {},
          error => {
            console.log(error)
          },
          () => {
            console.log("UPLOAD COMPLETE")
          }
        )  
      }
    }
    const newDescTitle = descTitle.length ? descTitle : "Please Provide a Title"
    const newDesc = desc.length ? desc : "Enter a delightful description"
    const amenityRef = firedb.database().ref("sys_amenities");
    const amenity = {
      name: newName,
      descriptionTitle: newDescTitle,
      description: newDesc,
      valid: true,
      timeDuration: timeDuration,
      beginTime: beginTime,
      restrictionSpec: restrictionSpec
    };
    amenityRef.push(amenity);
    setTimeDuration(timeDurationArray.length);
    setBeginTime(beginTimeArray.length);
    setRestrictionSpec(0);

    setMode("new")
    setEditAmenity("")
    setNewAmenityName("")
    setDescTitle("")
    setDesc("")
    setFileNames([])
    setFileObjs([])
    setSelection([])


/*     for (var i = 0; i < fileObjs.length; i++) {
      if (fileObjs[i].name == fileNames[i]) 
      {
        database.amenities.add({
//          url: url,
          name: fileObjs[i].name,
          createdAt: database.getCurrentTimestamp(),
          folderId: currentFolder.id,
          userId: currentUser.uid,
        })
      }
    }
 */

/*
    for (var i = 0; i < fileObjs.length; i++) {
      if (fileObjs[i].name == fileNames[i]) 
      {
        storage
        .ref(`PUBLIC/${newAmenityName}/`)
        .child(fileObjs[i].name)
        .getDownloadURL()
        .then(url => {
          console.log(url)
        })
      }
    }
 */

    //////////////////////
    //   AddFileButton line 64
/*     const uploadTask = storage
      .ref(`/files/${currentUser.uid}/${filePath}`)
      .put(file)
      firebase rules:       allow read, write: if request.auth != null;

      const uploadJob = storage
      .ref(`PUBLIC/${newAmenityName}/${image.name}`).put(image)

 */    ///////////////////////
  };

  useEffect(() => {
    console.log("Reporting RECEIVED restrictionArray: ",restrictionArray)
    setRestrictionSpec (
      ( ((!restrictionArray['mf']) && (!restrictionArray['ss']) && (!restrictionArray['hd']))     ) ? 
          0 : 
          (  ((restrictionArray['mf']) && (!restrictionArray['ss']) && (!restrictionArray['hd'])) ? 1 :
            ( 
              (  ((!restrictionArray['mf']) && (restrictionArray['ss']) && (!restrictionArray['hd'])) ? 2 :
                ( 
                  (  ((!restrictionArray['mf']) && (!restrictionArray['ss']) && (restrictionArray['hd'])) ? 3 :
                    ( 
                      (  ((restrictionArray['mf']) && (restrictionArray['ss']) && (!restrictionArray['hd'])) ? 4 :
                        ( 
                          (  ((restrictionArray['mf']) && (!restrictionArray['ss']) && (restrictionArray['hd'])) ? 5 :
                            ( 
                              (  ((restrictionArray['mf']) && (restrictionArray['ss']) && (restrictionArray['hd'])) ? 6 :
                                ( 
                                  0
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
    )
  }, [restrictionArray]);

  return (
    <>
    <div>
    <container 
      className="ToolsContainer" 
      style={{display: 'flex'}}
    >
      <div>
        <ToggleButtonGroup
          className="ml-4 mt-4 mr-4"
          value={mode}
          exclusive
          onChange={handleToggle}
          aria-label="text alignment"
        >
          <ToggleButton value="new" aria-label="left aligned">
            <AddBoxIcon/>
          </ToggleButton>
          <ToggleButton value="edit" aria-label="right aligned">
            <EditIcon/>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
{/*       // Just Below Container ///////////////////
 */}
      <div>
        <MuiThemeProvider>
          { mode=="new" ? (
                <TextField
                  hintText="Name of new Amenity"
                  floatingLabelText="New Amenity"
                  value={newAmenityName}
  // disabled the text input                onChange={e => this.setState({ query: e.target.value })}
                  onChange={e => setNewAmenityName(e.target.value)}
                  floatingLabelFixed
                />
            ):(
                <SelectField
//                  style={{ marginLeft: "1em" }}
                  floatingLabelText="Select Amenity to Edit"
                  value={editAmenity}
                  onChange={(event, index, value) =>
  /*                   setEditAmenity(value)
  */                  handleSelectAmenity(value)
                  }
                >
                { namedAmenities.map(name => ( <MenuItem value={name} primaryText={name}  /> )) }
              </SelectField>
          )}
        </MuiThemeProvider>
      </div>
{/*       // Just Below Container ///////////////////
 */}
      <div>
        <MuiThemeProvider>
          <TextField
                    hintText="Title of Description"
                    floatingLabelText="Description Title"
                    value={descTitle}
    // disabled the text input                onChange={e => this.setState({ query: e.target.value })}
                    onChange={e => setDescTitle(e.target.value)}
                    floatingLabelFixed
                  />
        </MuiThemeProvider>
      </div>
{/*       // Just Below Container ///////////////////
 */}
      <div style={styleDiv}>
        <div>
          <label class="custom-file-upload">
            <input type="file" onChange={(e) => 
/*               { reporta() }}/>     */
              { handleDrop(e.target.files) }}/>
              <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                Drop Image Files Below
          </label>
        </div>

        <div>
          <DragDropFiles handleDrop={handleDrop} filecount={fileNames.length}>
            <div style={{height: 90, width: 190, marginLeft: "20px"}}>
              {fileNames.map((file,i) => <div key={i}>{file}</div>)}
            </div>
          </DragDropFiles>
          <div style={{ display: 'flex', 
                        maxWidth: "220px",
                        minWidth: "220px"}}>
            {((mode=="new") || (mode=="edit" && editAmenity.length > 0)) &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick = { (event) => { handleClickOpen("save") } }
                  >
                  {mode=="edit" ? (<span>Update</span> ):( <span>Save</span>)}
                </Button>
              </div>
            }
            {mode=="edit" && editAmenity.length > 0 &&
              <div>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  className={classes.button}
                  startIcon={<DeleteIcon />}
                  onClick = { (event) => { handleClickOpen("delete") } }
                  >
                  Delete
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
{/*       // Just Below Container ///////////////////
 */}
      <div>
        <div className="d-flex align-items-start" 
             style={{display: 'flex', 
              maxWidth: "210px",
              minWidth: "210px"}}>
          <MuiThemeProvider>
          <SelectField
                autoWidth={true}
                floatingLabelText="Open X hours"
                value={timeDurationArray[timeDuration]}
                onChange={(event, index, value) =>
                  handleTimeDurationSelect(index, value)
                }
                >
                { timeDurationArray.map(name => ( <MenuItem value={name} primaryText={name}  /> )) }
              </SelectField>
              <SelectField
                autoWidth={true}
                floatingLabelText="From:"
                value={beginTimeArray[beginTime]}
                onChange={(event, index, value) =>
                  handleBeginTimeSelect(index, value)
                }
                >
                { beginTimeArray.map(name => ( <MenuItem value={name} primaryText={name}  /> )) }
              </SelectField>
            </MuiThemeProvider>
          </div>
          <div
            style={{display: 'flex', 
            marginTop: "2px",
            marginLeft: "34px"}}>         
            <RestrictionPeriods 
                setRestrictionArray={setRestrictionArray} 
                restrictionsPeriods={restrictionsPeriods[restrictionSpec]}/>
          </div>
      </div>
      </container>
{/*       // End of Container ///////////////////
 */}

      <div style={styleDesc}>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Description of Amenity</Form.Label>
          <Form.Control 
            placeholder = "Enter a delightful description"
            value = {desc} as="textarea" rows="4" cols="150"
            onChange = { (event) => { setDesc(event.target.value) } }/>
        </Form.Group>
      </div>


{/*       <div style={{ display: "flex", margin: "12px" }}>
        <div>
          <label class="custom-file-upload">
              <input type="file"
                onChange={(e) => {
                    handleDrop(e.target.files)
                }}/>
                <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                Drop Image Files Below
          </label>
        </div>
        <div style={{ display: "inline-flex", margin: "auto" }}>
          <button style={{ display: "inline", height: "30px", margin: "20px" }} 
                  onClick={saveAmenity}>Save Amenity</button>
        </div>
      </div>
      
      <div style={{ display: "flex", margin: "4px" }}>
        <div style={{ display: "flex", margin: "15px" }}>
          <DragDropFiles handleDrop={handleDrop}>
            <div style={{height: 70, width: 250, marginTop: "2px"}}>
              {fileNames.map((file,i) =>
                <div key={i}>{file}</div>
              )}
            </div>
          </DragDropFiles>
        </div>
 
        <div style={{ display: "inline-flex", margin: "auto"}}>  

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description of Amenity</Form.Label>
            <Form.Control as="textarea" rows="3" cols="150"
            onChange = { (event) => { setDesc(event.target.value) } }/>
          </Form.Group>
        </div>
      </div>
 */}

 <hr/>

      <ICVpage selection={selection}/>

{/*       {fileObjs.map((file,i) => 
        <>
          <img width="200px" src={URL.createObjectURL(file)}/>
          <div key={i}>FileName: {file.name}</div>
        </>
      )}
      <hr/>
          <ICVpage selection={selection}/>
 */}






    </div>


<div>
{/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
  Open form dialog
</Button>
 */}<Dialog
  open={open}
  onClose={handleClose}
  PaperComponent={PaperComponent}
  aria-labelledby="draggable-dialog-title"
>
  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
    {dialogTitle}
  </DialogTitle>
  <DialogContent>
    <DialogContentText>
      {dialogText}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button autoFocus 
      onClick = { (event) => { handleClose("cancel") } } color="primary">
      Cancel
    </Button>
    <Button 
      onClick = { (event) => { handleClose("ok") } } color="primary">
      OK
    </Button>
  </DialogActions>
</Dialog>
</div>

</>
  );
}
