//import React, { useState } from "react"
import React, { useState, useEffect } from "react"
import '../../stylesheets/Amenities.css'
import AOS from 'aos'

import { Container } from "react-bootstrap"
import Navbar from "./AmenitiesNavbar"
import { useAuth } from "../../contexts/AuthContext"

import CalendarModal from "./CalendarModal"

import bar1 from "./../../images/bar1.jpg"
import bar2 from "./../../images/bar2.jpg"
import bar3 from "./../../images/bar3.jpg"

import { database, storage } from "./../../firebase"
import firedb from "./../../firebase"

import AmenitiesBlock from './AmenitiesBlock'


import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');
console.log(bootstrap)

export default function Amenities() {
/*   const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
 */
//  const [count, setCount] = useState(0);
//  const [ flad, setFlad ] = useState(false)
  const { currentUser, logout } = useAuth()
  const [ docId, setDocId ] = useState("")

  const [ amenitiesNotSub, setAmenitiesNotSub ] = useState([])
  const [ namedAmenities, setNamedAmenities ] = useState([])
  const [ descAmenities, setDescAmenities ] = useState([])
  const [ descTitleAmenities, setDescTitleAmenities ] = useState([])

  const [ timeDurationArray, setTimeDurationArray ] = useState([])
  const [ beginTimeArray, setBeginTimeArray ] = useState([])
  const [ restrictionSpecArray, setRestrictionSpecArray ] = useState([])

//  let names = []

  useEffect(() => {
    AOS.init({
//      duration : 2000,
      easing: 'ease-in-out-sine'
    });
    AOS.refresh();

    const dbsRef = firedb.database().ref('sys_amenities')
    dbsRef.on('value', (snapshot) => {
      const dbs = snapshot.val();
      let namedList = [];
      let descTitleList = [];
      let descList = [];

      let timeDurationList = [];
      let beginTimeList = [];
      let restrictionSpecList = [];
      for (let id in dbs) {
        console.log("REPORTING NAME: ",dbs[id].name)
        console.log("REPORTING DESC: ",dbs[id].description)
        namedList.push(dbs[id].name);
        descTitleList.push(dbs[id].descriptionTitle);
        descList.push(dbs[id].description);
        timeDurationList.push(dbs[id].timeDuration);
        beginTimeList.push(dbs[id].beginTime);
        restrictionSpecList.push(dbs[id].restrictionSpec);
        }
      console.log("FINISHED ITERATION: ",namedList, descTitleList, descList)
      setNamedAmenities(namedList)
      setDescAmenities(descList)
      setDescTitleAmenities(descTitleList)
      setTimeDurationArray(timeDurationList);
      setBeginTimeArray(beginTimeList);
      setRestrictionSpecArray(restrictionSpecList);
    });
    // Get the amenity_not_sub vector - from users 
    console.log("amenitiesNotSub NEXT: ")
    if( currentUser && currentUser.email )
    {
      database.users.where("email", "==", currentUser.email).get()
      .then((querySnapshot) => {
        console.log("amenitiesNotSub ITERATION: ")
        querySnapshot.forEach((doc) => {
          const subArr = doc.data().amenitiesNotSub
          setDocId(doc.id)
          if( subArr && subArr.length > 0 )
          {
            console.log("amenitiesNotSub: ",doc.data().amenitiesNotSub)
            setAmenitiesNotSub(doc.data().amenitiesNotSub)  
          }
        })
      })
    }
  }, []);

/*   useEffect(() => {
    if( namedAmenities.length > 0 )
    {
      // For each named Amenity - create array of image(URL) arrays

    }
  }, [namedAmenities]);
 */
/*   if (typeof window.jQuery != 'undefined') {  
    // jQuery is loaded => print the version
    alert(window.jQuery.fn.jquery);
  }
  else
    alert("missing");
 */ 

  const UpdateSubscription = (name, val) => { // val = "add" or "del" subscription
    let filtered = []
    if( val == "add")
    {
      console.log("Adding 111 Subscription:", amenitiesNotSub, name, val)
      // Remove name from Array
      filtered = amenitiesNotSub.filter(function(value, index, arr){ 
        return value != name;
      });
    }
    else
    {
      console.log("Deleting 111 Subscription:", amenitiesNotSub, name, val)
      // Add name to array name from Array
      filtered = [ ...amenitiesNotSub, name]
    }
    console.log("Updated 111 Subscription:", filtered, docId)
    setAmenitiesNotSub(filtered)
    database.users.doc(docId).update({
      amenitiesNotSub: filtered
    });
  }  

  const reportdata = () => {
    console.log("SIMPLE REPORTING:")
  }  

  return (
    <>
    <div className="container" id="pagecontainer">
      <Navbar title='Amenities At Sixty01'/>

{/*     <button onClick={reportdata}>

    </button>
 */}

{/*       <label className="btn btn-outline-success btn-sm m-0 mr-2">
        <FontAwesomeIcon icon={faFileUpload} />
        <input
          type="file"
          onChange={handleUpload}
          style={{ opacity: 0, position: "absolute", left: "-9999px" }}
        />
      </label>
      
 */}      
{/*       <div className="container">
        <h1 className="title">Amenities At Sixty01</h1>
        <hr/>
      </div>

      <button type="button" className="btn btn-primary">Primary</button>
 */}
{/*       <section className="one" data-aos="fade-right" data-aos-duration="2000">
 */}      
{/* 
      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">The Gus at Sixty01</h1>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100" alt="bar"/>
          </div>
        </div>
      </section>

      <section className="one" data-aos="fade-left" data-aos-duration="2000">
        <h1>A place to relax and meet friends after a long day</h1>
        <p> aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>
      <hr/>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">The Gus at Sixty01</h1>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100" alt="bar"/>
          </div>
        </div>
      </section>

      <section class="two" data-aos="fade-left" data-aos-duration="2000">
        <h1>A place to relax and meet friends after a long day</h1>
        <p> aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>
      <hr/>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">The Gus at Sixty01</h1>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100" alt="bar"/>
          </div>
        </div>
      </section>

      <section class="three" data-aos="fade-up" data-aos-duration="2000">
        <h1>A place to relax and meet friends after a long day</h1>
        <p> aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>
      <hr/>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">The Gus at Sixty01</h1>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100" alt="bar"/>
          </div>
        </div>
      </section>

      <section className="four" data-aos="fade-down" data-aos-duration="2000">
        <h1>A place to relax and meet friends after a long day</h1>
        <p> aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>
      <hr/>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">The Gus at Sixty01</h1>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100" alt="bar"/>
          </div>
        </div>
      </section>

      <section className="one" data-aos="fade-left" data-aos-duration="2000">
        <h1>A place to relax and meet friends after a long day</h1>
        <p> aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>
      <hr/>
 */}
{/*            <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
 */}

      { namedAmenities && namedAmenities.map( (obj, index) => 
        (
          <AmenitiesBlock name={namedAmenities[index]} 
                          title={descTitleAmenities[index]} 
                          desc={descAmenities[index]}
                          timeDuration={timeDurationArray[index]}
                          beginTime={beginTimeArray[index]}
                          restrictionSpec={restrictionSpecArray[index]}
                          containerID={index}
                          amenitiesNotSub={amenitiesNotSub}
                          UpdateSubscription={UpdateSubscription}/>  
        )      
      )}
{/*           <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
          <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
          <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
          <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
          <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
          <AmenitiesBlock name={namedAmenities[0]} 
                          title={descTitleAmenities[0]} 
                          desc={descAmenities[0]}/>  
 */}
{/*       <AmenitiesBlock name={namedAmenities[0]} title={descTitleAmenities[0]} desc={descAmenities[0]}/>
 */}
{/*       <section className="images">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between">
            <h1 data-aos="fade-down" data-aos-duration="2200">{namedAmenities ? namedAmenities[0] : "LOADING"}</h1>
            <CalendarModal resourceId="bar" />
          </div>
        </Container>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100" alt="bar"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100" alt="bar"/>
          </div>
        </div>
      </section>

      <section className="one" data-aos="fade-left" data-aos-duration="2000">
        <h1>{descTitleAmenities ? descTitleAmenities[0] : "LOADING"}</h1>
        <p> {descAmenities ? descAmenities[0] : "LOADING"}</p>
      </section>
      <hr/>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">Images</h1>
        <hr/>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100"/>
          </div>
        </div>
      </section>


      <section className="one" data-aos="fade-right" data-aos-duration="2000">
        <h1>Section One</h1>
        <hr/>
        <p>Dolore reprehenderit aute officia nostrud culpa ex deserunt quis elit excepteur magna elit nisi tempor ut tempor in ad qui non cupidatat duis esse elit aute culpa deserunt nisi deserunt quis in nulla ut elit culpa sit cupidatat culpa elit enim do proident qui ex ex culpa mollit consequat consequat culpa laborum adipisicing in officia ea ut irure velit culpa id aute tempor sed amet ullamco est est occaecat id nulla esse dolor id aliquip ex exercitation ut irure dolore magna sint velit reprehenderit irure est et id sunt velit amet adipisicing reprehenderit non officia in est sint officia dolor eiusmod consequat veniam est laboris in voluptate quis velit sunt culpa dolor qui aliqua veniam esse occaecat qui dolor deserunt do reprehenderit esse ad sint qui in consectetur occaecat consectetur et amet nisi cillum ad ullamco labore ea consectetur sed duis ut dolor deserunt anim elit officia ullamco nostrud labore ut sed et dolore in ullamco adipisicing quis eiusmod aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">Images</h1>
        <hr/>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100"/>
          </div>
        </div>
      </section>

      <section className="two" data-aos="fade-left" data-aos-duration="2000">
        <h1>Section Two</h1>
        <hr/>
        <p>Dolore reprehenderit aute officia nostrud culpa ex deserunt quis elit excepteur magna elit nisi tempor ut tempor in ad qui non cupidatat duis esse elit aute culpa deserunt nisi deserunt quis in nulla ut elit culpa sit cupidatat culpa elit enim do proident qui ex ex culpa mollit consequat consequat culpa laborum adipisicing in officia ea ut irure velit culpa id aute tempor sed amet ullamco est est occaecat id nulla esse dolor id aliquip ex exercitation ut irure dolore magna sint velit reprehenderit irure est et id sunt velit amet adipisicing reprehenderit non officia in est sint officia dolor eiusmod consequat veniam est laboris in voluptate quis velit sunt culpa dolor qui aliqua veniam esse occaecat qui dolor deserunt do reprehenderit esse ad sint qui in consectetur occaecat consectetur et amet nisi cillum ad ullamco labore ea consectetur sed duis ut dolor deserunt anim elit officia ullamco nostrud labore ut sed et dolore in ullamco adipisicing quis eiusmod aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">Images</h1>
        <hr/>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100"/>
          </div>
        </div>
      </section>

      <section className="three" data-aos="fade-up" data-aos-duration="2000">
        <h1>Section Three</h1>
        <hr/>
        <p>Dolore reprehenderit aute officia nostrud culpa ex deserunt quis elit excepteur magna elit nisi tempor ut tempor in ad qui non cupidatat duis esse elit aute culpa deserunt nisi deserunt quis in nulla ut elit culpa sit cupidatat culpa elit enim do proident qui ex ex culpa mollit consequat consequat culpa laborum adipisicing in officia ea ut irure velit culpa id aute tempor sed amet ullamco est est occaecat id nulla esse dolor id aliquip ex exercitation ut irure dolore magna sint velit reprehenderit irure est et id sunt velit amet adipisicing reprehenderit non officia in est sint officia dolor eiusmod consequat veniam est laboris in voluptate quis velit sunt culpa dolor qui aliqua veniam esse occaecat qui dolor deserunt do reprehenderit esse ad sint qui in consectetur occaecat consectetur et amet nisi cillum ad ullamco labore ea consectetur sed duis ut dolor deserunt anim elit officia ullamco nostrud labore ut sed et dolore in ullamco adipisicing quis eiusmod aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">Images</h1>
        <hr/>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100"/>
          </div>
        </div>
      </section>

      <section className="four" data-aos="fade-down" data-aos-duration="2000">
        <h1>Section Four</h1>
        <hr/>
        <p>Dolore reprehenderit aute officia nostrud culpa ex deserunt quis elit excepteur magna elit nisi tempor ut tempor in ad qui non cupidatat duis esse elit aute culpa deserunt nisi deserunt quis in nulla ut elit culpa sit cupidatat culpa elit enim do proident qui ex ex culpa mollit consequat consequat culpa laborum adipisicing in officia ea ut irure velit culpa id aute tempor sed amet ullamco est est occaecat id nulla esse dolor id aliquip ex exercitation ut irure dolore magna sint velit reprehenderit irure est et id sunt velit amet adipisicing reprehenderit non officia in est sint officia dolor eiusmod consequat veniam est laboris in voluptate quis velit sunt culpa dolor qui aliqua veniam esse occaecat qui dolor deserunt do reprehenderit esse ad sint qui in consectetur occaecat consectetur et amet nisi cillum ad ullamco labore ea consectetur sed duis ut dolor deserunt anim elit officia ullamco nostrud labore ut sed et dolore in ullamco adipisicing quis eiusmod aliqua nulla ullamco adipisicing ea voluptate do cillum duis ut reprehenderit sunt commodo ex commodo laboris in et ullamco minim dolore eiusmod incididunt laboris magna ullamco dolor tempor minim in labore deserunt incididunt laborum tempor dolore cupidatat esse incididunt aute nulla magna sit veniam culpa exercitation ea sit veniam aliqua id ullamco dolore esse et duis aliquip tempor dolor nostrud non id ut minim enim.</p>
      </section>

      <section className="images">
        <h1 data-aos="fade-down" data-aos-duration="2000">Images</h1>
        <hr/>
        <div className="row">
          <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
            <img src={bar1} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
            <img src={bar2} className="w-100"/>
          </div>
          <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
            <img src={bar3} className="w-100"/>
          </div>
        </div>
      </section>
 */}

    </div>
    </>
  )
}
