import React from 'react';
import { useState, useEffect } from "react"
import { database } from "../../../firebase" 

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');
console.log(bootstrap)

export default function ManageUserRecord({record, index, usersSelectionCurrCnt, handleOnCheckUserRecord}) {
  const [ myRecord, setMyRecord] = useState(record)
  const [ myUserEnabled, setMyUserEnabled] = useState(false)
  const [ myChecked, setMyChecked] = useState(false)

  const handleCheckUserRecord = (e) => { 
    console.log("handleCheckUserRecord: ",e.target.checked)
    setMyChecked(e.target.checked)
    const action = e.target.checked ? "add" : "del"
    handleOnCheckUserRecord(myRecord, action)
  };

  // https://stackoverflow.com/questions/41866763/updating-data-in-firebase-using-react
  // https://stackoverflow.com/questions/40589397/firebase-db-how-to-update-particular-value-of-child-in-firebase-database
  const handleUserEnabled = () => { 
    console.log("useEffect - myUserEnabled: ",myUserEnabled, myRecord)
    myUserEnabled ? (  // if true -> update to false
      database.users.doc(myRecord.id).update("valid", "0")
    ) : (              // if false -> update to true
      database.users.doc(myRecord.id).update("valid", "1")
    )
    setMyUserEnabled(!myUserEnabled)
  };

  useEffect(() => {
    if( usersSelectionCurrCnt == 0 )
      setMyChecked(false)
  }, [usersSelectionCurrCnt]);

  return (
    <div id="TopTitleRow" style={{display: 'flex', marginLeft: "22px"}}>
      <div id="FirstHalf">
        <div id="TitleRow" style={{display: 'flex'}}>
          <h1>
            <input type="checkbox" checked={myChecked} onClick={(e)=>handleCheckUserRecord(e)}/>
            <label key={index} className="btn-xl m-0 mr-2"> <h4>Email: {myRecord.email}</h4></label>
          </h1>
        </div>
        <div id="BtnRow" style={{display: 'flex', marginTop: "2px"}}>
          <button 
            onClick={ () => { 
                console.log("checking enabled: ",myUserEnabled)
                handleUserEnabled()
            }}
          > { myUserEnabled === true ? <>Disable</> : <>Enable</>}
          </button>
        </div>
      </div>
    </div>
  );
}
