import React from 'react';
//import useFirestore from '../hooks/useFirestore';
import { motion } from 'framer-motion';
import "./ICVpage.css"

const ImageGrid = ({ selection, setSelectedImg }) => {
//  const { docs } = useFirestore('images');

  return (
    <div className="img-grid">
{/*       <h2> hello </h2>
 */}
{/* 
      {selection.map(sel => (
                    <div key={sel.id}>
                      <p2>{sel.value},{sel.url}</p2>
                      </div>
      ))}
 */}

      {selection && selection.map(sel => (
        <motion.div className="img-wrap" key={sel.id} 
          layout
          whileHover={{ opacity: 1 }}s
          onClick={() => setSelectedImg(sel.url)}
        >
          <motion.img src={sel.url} alt="uploaded pic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
        </motion.div>
      ))}



{/*
      {docs && docs.map(doc => (
        <motion.div className="img-wrap" key={doc.id} 
          layout
          whileHover={{ opacity: 1 }}s
          onClick={() => setSelectedImg(doc.url)}
        >
          <motion.img src={doc.url} alt="uploaded pic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
        </motion.div>
      ))}
 */}    </div>
  )
}

export default ImageGrid;