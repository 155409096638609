import React, {useState, useEffect} from 'react';
//import shave from 'shave';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useAuth } from "../../../../contexts/AuthContext"
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import MessageIcon from '@material-ui/icons/Message';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';

import firedb from "../../../../firebase" 


import { storage, database } from "../../../../firebase"


import './MyTicketListingItem.css';

const dbComponentName = "0_tickets"

export default function MyTicketListingItem(props) {
//  useEffect(() => {
//    shave('.conversation-snippet', 20);
//  })
    const [ isVisible, setIsVisible ] = useState()
    const [ toggleToolTip, setToggleToolTip ] = useState("")
    const index = props.index;
    const { currentUser } = useAuth()
    const { state, id, subject, body } = props.data.data;

    useEffect(() => {
      isVisible ? (  // if true -> update to false
        setToggleToolTip("Communication is open to resident - click to DISABLE communication and hide ticket updates")
      ) : (              // if false -> update to true
        setToggleToolTip("Communication is currently DISABLED - click to ENABLE communication and show ticket updates")
      )
    }, [isVisible])  

    useEffect(() => {
      if( props.data.data.state == "ENABLED")
        setIsVisible(true)
      else
        setIsVisible(false)
    }, [])  

    const handleSelectItem = () => {
//      console.log("handleSelectItem from Item in Lisitng: ",index, id)
      props.setTicket(index)
    }

    const ToggleShow = () => {
      console.log("ToggleShow: ",isVisible, props.data)
      const dbReference = firedb.database().ref(`${dbComponentName}`);
      isVisible ? (  // if true -> update to false
        dbReference.child(props.data.key).update({ state: "DISABLED" })
      ) : (              // if false -> update to true
        dbReference.child(props.data.key).update({ state: "ENABLED" })
      )
      setIsVisible(!isVisible)
    }
  
    return (
      <div className="d-flex conversation-info">
        { (state === "REQUEST") && currentUser.email === "gm@sixty01.com" && 
          <div>
            <Tooltip title="This item has not been assigned to a Vendor yet">
              <IconButton aria-label="show"
                  onClick={() => handleSelectItem()}>
                  <FormatColorTextIcon/> 
              </IconButton>
            </Tooltip>          
          </div> }
        { !(state === "REQUEST") && currentUser.email === "gm@sixty01.com" && 
          <div>
            <Tooltip title={toggleToolTip}>
              <IconButton aria-label="show"
                onClick={() => ToggleShow()}>
                { isVisible?
                  <MessageIcon /> : 
                  <SpeakerNotesOffIcon /> }
              </IconButton>
            </Tooltip>          
          </div> }
        <div className="conversation-list-item" onClick={() => handleSelectItem()}>
          <div className="conversation-info">
            <h1 className="conversation-title">{ subject }</h1>
            <p className="conversation-snippet">{ body }</p>
          </div>
        </div>
      </div>
    );
}
