import React, {useEffect, useState} from 'react';
import { Button, Modal, Form } from "react-bootstrap"

import './MyTicketListingSearch.css';

export default function MyTicketListingSearch({UpdateSearchTerm}) {

  const [ searchTerm, setSearchTerm] = useState("")

  const handleChange = (e) => {
    console.log("handleChange: ",e.target.value)
    UpdateSearchTerm(e.target.value)
  }

  return (
    <div className="conversation-search">


      <input
/*         value={searchTerm}
 */        onChange={(e) => {handleChange(e)}}
        type="search"
        className="conversation-search-input"
        placeholder="Search"
      />
    </div>
  );
}
