import React, {useEffect, useState} from 'react';
import Compose from './Compose/Compose';
import Toolbar from '../Toolbar/Toolbar';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import Message from './Message/Message';
import moment from 'moment';
import firedb from "../../../firebase" 
import { database } from "../../../firebase"

import { useAuth } from "../../../contexts/AuthContext"

import './MessageList.css';

const MY_USER_ID = 'apple';

export default function MessageList({showAnnouncement, user, userGUID}) {
  const [ title, setTitle ] = useState('')
  const [ messages, setMessages ] = useState([])
  const [ reply, setReply ] = useState([])
  const { currentUser } = useAuth()

  const [ loading, setLoading ] = useState(false)

  const SendReply = (reply) => {
    console.log("SENDING REPLY: ",reply,"-to ID-", showAnnouncement.docID)
    console.log("SENDING On: ",title, showAnnouncement)
    console.log("BY: ",user)
    const timeNow = Date.now()
    const obj = { From: userGUID,
                  Reply: reply, 
                  ts: timeNow }
    firedb.database().ref(`sys_email_replies/${showAnnouncement.docID}`).push(obj);
    setLoading(true)
  }

  async function processSnapshot(ss) {
    console.log("loadMessages: ")
      const dbs = ss.val();
      let resList = [];
      for (let id in dbs) {
//            console.log("GETTING MESSAGES: ",dbs[id], id)
        const userSrc = await database.users.doc(dbs[id].From).get()
        
        resList.push(
          {
            id: id,
            author: userSrc.data().email,
            url: userSrc.data().profileURL,
            message: dbs[id].Reply,
            timestamp: dbs[id].ts

          }
        )
        console.log("GETTING MESSAGE FROM: ",dbs[id], id, dbs[id].From, userSrc.data())
      }
      console.log("BUILD LISt: ",resList)
      setMessages(resList)
      setLoading(false)
  }

  useEffect(() => {
    if( loading )
    {
      firedb.database().ref(`sys_email_replies/${showAnnouncement.docID}`)
        .on('value', (snapshot) => {
          processSnapshot(snapshot)
        })
    }
    if( 0 && loading )
    {
      console.log("-XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--->RELOADING MESSAGES", showAnnouncement.docID)
      firedb.database().ref(`sys_email_replies/${showAnnouncement.docID}`)
        .on('value', (snapshot) => {
          const dbs = snapshot.val();
          let resList = [];
/*           for (let id in dbs) {
            
            resList.push(
              {
                id: id,
                author: user.email,
                url: user.profileURL,
                message: dbs[id].Reply,
                timestamp: dbs[id].ts

              }
            )
            console.log("GETTING MESSAGE FROM: ",dbs[id], id, dbs[id].From)
          }
 */          console.log("BUILD LISt: ",resList)
          setMessages(resList)
          setLoading(false)
        });  
    }
  },[loading])

  useEffect(() => {
    if( title.length > 0 )
      setLoading(true)
    else
      getMessages();
  },[title])

  useEffect(() => {
    setMessages([])
    if( showAnnouncement && showAnnouncement.subject )
      setTitle(showAnnouncement.subject);
    else
      setTitle("");
    console.log("SETTING ANNOUNCEMEMNT", messages)
  },[showAnnouncement])
  
  const getMessages = () => {
     var tempMessages = [
        {
          id: 1,
          author: 'apple',
          message: 'Welcome Message with instructions and explanation of purpose.',
          timestamp: new Date().getTime()
        },
/*         {
          id: 2,
          author: 'orange',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 3,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 4,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 5,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 6,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 7,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 8,
          author: 'orange',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 9,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 10,
          author: 'orange',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 11,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 12,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 13,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 14,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 15,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 16,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 17,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 18,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 19,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 20,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 21,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 22,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 23,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 24,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 25,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
        {
          id: 26,
          author: 'apple',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 27,
          author: 'orange',
          message: 'Hello world! This is a long message that will hopefully get wrapped by our message bubble component! We will see how well it works.',
          timestamp: new Date().getTime()
        },
        {
          id: 28,
          author: 'apple',
          message: 'It looks like it wraps exactly as it is supposed to. Lets see what a reply looks like!',
          timestamp: new Date().getTime()
        },
 */      ]
      setMessages([...messages, ...tempMessages])
  }

  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let profURL = messages[i].url;
      let previous = messages[i - 1];
      let current = messages[i];
      console.log("REPORTING Current Message", current)

      let next = messages[i + 1];
      let isMine = current.author === "gm@sixty01.com";
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.author === current.author;
        
        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          currentAuthor={current.author}
          isMine={isMine}
          url={profURL}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  }

    return(
      <div className="message-list">
        <Toolbar
          title={title}
          rightItems={[
            <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />
          ]}
        />

        <div className="message-list-container">{renderMessages()}</div>

        { title.length > 0 && <Compose SendReply={SendReply} rightItems={[
              <ToolbarButton key="emoji" icon="ion-ios-happy" />
            ]}/>
        }

{/*         <Compose rightItems={[
          <ToolbarButton key="photo" icon="ion-ios-camera" />,
          <ToolbarButton key="image" icon="ion-ios-image" />,
          <ToolbarButton key="audio" icon="ion-ios-mic" />,
          <ToolbarButton key="money" icon="ion-ios-card" />,
          <ToolbarButton key="games" icon="ion-logo-game-controller-b" />,
          <ToolbarButton key="emoji" icon="ion-ios-happy" />
        ]}/>
 */}      </div>
    );
}