import React from "react"
import Navbar from "./Navbar"
import { useState, useEffect } from "react"
import { storage, database } from "../../firebase"
import firedb from "../../firebase" 

import { useAuth } from "../../contexts/AuthContext"
import RequestIncidentReport from "./IncidentReport/RequestIncidentReport"
import RequestTicketButton from "./RequestTicketButton"
import MyTicketListing from "./MyTicketListing/MyTicketListing"
import MessageList from "./MessageList/MessageList"

//import PeopleGallery from "./PeopleGallery/PeopleGallery"
//import ConversationList from "./ConversationList/ConversationList"
//import MessageList from "./MessageList/MessageList"
import './MyTickets.css';

import { Container } from "react-bootstrap"

const dbComponentName = "0_tickets"
const dbFilesName = "tickets"

export default function MyTickets() {
  const [ dbRef, setDbRef] = useState(null)
  const [ searchTerm, setSearchTerm ] = useState("")
  //  const [ announcements, setAnnouncements ] = useState([])
  //  const [ filteredAnnouncements, setFilteredAnnouncements ] = useState([])
  const [ myTickets, setMyTickets ] = useState([])
  const [ filteredTickets, setFilteredTickets ] = useState([])

  const [ changeArray, setChangeArray ] = useState([])

  const { currentUser } = useAuth()
  const [ userAcct, setUserAcct ] = useState()
  const [ patrol, setPatrol ] = useState(false)
  const [ showTicket, setShowTicket ] = useState()
  //
//  const [ fileObjList, setFileObjList] = useState([])
//  const [ ticketCnt, setTicketCnt] = useState(10)
  const [ busy, setBusy ] = useState(false)


  const onEdit = (key) => {
    console.log("onEdit: ",key)
  }

  useEffect(() => {
    database.users.where("email", "==", currentUser.email).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setUserAcct(doc.data())
        console.log("f15: groups of user: ", doc.data().group);
        doc.data().group.forEach( ele => {
          console.log("f15: inspecting: ", ele);
          if( ele == "PATROL")
          {
            setPatrol(true)
            console.log("f15: Found Patrol: ");
          }
        })
      });
    })
    .catch((error) => {
        console.log("f15: Error getting User Doc documents: ", error);
    });
  }, [])

  const onReportNewIncident = () => {
    console.log("f15: onReportNewIncident: " )
  }

  const onRequestNewTicket = (value, desc, fileObjs) => {
//    setFileObjList(fileObjs)
    setBusy(true)
    const ts = Date.now()
    var d = new Date(ts);
    const sDate = d.toString().substr(4, 11);

    const dbReference = firedb.database().ref(`${dbRef}`);
    var newRef = dbReference.push(); // this does *not* call the server
    var newKey = ""
//    if (fileObjs.length > 0) 
      newKey = newRef.key;  // newKey is the node ID of the files location
    const newObj = {
      state: "REQUEST",
      body: desc,
      company: "HOA",
      email: currentUser.email,
      files: newKey,
      group: "HOA-REQUEST",
      name: currentUser.email,
      priority: "-1",
      ref_key: newKey,
      reply_cnt: "0",
      subject: value,
      tag: "na",
      timestamp: sDate,
      uid: currentUser.uid
    }
    const ticketID = dbReference.push(newObj).key;
    dbReference.child(ticketID).update({ ref_key: ticketID }) // a newly created request references itself
    // Store files in storage under <newKey>
    const fileCount = fileObjs.length;
    if (fileCount > 0) 
    {
      for (var i = 0; i < fileCount; i++)
      {
//        console.log("Uploading: ")
        // https://stackoverflow.com/questions/45714007/firebase-get-download-url-after-successful-image-upload-to-firebase-storage

        const storageRef = storage.ref(`${dbFilesName}/${newKey}/${fileObjs[i].name}`);
        const task = storageRef.put(fileObjs[i])

        task.on('state_changed',
          function progress (snapshot) {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//            console.log("1234567 - Uploaded progress: ", percentage)
          },
          function error () {
//            console.log("1234567 - FAILED: ")
            setBusy(false)
          },      
          function complete (event) {
//            console.log("1234567 - Uploaded and setting image url: ", storageRef)
            setBusy(false)
            if( fileCount == 1 )
              storageRef.getDownloadURL().then((url) => { 
//                console.log(url) 
                dbReference.child(ticketID).update({ files: url })
                setBusy(false)
              })
          }
        )  
      }
    }
    else
    {
      setBusy(false)
    }
    /////////////////////////
  }

  const DeleteCurrent = () => {
    console.log("DeleteCurrent: ",showTicket)
/*     // https://firebase.google.com/docs/firestore/manage-data/delete-data
    database.emails.doc(showTicket.docID).delete().then(() => {
      console.log("Document successfully deleted!");
      setTicket()
      setSearchTerm('')
//      getEmail()
      setShowAnnouncement()
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
 */  }

/*   const getMyTickets = async (msgID) => {
    try {
      let announcementArr = []
      const emails = database.emails;
      const data = await emails.get();
      if(data.empty) {
        console.log("MsgCenter -> EMPTY: ");
      }
      else {
        console.log("MsgCenter -> FOUND for : ", data, msgID );
        let data_out = "";
        data.forEach(doc => {
          if( doc.data().show || currentUser.email === "gm@sixty01.com" )
          {
            console.log("MsgCenter -> FOREACH: ", doc.id, doc.data().id);
            announcementArr.push({docID: doc.id, ...doc.data()})
            if( doc.data().id == msgID )
            {
              console.log("MsgCenter -> FOUND for : ", doc.data(), msgID );
              setFirst(doc.data())
              setShowAnnouncement(doc.data())
            }  
          }
        })
      }
      console.log("MsgCenter -> set: ", announcementArr);
      setAnnouncements(announcementArr)
      setFilteredAnnouncements(announcementArr)
    } catch (error) {
      console.log("MsgCenter -> ERROR: ");
    }
  }
 */

  function LoadTickets(){
    console.log("LoadTickets(){ ");
    const dbReference = firedb.database().ref(`${dbComponentName}`);
    let myTicketArr = []
    if( currentUser.email.indexOf("gm@sixty01.com") !== -1 )
    {
      dbReference.orderByChild('email')
      .once('value')
      .then(function (snapshot) {
        var value = snapshot.val();
        if (value) {
          let myTicketArr = []
          snapshot.forEach(function (childSnapshot) {

/*             childSnapshot.forEach((child) => {
              console.log("SUCCESS: ",child.key, child.val()); 
            })
 */            var childKey = childSnapshot.key;
//            console.log("MyTicket - childKey pulled", childKey)
            var childData = childSnapshot.val();
//            console.log("MyTicket - childData Shows", childData)

            if( childData.state == "REQUEST" || childData.state == "ENABLED" || childData.state == "DISABLED")
              myTicketArr.push({ key: childKey,
                                data: childData })
            // ...
          });
          setMyTickets(myTicketArr)        
          setFilteredTickets(myTicketArr)
        } else {
          console.log("MyTicket - Value pulled - BROKEN")
        }
      });        
    }
    else
    {
      dbReference.orderByChild('email')
      .equalTo(currentUser.email)
      .once('value')
      .then(function (snapshot) {
        var value = snapshot.val();
        if (value) {
          let myTicketArr = []
          snapshot.forEach(function (childSnapshot) {

/*             childSnapshot.forEach((child) => {
              console.log("SUCCESS: ",child.key, child.val()); 
            })
 */            var childKey = childSnapshot.key;
//            console.log("MyTicket - childKey pulled", childKey)
            var childData = childSnapshot.val();
//            console.log("MyTicket - childData Shows", childData)

            myTicketArr.push({ key: childKey,
                               data: childData })
            // myTicketArr.push(childData)
            // ...
          });
          setMyTickets(myTicketArr)        
          setFilteredTickets(myTicketArr)
        } else {
          console.log("MyTicket - Value pulled - BROKEN")
        }
      });        
    }
  }

  useEffect(() => {
    window.history.pushState("mytickets", "Title", "/mytickets");
    setDbRef(`${dbComponentName}`)
    const dbReference = firedb.database().ref(`${dbComponentName}`);
    // Let's get the users tickets from realtime database
    let myTicketArr = []
    // https://stackoverflow.com/questions/39176070/firebase-orderbychild-equalto-once-then-promises-when-child-doesnt-exi

    dbReference.on('value', items =>{
      console.log("MyTickets,js: dbReference.on child changes", showTicket);
      LoadTickets()
      var tmpArr = []
      items.forEach((child) => {
        const state = child.val().state;
        const ref_key = child.val().ref_key;
        if( state == "REQUEST" || state == "ENABLED" || state == "DISABLED" )
        {
          console.log("HandleChangchild.val())  ",child.key, child.val(), ref_key);
          tmpArr.push(child.val())  
        }
      })
      setChangeArray(tmpArr)
    })

    LoadTickets()
    database.users.where("email", "==", currentUser.email).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setUserAcct(doc.data())
        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }, [])

  const [ stateOfView, setStateOfView ] = useState()
  const [ refOfView, setRefOfView ] = useState()

  const setTicket = (key) => {
    if( key >= 0)
    {
      setShowTicket(filteredTickets[key].data)
      console.log("TICKET IS BEING SET: ", filteredTickets[key].data, filteredTickets[key].data.state)
      setStateOfView(filteredTickets[key].data.state)
      setRefOfView(filteredTickets[key].data.ref_key)
    }
    else
    {
      console.log("RESETTING SHOW TICKET: ")
      setShowTicket()
    }
    setSearchTerm('')
  }

  useEffect(() => {
    let tgtArr = myTickets.filter(tic => 
    {
      return(
        ( tic.data.subject.toLowerCase().indexOf(searchTerm) !== -1 ||
          tic.data.body.toLowerCase().indexOf(searchTerm) !== -1 )
      )
    })
    setFilteredTickets(tgtArr)    
  }, [searchTerm])

  useEffect(() => {
    console.log("changeArray reporting state of view  : ", stateOfView)
    if( changeArray.length > 0 )
      console.log("Iterating ChangeArray  : ")
      changeArray.forEach((item) => {
        if( item.ref_key == refOfView && item.state != stateOfView) {
          console.log("changeArray FOUNDIT  : ", item.ref_key, item.state)
          setShowTicket()
        }
      })
  }, [changeArray])

  useEffect(() => {
    console.log("MyTicket.js: showTicket is:", showTicket)
  }, [showTicket])

  return (
    <div id="msg-center">
      { patrol? (
          <>
            <Navbar title='Incident Tickets' />
            <Container fluid>
              <div className="d-flex align-items-center">
                <h2>Follow Incident Reports</h2> 
                  <RequestIncidentReport onReportNewIncident={onReportNewIncident}/>
                <hr/>
              </div>
              <div id="messenger-panel" className="messenger-panel-show">
                <div className="scrollable sidebar">
                  <MyTicketListing
                                    tickets={filteredTickets} 
                                    currTicket={showTicket}
                                    setTicket={setTicket} 
                                    setSearchTerm={setSearchTerm}
      //                              deleteCurrent={DeleteCurrent}
                                    />
                </div>
                <div className="scrollable content">
                  <MessageList dbRef={dbRef} showItem={showTicket} user={userAcct}/>
                </div>
              </div>
            </Container>
          </>
          ):(
          <>
            <Navbar title='My Ticket/Work Orders' />
            <Container fluid>
            <div className="d-flex align-items-center">
                <h2>A place to follow your Tickets/Work Orders</h2> 
                <RequestTicketButton onRequestNewTicket={onRequestNewTicket}/>
                <hr/>
    {/*             <PeopleGallery/>
    */}        </div>
    
            <div id="messenger-panel" className="messenger-panel-show">
              <div className="scrollable sidebar">
                <MyTicketListing
                                  tickets={filteredTickets} 
                                  currTicket={showTicket}
                                  setTicket={setTicket} 
                                  setSearchTerm={setSearchTerm}
    //                              deleteCurrent={DeleteCurrent}
                                  />
              </div>
              <div className="scrollable content">
                <MessageList dbRef={dbRef} showItem={showTicket} user={userAcct}/>
              </div>
            </div>              
          </Container>
          </>  
        ) 
      }
    </div>
  )
}
