import React from 'react';
import { useState, useEffect } from "react"

import { Button, Modal, Form } from "react-bootstrap"
import { storage, database } from "../../../firebase"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons"

import firedb from "../../../firebase" 
import AddEmailButton from "./AddEmailButton"

import { useAuth } from "../../../contexts/AuthContext"

import './Toolbar.css';

export default function Toolbar(props) {
    const { title, leftItems, rightItems, ClearConversation } = props;
    const { currentUser } = useAuth()
    const [ panel, setPanel ] = useState(true)

    const [ users, setUsers ] = useState([])

    const handlePanelToggle = () => {
      console.log("handlePanelToggle: ",panel)
      if(panel)
      {
        document.getElementById("messenger-panel").classList.add('messenger-panel-hide');
        document.getElementById("messenger-panel").classList.remove('messenger-panel-show');
      }
      else{
        document.getElementById("messenger-panel").classList.add('messenger-panel-show');
        document.getElementById("messenger-panel").classList.remove('messenger-panel-hide');        
      }
      setPanel(!panel)
    }

    const handleChange = (e) => {
      console.log("handleChange: ",e.target.value)
    }
  
    const onAddNewEmail = async (subject, body, sign, attachments, action, tgtGrp) => {
      ClearConversation(-1)
      // Action is either POST or SEND - POST to store the announcement and SEND to mass email
      const testemails = ["jari@havenrealestateservices.com","mortensenviggo0@gmail.com","hres.jkwebapps@gmail.com"]
//      const testemails = ["jari@havenrealestateservices.com"]
      let tgtemails = testemails;
      let injectFlag = false;
      let injectALL = false;
      if( tgtGrp.includes("ALL") )
        injectALL = true

      let tmpArr = ["mortensenviggo0@gmail.com"]; // DEFAULT one plus GM and one more - 3 min
      console.log("f6: onAddNewEmail FINDING ALL USERS ")
      const userRef = database.users;
      const data = await userRef.get();
      if(data.empty) {
        console.log("f6: No users present - ERROR: ");
        tgtemails = [] 
      }
      else {
        console.log("f6: handling array: ", tgtGrp);
        data.forEach(doc => {  // for each record containing a good email
          if( doc.data().email && doc.data().email.includes('@') )
          { // Processing god email
            console.log("f6: Iteration on good email: ", doc.data().email );
            // Process to set injectFlag 
            if( injectALL )
              injectFlag = true
            else
            { // Process flag on a per-user basis
              tgtGrp.forEach(grp => {
                if( doc.data().group && doc.data().group.length > 0 && doc.data().group.includes(grp) )
                {
                  console.log("f6: --> Included on grp: ", grp );
                  injectFlag = true
                }
                // If grp identifier is included in the users noSub Amenities - negate the flag - policy here
                if( doc.data().amenitiesNotSub && doc.data().amenitiesNotSub.length > 0 && doc.data().amenitiesNotSub.includes(grp) )
                {
                  console.log("f6: --> Excluded on amenity: ", grp );
                  injectFlag = false
                }
              })  
            }
          }
          if( injectFlag ) // Either filtering was possitive or the injectALL is set
          {
            tmpArr.push(doc.data().email)
          }
          injectFlag = false
        })
        console.log("f6 - 1: :RESULT ARR: ", tmpArr );
        if( tmpArr.length < 3 )
          tmpArr.push("jari@havenrealestateservices.com")
        console.log("f6 - 2: :RESULT ARR: ", tmpArr );
        tgtemails = tmpArr  
      }
      console.log("onAddNewEmail TOP - args: ",subject, body, sign, attachments, action)
      // ONCE we have option to select groups to add to email
/*       console.log("onAddNewEmail TOP - values: ",selected, column, attachments)
      let emails = []
      selected.map((row, index) => { 
        const obj = dataArray[row]
        console.log("onAddNewEmail TOP - Iteration: ",row, index)
        Object.keys(obj).map(function(keyName, keyIndex) {
          if( keyName == column )  emails.push(obj[keyName])
        })
      })
 */      console.log("onAddNewEmail TOP - emails: ", tgtemails)
      ///////////////////////////
      const ts = Date.now()
      const emailRef = firedb.database().ref(`sys_emails_${action}`);
      var attachmentsPresent = 0
      var newRef = emailRef.push(); // this does *not* call the server
      var newKey = ""
      newKey = newRef.key;  // Perhps only key as a property
      if (attachments.length > 0) 
        attachmentsPresent = attachments.length
      const newObj = {
        id: newKey,
        attachments: attachmentsPresent,
        subject: subject,
        body: body,
        sign: sign,
        type: "ANNOUNCE",
        state: action, 
        to: tgtemails,
        ts: ts
      }
      if (attachments.length > 0) 
      { // Explain thi shack
        // https://stackoverflow.com/questions/64142108/react-js-image-uploder-code-with-firebase-cloud-storage-gives-error
        // with an attachment we first up;oad and then ge the URL and then set the email entry and then call the server
        let urlArray = []
        let nameArray = []
        const attachmentFirst = attachments[0]
        const attachmentNameFirst = attachments[0].name
        console.log("Uploading attachments - starting array: ",attachments, action)
        for (var i = 0; i < attachments.length; i++)
        {
          let urlTmp = ''
          console.log("Uploading: ", attachments[i].name)
          await storage
            .ref(`sys_attachments/${newKey}/${attachments[i].name}`)
            .put(attachments[i])
          urlTmp = await storage.ref(`sys_attachments/${newKey}`)
                                  .child(`${attachments[i].name}`)
                                  .getDownloadURL()
  
          console.log("Adding to arrays: ", urlTmp, attachments[i].name)
          urlArray.push(urlTmp)
          nameArray.push(attachments[i].name)
        }
        const newObj = {
          id: newKey,
          attachments: attachmentsPresent,
          attachName: nameArray,
          attachURL: urlArray,
          subject: subject,
          body: body,
          sign: sign,
          type: "ANNOUNCE",
          state: action, 
          to: tgtemails, // NEEDS to LIST all Users
          ts: ts
        }                              
        if( action == "POST" )
          database.emails.add(newObj)
        else
        {
          database.emails.add(newObj).then(() => {

          const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
//          const apiUrl = `http://localhost:452/slabs/send/${newKey}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => console.log('This is your data', data));
          })
        }
      }
      else
      { // just push the new email and .then PING the server
        if( action == "POST" )
          database.emails.add(newObj)
        else
        {
          database.emails.add(newObj).then(() => {

          const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
//          const apiUrl = `http://localhost:452/slabs/send/${newKey}`;

              fetch(apiUrl)
                .then((response) => response.json())
                .then((data) => console.log('This is your data', data));
            })
        }
      } 
    }
  
    return (
    <div className="d-flex">
      { !panel && leftItems &&
        <Button title="Press to expand" 
            onClick={handlePanelToggle} variant="light" size="sm" className="btn btn-sm mb-1 ml-1">
              <FontAwesomeIcon icon={faArrowsAltH} className="mr-2" />
        </Button> }

      { leftItems && 
        <Button title="Collaps and expand left panel" 
            onClick={handlePanelToggle} variant="light" size="sm" className="btn btn-sm mb-1 ml-1">
              <FontAwesomeIcon icon={faArrowsAltH} className="mr-2" />
        </Button> }

      <h1 className="toolbar-title">{ title }</h1>

      { panel && leftItems && currentUser.email === "gm@sixty01.com" &&
        <AddEmailButton 
            onAddNewEmail={onAddNewEmail}/>
      }

    </div>
  );
}