import React from 'react';
import { useState, useEffect } from "react"
import ReplyTicketButton from "./ReplyTicketButton"
import EditViewTicketButton from "./EditViewTicketButton"
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

import { faEllipsisH, faThinkPeaks, faExpand, faFile, faLock, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { storage } from "../../firebase"
import firedb from "../../firebase" 
import { v4 as uuidV4 } from "uuid"
import './Ticket.css'; 

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');
console.log(bootstrap)

const componentName = "tickets"

/* 
var image_clicked;
$(document).ready(function() {
  image_clicked = function () {

 */

export default function Ticket({setPreviewURL, currentUser, parentChecked, dbRef, onEdit, onDelete, onReply, ticket, ticketCnt, users, handleOnTicketSelection, index}) {
  const [ error, setError] = useState([])

  const [ myChecked, setMyChecked] = useState(false)
  const [ myTicket, setMyTicket] = useState(ticket)
  const [ myTicketCnt, setMyTicketCnt] = useState(0)
  const [ imgBucketId, setImgBucketId] = useState(0)
  const [ imgBox, setImgBox] = useState(index+"-")
  const [ key, setKey] = useState(index)
  const [ subject, setSubject] = useState([])
  const [ expandChecked, setExpandChecked] = useState(false)
  const [ ticketArray, setTicketArray] = useState([])
  const [ dbReference, setDbReference] = useState()

  var lis = []; //  var lis = [<FontAwesomeIcon icon={faLock} className="mr-3" />];

  useEffect(() => {
    if( parentChecked != myChecked )
    {
      console.log("ParentChecked changed: ")
      setMyChecked(parentChecked)   
    } 
    setMyTicketCnt(ticketCnt)
  }, [parentChecked, ticketCnt]);

  const image_clicked = () => {
    console.log("image_clicked: ")
//    setPreviewURL(url)
  };
 
  const handleClickDelete = () => {
    const db = `${dbReference}`
    console.log("handleClickDelete: ",db)
    onDelete(db)
/*     const ticketRef = firedb.database().ref(`sys_${componentName}/${key}`);
 */
    const ticketRef = firedb.database().ref(db);
    ticketRef.remove();
  };

  const onReplyTicket = (reply) => {
    const db = `${dbReference}`  // PATH VERIFIED
    const ticketRef = firedb.database().ref(db);
    const new_key = ticketRef.push().key;
    const ts = Date.now()
    var d = new Date(ts);
    const sDate = d.toString().substr(4, 11);
    console.log("Ticket.js -> onReplyTicket (db, by, key): ",db, currentUser.email,new_key)
    const replyObj = {
      body: reply,
      company: "HOA",
      email: currentUser.email,
      files: new_key,
      group: "HOA",
      name: currentUser.email,
      priority: "na",
      ref_key: new_key,
      reply_cnt: "0",
      subject: subject,
      tag: "na",
      timestamp: sDate,
      uid: "currentUser.uid" //currentUser.uid
    }
    ticketRef.push(replyObj);
  }

  const onExpand = () => {
//    console.log("onExpand: ")
    lis.push(<FontAwesomeIcon icon={faLock} className="mr-3" />);
    console.log("ON EXPAND: ", lis )
    if( !expandChecked )
    {
//        alert("Expanding") // TESTED
        const cnt = dbRef.split("/").length;
        console.log("onExpand: ",cnt, ticketArray)
    }
    setExpandChecked(!expandChecked)
  };
  const handleCheckTicket = (e) => { 
    console.log("handleCheckTicket: ",dbReference, e.target.checked)
    setMyChecked(e.target.checked)
    const action = e.target.checked ? "add" : "del"
    handleOnTicketSelection(dbReference, action)
  };
  const completeTicket = () => { 
    console.log("completeTicket: ")
  };

  useEffect(() => {
    setKey(ticket.key)
    setSubject(ticket.obj.subject)
    setDbReference(`${dbRef}/${ticket.key}/`)
    const ticketRef = firedb.database().ref(`${dbRef}/${ticket.key}/`);
    console.log("Ticket INITIALIZING22-1: ", ticket, `${dbRef}/${ticket.key}/` )
    ticketRef.on('value', (snapshot) => {
        const dbs = snapshot.val();
        let ticketList = [];
        for (let id in dbs) {
            console.log("INITIALIZING-ITERATION22-2: ",id,"::",dbs[id])
            if( dbs[id].by )
                ticketList.push({ key: id, obj: dbs[id]})
        }
        console.log("INITIALIZING-REPORT22-3: ",ticketList)
        setTicketArray(ticketList)
    })  
    const cnt = dbRef.split("/").length;
    console.log("Ticket INIiiiiiiiiiiiiiiiiiiiTIALIZING - LOADED22-4566788: ", cnt, ticketArray )
    console.log("Ticket INIiiiiiii222222iiiiiiiiiiiiTIALIZING - LOADED22-4566788: ", ticket, imgBucketId)
    //////////////////////
    if( ticket.obj.id && ticket.obj.id.length > 0 )
    {
      setImgBucketId(ticket.obj.id)
      const imgRef = storage.ref(`sys_tickets`)
      const imgList = imgRef.child(`${ticket.obj.id}`)
      if( !(imgBucketId > 0) )
      {
        $(`#${imgBox}`).find('container').empty()
        imgList.listAll().then((res) => {
          res.items.forEach((itemRef, indexInBucket) => {
            itemRef.getDownloadURL().then(url => {

              setImgBucketId(ticket.obj.id)
              console.log("RRiiiiiiiiiiiietrieved Image NAME: ",imgBox, url, indexInBucket)
              
  /*             https://stackoverflow.com/questions/3678378/is-there-a-way-to-specify-a-max-height-or-width-for-an-image/3678616
              max-height:500px;
              max-width:500px;
              height:auto;
              width:auto;
  */
              let arr_html = ''
              arr_html = '<div id="bs'+imgBox+indexInBucket+'" className="col-md-4">'
  //            arr_html += '<img src="'+url+'" width="80" height="80" onClick={() => image_clicked('+url+')} alt="bar"/>'
              arr_html += '<img src="'+url+'" width="auto" height="80" alt="bar"/>'
              arr_html += '</div>'
              $(`#${imgBox}`).find('container').append(arr_html)
  //            $(`#6b33fce8-1745-f8de-4ad8-4ee42585oprf`).find('container').append(arr_html)
              console.log("INJECTING: ", arr_html)

              let menuImg = (e) => {
  ////              https://stackoverflow.com/questions/31272118/addeventlistener-to-an-element-with-the-element-itself-as-parameter
                console.log("Got HITITTIT: ",e.target.src)
                setPreviewURL(e.target.src)
              }
              document.getElementById(`bs${imgBox}${indexInBucket}`).addEventListener('click', menuImg );

  //            $(`#pic${index}`).find('container').append(arr_html)

            })
          })
        })
      }
    }
  }, [ticketCnt]);
/* }, [ticket, myTicketCnt, expandChecked]);
 */
  return (
    <div onclick={(e)=> handleOnTicketSelection(e, "1234", false)}>

    <hr/>

    <div id="TopTitleRow" style={{display: 'flex'}}>

      <div id="FirstHalf">

        <div id="TitleRow" style={{display: 'flex'}}>
          <h1>
            { dbRef.split("/").map((mgin) => <FontAwesomeIcon icon={faEllipsisH} className="mr-3" />) }
            <input type="checkbox" checked={myChecked} onClick={(e)=>handleCheckTicket(e)}/>
            <label className="btn-xl m-0 mr-2">
{/*               <FontAwesomeIcon icon={faEllipsisH} /> 
 */}              Title: </label>   {subject}
          </h1>
        </div>
        <div id="BtnRow" style={{display: 'flex', marginTop: "5px"}}>
          <ReplyTicketButton onReplyTicket={onReplyTicket}/>

{/*           <button 
            onClick={ () => { if (key) {
                setError(false);
                onEdit(key);
                } else { setError(true) }
            }}
          >Edit</button>
 */}      <EditViewTicketButton onReplyTicket={onReplyTicket}/>

{/*           <button class="MuiButtonBase-root MuiIconButton-root" 
            tabindex="0" type="button" aria-label="edit" 
            title="Edit">
            <span class="MuiIconButton-label">
              <svg class="MuiSvgIcon-root" 
              focusable="false" viewBox="0 0 24 24" 
              aria-hidden="true">
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z">
                  </path>
              </svg>
            </span>
            <span class="MuiTouchRipple-root"></span>
          </button>
 */}
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
              onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>


{/*           <button 
            onClick={handleClickDelete}
            ><faTrashAlt/>rereerere</button>
          <button onClick={completeTicket}>Complete</button>
 */}
          { ticketArray.length > 0 ? 
            ( <button 
                onClick={ () => { if (key) {
                    setError(false);
                    onExpand(key);
                    } else { setError(true) }
                }}
              >
              { expandChecked ? <>Hide Answers</> : <>Show Answers</>}</button>
            ) : ( " " ) 
          }
        </div>
      </div>

      <div id={imgBox} className="row">
         <container style={{display: 'flex', marginLeft: "80px"}}>
         </container>
      </div>

    </div>

{/*         { expandChecked ? <label>Collaps</label> : <label>Expand</label> }
 */} 

    <hr/>
      { (expandChecked && ticketArray)
            ? ticketArray.map((ticket, index) => 
                <Ticket setPreviewURL={setPreviewURL}
                        parentChecked={myChecked}
                        dbRef={`${dbRef}/${myTicket.key}`} 
                        onEdit={onEdit} 
                        onDelete={onDelete} 
                        onReply={onReply} 
                        ticket={ticket}
                        ticketCnt={ticketCnt}
                        users={users}
                        handleOnTicketSelection={handleOnTicketSelection}
                        index={`${imgBox}${index}`} />)
        : ''}




    </div>
  );
}
