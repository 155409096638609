import React from "react"
import { useState, useEffect } from "react"
// https://www.youtube.com/watch?v=htw4iKMYzEc - lock pages
// https://www.youtube.com/watch?v=IOfoHJC4hwk - scroll react nav bar
// https://www.youtube.com/watch?v=rH9jM-8hAD8 - Cards

import "./NavbarSticky/Navbarsticky.css";
import Navbar from "./NavbarSticky/Navbarsticky"
import Cards from "./Comps/Cards"

import "./style.css"

export default function GeneralInfo() {

  const [ checked, setChecked ] = useState(true)
  return (
    <>
{/*       <Navbar />
 */} 
      <div className="gi-container" id="gid-container">
        <Navbar />
        <div className="gi-section-content pzero" id="gid-content">
            <h1>First Page</h1>
        </div>
        <div className="gi-section-content pone">
          <Cards/>        
        </div>
        <div className="gi-section-content ptwo">
            <h1>Second Page</h1>
        </div>
        <div className="gi-section-content pthree">
            <h1>Third Page</h1>
        </div>
        <div className="gi-section-content pfour" id="pg-four">
            <h1>Forth Page</h1>
        </div>
      </div>
{/*       <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero
        amet nam expedita laboriosam quos odio doloremque, dolor accusantium
        eaque, suscipit natus culpa commodi alias deserunt quod, distinctio
        deleniti nulla delectus officia velit dolore ea voluptas aperiam!
        Consequuntur, dolores eveniet rerum perferendis nemo ipsum quod deserunt
        voluptas. Numquam quaerat libero quos magnam repellat aperiam sit
        blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
        accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem
        ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium,
        dolor recusandae maiores quae necessitatibus, pariatur veniam. Harum
        doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat
        error vel eos laborum facere, quasi iste esse hic suscipit quidem
        voluptates temporibus. In quos veritatis consequatur, fugiat dolorum
        velit nam corporis accusantium sunt assumenda magni quo cupiditate aut
        aperiam placeat distinctio! Voluptates earum porro quidem accusamus
        veritatis dignissimos iusto fugit in ad minima atque temporibus officia,
        eligendi expedita, pariatur laborum maiores quisquam! Voluptatum, quia
        nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga
        vero quam voluptas totam inventore aliquam! Eaque necessitatibus tempora
        corrupti distinctio voluptas illo quas eveniet tempore, explicabo
        ratione cum! Illum qui facilis provident autem ipsum, assumenda sint
        exercitationem dicta delectus suscipit.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero
        amet nam expedita laboriosam quos odio doloremque, dolor accusantium
        eaque, suscipit natus culpa commodi alias deserunt quod, distinctio
        deleniti nulla delectus officia velit dolore ea voluptas aperiam!
        Consequuntur, dolores eveniet rerum perferendis nemo ipsum quod deserunt
        voluptas. Numquam quaerat libero quos magnam repellat aperiam sit
        blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
        accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem
        ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium,
        dolor recusandae maiores quae necessitatibus, pariatur veniam. Harum
        doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat
        error vel eos laborum facere, quasi iste esse hic suscipit quidem
        voluptates temporibus. In quos veritatis consequatur, fugiat dolorum
        velit nam corporis accusantium sunt assumenda magni quo cupiditate aut
        aperiam placeat distinctio! Voluptates earum porro quidem accusamus
        veritatis dignissimos iusto fugit in ad minima atque temporibus officia,
        eligendi expedita, pariatur laborum maiores quisquam! Voluptatum, quia
        nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga
        vero quam voluptas totam inventore aliquam! Eaque necessitatibus tempora
        corrupti distinctio voluptas illo quas eveniet tempore, explicabo
        ratione cum! Illum qui facilis provident autem ipsum, assumenda sint
        exercitationem dicta delectus suscipit.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero
        amet nam expedita laboriosam quos odio doloremque, dolor accusantium
        eaque, suscipit natus culpa commodi alias deserunt quod, distinctio
        deleniti nulla delectus officia velit dolore ea voluptas aperiam!
        Consequuntur, dolores eveniet rerum perferendis nemo ipsum quod deserunt
        voluptas. Numquam quaerat libero quos magnam repellat aperiam sit
        blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
        accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem
        ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium,
        dolor recusandae maiores quae necessitatibus, pariatur veniam. Harum
        doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat
        error vel eos laborum facere, quasi iste esse hic suscipit quidem
        voluptates temporibus. In quos veritatis consequatur, fugiat dolorum
        velit nam corporis accusantium sunt assumenda magni quo cupiditate aut
        aperiam placeat distinctio! Voluptates earum porro quidem accusamus
        veritatis dignissimos iusto fugit in ad minima atque temporibus officia,
        eligendi expedita, pariatur laborum maiores quisquam! Voluptatum, quia
        nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga
        vero quam voluptas totam inventore aliquam! Eaque necessitatibus tempora
        corrupti distinctio voluptas illo quas eveniet tempore, explicabo
        ratione cum! Illum qui facilis provident autem ipsum, assumenda sint
        exercitationem dicta delectus suscipit.
      </p>
 */} 
    </>
  )
}



{/* <Navbar />
<div class="container">
  <section class="one">
    <div class="section-content">
      <h1>First Page</h1>
    </div>
  </section>
  <section class="two">
    <div class="section-content">
      <h1>Second Page</h1>
    </div>
  </section>
  <section class="three">
    <div class="section-content">
      <h1>Third Page</h1>       
    </div>
  </section>
  <section class="four">
    <div class="section-content">
      <h1>4th Page</h1>       
    </div>
  </section>
</div>
 */}



{/*       <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero
        amet nam expedita laboriosam quos odio doloremque, dolor accusantium
        eaque, suscipit natus culpa commodi alias deserunt quod, distinctio
        deleniti nulla delectus officia velit dolore ea voluptas aperiam!
        Consequuntur, dolores eveniet rerum perferendis nemo ipsum quod deserunt
        voluptas. Numquam quaerat libero quos magnam repellat aperiam sit
        blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
        accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem
        ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium,
        dolor recusandae maiores quae necessitatibus, pariatur veniam. Harum
        doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat
        error vel eos laborum facere, quasi iste esse hic suscipit quidem
        voluptates temporibus. In quos veritatis consequatur, fugiat dolorum
        velit nam corporis accusantium sunt assumenda magni quo cupiditate aut
        aperiam placeat distinctio! Voluptates earum porro quidem accusamus
        veritatis dignissimos iusto fugit in ad minima atque temporibus officia,
        eligendi expedita, pariatur laborum maiores quisquam! Voluptatum, quia
        nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga
        vero quam voluptas totam inventore aliquam! Eaque necessitatibus tempora
        corrupti distinctio voluptas illo quas eveniet tempore, explicabo
        ratione cum! Illum qui facilis provident autem ipsum, assumenda sint
        exercitationem dicta delectus suscipit.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero
        amet nam expedita laboriosam quos odio doloremque, dolor accusantium
        eaque, suscipit natus culpa commodi alias deserunt quod, distinctio
        deleniti nulla delectus officia velit dolore ea voluptas aperiam!
        Consequuntur, dolores eveniet rerum perferendis nemo ipsum quod deserunt
        voluptas. Numquam quaerat libero quos magnam repellat aperiam sit
        blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
        accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem
        ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium,
        dolor recusandae maiores quae necessitatibus, pariatur veniam. Harum
        doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat
        error vel eos laborum facere, quasi iste esse hic suscipit quidem
        voluptates temporibus. In quos veritatis consequatur, fugiat dolorum
        velit nam corporis accusantium sunt assumenda magni quo cupiditate aut
        aperiam placeat distinctio! Voluptates earum porro quidem accusamus
        veritatis dignissimos iusto fugit in ad minima atque temporibus officia,
        eligendi expedita, pariatur laborum maiores quisquam! Voluptatum, quia
        nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga
        vero quam voluptas totam inventore aliquam! Eaque necessitatibus tempora
        corrupti distinctio voluptas illo quas eveniet tempore, explicabo
        ratione cum! Illum qui facilis provident autem ipsum, assumenda sint
        exercitationem dicta delectus suscipit.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero
        amet nam expedita laboriosam quos odio doloremque, dolor accusantium
        eaque, suscipit natus culpa commodi alias deserunt quod, distinctio
        deleniti nulla delectus officia velit dolore ea voluptas aperiam!
        Consequuntur, dolores eveniet rerum perferendis nemo ipsum quod deserunt
        voluptas. Numquam quaerat libero quos magnam repellat aperiam sit
        blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
        accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem
        ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium,
        dolor recusandae maiores quae necessitatibus, pariatur veniam. Harum
        doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat
        error vel eos laborum facere, quasi iste esse hic suscipit quidem
        voluptates temporibus. In quos veritatis consequatur, fugiat dolorum
        velit nam corporis accusantium sunt assumenda magni quo cupiditate aut
        aperiam placeat distinctio! Voluptates earum porro quidem accusamus
        veritatis dignissimos iusto fugit in ad minima atque temporibus officia,
        eligendi expedita, pariatur laborum maiores quisquam! Voluptatum, quia
        nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga
        vero quam voluptas totam inventore aliquam! Eaque necessitatibus tempora
        corrupti distinctio voluptas illo quas eveniet tempore, explicabo
        ratione cum! Illum qui facilis provident autem ipsum, assumenda sint
        exercitationem dicta delectus suscipit.
      </p>
 */} 
