import React from 'react';
import {useState, useEffect} from 'react'
import firedb from "../../firebase" 
import { storage, database } from "../../firebase"

import AddEmailButton from "./Modals/AddEmailButton"

import PropTypes from 'prop-types';
import { Form } from "react-bootstrap"

import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import StorageIcon from '@material-ui/icons/Storage';
import SecurityIcon from '@material-ui/icons/Security';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
//
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
//import { SelectField, MenuItem, TextField } from '@material-ui/core';
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";


const headerArg = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
  { id: 'calories', numeric: true, disablePadding: false, label: 'HdrArgCalories' },
  { id: 'fat', numeric: true, disablePadding: false, label: 'DataArgFat (g)' },
  { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
//  { id: 'carbs', numeric: true, disablePadding: false, label: 'jk=--Carbs (g)' },
  { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const dataArg = [
  createData('Csupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('HdrArgDataArgrozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Hooneycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
  { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
  { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
  { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
  { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headers } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
//  const { numSelected } = props;
  const [selectedFromCloud, setSelectedFromCloud] = useState("")

  const { editingDatasetName, 
          datasetName, setDatasetName, 
          datasetsAvailableFromCloud, setDatasetSelectedFromCloud,
          onAddNewEmail, hdrArray,
          selection, numSelected, setToolAction } = props;

  const handleAction = (action) => {
    setToolAction(action)
    console.log("TESTING-handleModalSubmit: ",numSelected,action,datasetName)
  }

  const onSetDatasetSelectedFromCloud = (value) => {
    console.log("1 onSetDatasetSelectedFromCloud: ",value)
    setDatasetSelectedFromCloud(value)
    setSelectedFromCloud(value)
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      { (datasetName == 'No Name') ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          Fetch data either by importing from Excel File or choose from database ( dropdown to the right )
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          { editingDatasetName ? 
            (
              <>
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                <Tooltip title="Editing being done">
                  <IconButton aria-label="edit">
                    <Form.Control size="md" type="text" placeholder="No name is loaded" value={datasetName}
                                  onChange={e => setDatasetName(e.target.value)}
                     />
                    <ClearIcon onClick={() => handleAction("exit-dataset-name")}/>
                    <CheckIcon onClick={() => handleAction("save-dataset-name")}/>
                  </IconButton>
                </Tooltip>  
              </Typography>
              </>
            ):(
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Name of Dataset: {datasetName}
                <Tooltip title="Change Name of Dataset">
                  <IconButton aria-label="edit"
                    onClick={() => handleAction("edit-dataset-name")}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            )
          }

          { numSelected > 0 &&
            <Tooltip title="DB">
              <IconButton aria-label="db"
                        onClick={() => handleAction("db")}
                        >
                <StorageIcon /> Click Here to store {datasetName} in database
              </IconButton>
            </Tooltip>
          }

        </Typography>
      )}
      {numSelected > 0 ? (
      <>
{/*         <Tooltip title="Header-hdrshow">
          <IconButton aria-label="delete"
                    onClick={() => handleAction("hdrshow")}
                    >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Data-datashow">
          <IconButton aria-label="delete"
                    onClick={() => handleAction("datashow")}
                    >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
 */}

        { (numSelected == 1) &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                      onClick={() => handleAction("edit")}
                      >
              <EditIcon />
            </IconButton>
          </Tooltip>        }
        <Tooltip title="Email">
          <IconButton aria-label="email"
                    onClick={() => handleAction("email")}
                    >
            <AddEmailButton onAddNewEmail={onAddNewEmail} hdrArray={hdrArray} numSelected={numSelected} onAddNewEmail={onAddNewEmail}/>

{/*             <MailOutlineIcon />
 */} 
          </IconButton>
        </Tooltip>

{/*         <Tooltip title="ACH">
          <IconButton aria-label="ach"
                    onClick={() => handleAction("ach")}
                    >
            <AccountBalanceIcon />
          </IconButton>
        </Tooltip>
 */}
{/*         <Tooltip title="DB">
          <IconButton aria-label="db"
                    onClick={() => handleAction("db")}
                    >
            <StorageIcon />
          </IconButton>
        </Tooltip>
 */} 
 
{/*         <Tooltip title="Permissions">
          <IconButton aria-label="permissions"
                    onClick={() => handleAction("permissions")}
                    >
            <SecurityIcon />
          </IconButton>
        </Tooltip>
 */} 
 
        <Tooltip title="Delete">
          <IconButton aria-label="delete"
                    onClick={() => handleAction("delete")}
                    >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
      ) : (
      <>
{/*         <Tooltip title="restore arg">
          <IconButton aria-label="test"
            onClick={() => handleAction("test")}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
 */}        
        <MuiThemeProvider>
          <SelectField
            style={{ marginLeft: "1em" }}
            floatingLabelText="Select from Database"
            value={selectedFromCloud}
            onChange={(event, index, value) =>
              onSetDatasetSelectedFromCloud(value)
            }
          >
           { datasetsAvailableFromCloud.map(name => ( <MenuItem value={name} primaryText={name}  /> )) }
            <MenuItem value="test1" primaryText="primtest1"/>
          </SelectField>
{/*           <CloudDownloadIcon text="Select" />
 */}        </MuiThemeProvider>


{/*           { headerProp.map(obj => ( <MenuItem value={obj.id} primaryText={obj.label}  /> )) }
 */}


{/*         <Tooltip title="Pull dataset from Cloud">
          <IconButton aria-label="open"
            onClick={() => handleAction("open")}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
 */}      </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1600,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({querySpec, columnSpec, firstSheetName, headerProp, setHeaderProp, setColumnSpec, dataProp}) {
  const classes = useStyles();
  const [datasetNamesInDB, setDatasetNamesInDB] = React.useState([]);
  const [datasetsInDB, setDatasetsInDB] = React.useState([]);
  const [datasetSelectedFromCloud, setDatasetSelectedFromCloud] = React.useState([]);
  const [datasetKey, setDatasetKey] = React.useState("");
  const [datasetName, setDatasetName] = React.useState(firstSheetName);
  const [datasetBucketKey, setDatasetBucketKey] = React.useState("");
  const [editingDatasetName, setEditingDatasetName] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [existingUsersByEmail, setExistingUsersByEmail] = React.useState([]);

  const [toolAction, setToolAction] = React.useState("");
//  const [headerArray, setHeaderArray] = React.useState(headerArg);
//  const [dataArray, setDataArray] = React.useState(dataArg); 
  const [headerArray, setHeaderArray] = React.useState(headerArg);
  const [dataArray, setDataArray] = React.useState(dataArg); 
  const [dataArrayFiltered, setDataArrayFiltered] = React.useState(dataArg); 
    // replacing rows with dataIn, and headerIn

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataArray.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const lowerCaseQuery = querySpec.toLowerCase();

  useEffect(() => {
    const indexOfName = datasetNamesInDB.indexOf(datasetSelectedFromCloud)
    if( indexOfName != -1 )
    {
      const dbBucketRef = firedb.database().ref(`sys_unit_datasets/${datasetsInDB[indexOfName].obj.id}/`);
      setDatasetKey(datasetsInDB[indexOfName].key)
      setDatasetBucketKey(datasetsInDB[indexOfName].obj.id)
      console.log("NEEEEEWWWWW loading from named dataset ",indexOfName, datasetSelectedFromCloud, datasetKey, datasetBucketKey)
      // Let's load the dataarrays
      dbBucketRef.on('value', (snapshot) => {
        const dbs = snapshot.val();
        let tmpArr = []
        let dataArr = []
        for (let id in dbs) {
          if( tmpArr.length == 0 )
          {
            Object.keys(dbs[id]).forEach(function(key) {
            console.log("ITERATION: ",key)
            tmpArr.push({ 
              "id": key,
              "numeric": "false", 
              "disablePadding": "true", 
              "label": key})
            });
            setColumnSpec(tmpArr[0].id)
            setHeaderArray(tmpArr)
            setHeaderProp(tmpArr)
            console.log("NEEEEEWWWWW loading from named dataset 2 ",dbs[id],tmpArr)
          }
          dataArr.push(dbs[id])
        }
        setDataArray(dataArr)
        console.log("NEEEEEWWWWW loading from named dataset 3 ",dataArr)
        setDataArrayFiltered(dataArr)
        setDatasetName('No Name')
      })
    }
  }, [datasetSelectedFromCloud])

  useEffect(() => {
    // Grab all user emails on creation in case we want to induct items 
    database.users.onSnapshot(snapshot => {
      let tmpList = [];
      snapshot.docs.map( doc => {
        const obj = { id: doc.id, ...doc.data() }
        console.log("READING User Table w/ ",obj)
        tmpList.push(obj.email)
      })
      setExistingUsersByEmail(tmpList)
      console.log("INITIALIZING User Table w/ ",tmpList)
    })

    const dbRef = firedb.database().ref(`sys_unit_names/`);
    dbRef.on('value', (snapshot) => {
      const dbs = snapshot.val();
      let namesList = [];
      let tmpList = [];
      for (let id in dbs) {
        tmpList.push({ key: id, obj: dbs[id]})
        namesList.push(dbs[id].name)
      }
      setDatasetsInDB(tmpList)
      setDatasetNamesInDB(namesList)
      ////
      setDataArray([])
      setDataArrayFiltered([])
      console.log("INITIALIZING setDatasetsInDB w/ ",tmpList, namesList, datasetKey)
    })
  }, [])

  useEffect(() => {
    setDatasetName(firstSheetName)
  }, [firstSheetName])

  useEffect(() => {
    if( dataArray.length > 0 )
    {
//      let tmpArr = [...dataArray]
      let tmpArr = dataArray.filter(x =>
        {
//        console.log("QuerySpec Iterating Filter: ", x[columnSpec] ? x[columnSpec].toString().toLowerCase().includes(lowerCaseQuery): null)
        if( x[columnSpec] )
          return (
              x[columnSpec].toString().toLowerCase().includes(lowerCaseQuery)
          )
        }
      )
/*       data={orderBy(
        query
          ? data.filter(x =>
              x[columnToQuery]
                .toString()
                .toLowerCase()
                .includes(lowerCaseQuery)
            )
          : data,
        columnToSort,
        sortDirection
      )}
 */   setDataArrayFiltered(tmpArr) // dataArrayFiltered affects 5 place below
      console.log("QuerySpec Executed", querySpec, columnSpec, dataArray, tmpArr.length)
    }
  }, [querySpec,columnSpec])

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataArrayFiltered.length - page * rowsPerPage);

  useEffect(() => {
    setHeaderArray(headerProp)
    setDataArray(dataProp)
    setDataArrayFiltered(dataProp)
    console.log("data and header prop setting", headerProp, dataProp)
  }, [dataProp, headerProp])

  useEffect(() => {
    if( toolAction.length > 0 )
    {

      const dbRef = firedb.database().ref(`sys_unit_names/`);
      dbRef.on('value', (snapshot) => {
        const dbs = snapshot.val();
        let namesList = [];
        let tmpList = [];
        for (let id in dbs) {
          tmpList.push({ key: id, obj: dbs[id]})
          namesList.push(dbs[id].name)
          if( dbs[id].name === datasetName)
          {
            console.log("Found key",id)
            setDatasetKey(id)
          }
        }
        setDatasetsInDB(tmpList)
        setDatasetNamesInDB(namesList)
        console.log("INITIALIZING setDatasetsInDB w/ ",tmpList, namesList, datasetKey)
      })
  

      console.log("Tool Action set", toolAction)
      console.log("HeaderArray", headerArray)
      console.log("DataArray", dataArray)
      console.log("DataArrayFiltered", dataArrayFiltered)
      console.log("HeaderProp", headerProp)
      console.log("DataProp", dataProp)
      if(toolAction == 'hdrshow')
      {
        console.log("SWITCHTING HEADER")
        setHeaderArray(headerProp)
      }
      if(toolAction == 'datashow')
      {
        console.log("SWITCHTING DATA")
        setDataArray(dataProp)
      }
      ////////////////////////////////////////////////////////////////
      if(toolAction == 'exit-dataset-name')
      {
        console.log("exit-dataset-name")
        setEditingDatasetName(false)
      }
      if(toolAction == 'edit-dataset-name')
      {
        console.log("edit-dataset-name")
        setEditingDatasetName(true)
      }
      if(toolAction == 'save-dataset-name')
      {
        console.log("save-dataset-name")
        setEditingDatasetName(false)
      }
      if(toolAction == 'add')
      {
        console.log("add item")
      }
      if(toolAction == 'email')
      {
        // we handle the email creation in the Modal
        // collection of emails with subject listing
        console.log("handle EMAIL - sending call ",selected)
//        http://143.110.145.59:4000/send/1234567890

/*       const apiUrl = 'https://swapi.dev/api/planets/1/';
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => console.log('This is your data', data));
 */
      }
      if(toolAction == 'db')
      {
        // 1) datasetName is NOT PRESENT in datasetNamesInDB and we can on ALL send to DB
        if( selected.length > 1 && selected[0] == null  ) // all is selected on a present dataset
        {
          if( datasetNamesInDB.indexOf(datasetName) !== -1 )
            console.log("Found name to be existing in DB so no full upload can be done")
          else
          {
            console.log("NAme is not present -> so full upload")
            const dbRef = firedb.database().ref(`sys_unit_names/`);
            const ts = Date.now()
            var newRef = dbRef.push(); // this does *not* call the server
            var newKey = ""
            newKey = newRef.key;  // Perhps only key as a property
            const newUnit = {
              id: newKey,
              name: datasetName,
              email: "who did it", 
              valid: true,
              ts: ts
            }
            dbRef.push(newUnit).on('value', (snapshot) => {
              const dbs = snapshot.val();
              let namesList = [];
              let tmpList = [];
              for (let id in dbs) {
                tmpList.push({ key: id, obj: dbs[id]})
                namesList.push(dbs[id].name)
                if( dbs[id].name === datasetName)
                {
                  console.log("Found key",id)
                  setDatasetKey(id)
                }
              }
              setDatasetsInDB(tmpList)
              setDatasetNamesInDB(namesList)
              console.log("INITIALIZING setDatasetsInDB w/ ",tmpList, namesList, datasetKey)
            })

            const dbSetRef = firedb.database().ref(`sys_unit_datasets/${newKey}/`);
            selected.map((row, index) => {
              console.log("Iterating Selection",dataArray[index], index)
              if( selected.length > 1 && row == null )
              {
                dbSetRef.push(dataArray[index]) // if row is null we are pushing everything - so use index
                // If dataset has an email - create a user
                Object.keys(dataArray[index]).map(function(keyName, keyIndex) {
                  const obj = dataArray[index]
                  if( keyName.toLowerCase().indexOf("email") >= 0)  // emails.push(obj[keyName])
                    if( obj[keyName].toLowerCase().indexOf("@") >= 0)
                    {
                      console.log("------> found valid email", obj[keyName])
                      const newUser = {
                        group: ["PUBLIC-USER"],
                        origin: "",
                        profileURL: "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/sys_profile_pictures%2Fgm%40sixty01.com%2Fprofileimage?alt=media&token=bb959e6b-b2b8-43c9-9ed9-1cfd6dc05ad2",
                        name: "no name",
                        addr: "no addr",
                        email: obj[keyName], 
                        valid: '1',
                        ts: Date.now()
                      }
                      database.users.add(newUser)                  
                    }
                })
        
//                if( )
              }
              else
                alert("Error")
            })
          }
        }
      }
      setToolAction("")
    }
  }, [toolAction])

  const onAddNewEmail = async (subject, body, attachments, hdrIndex, action) => {
    const column = headerArray[hdrIndex].id
    const testemails = ["jari@havenrealestateservices.com","mortensenviggo0@gmail.com","frontdesk@sixty01.com","hres.jkwebapps@gmail.com"]
    console.log("onAddNewEmail TOP - args: ",subject, body, attachments, hdrIndex, action)
    console.log("onAddNewEmail TOP - values: ",selected, column, attachments)
    let emails = []
    if( dataArray.length == selected.length ) // all is selected
    {
      console.log("onAddNewEmail TOP - selected: ",selected.length, dataArray)
      dataArray.map((row, index) => { 
//        const obj = dataArray[row]
        console.log("onAddNewEmail TOP - selecting ALL: ",row, index)
        Object.keys(row).map(function(keyName, keyIndex) {
          if( keyName == column )  emails.push(row[keyName])
        })
      })  
    }
    else
    {
      selected.map((row, index) => { 
        const obj = dataArray[row]
        console.log("onAddNewEmail TOP - Selecting Iteration: ",row, index)
        Object.keys(obj).map(function(keyName, keyIndex) {
          if( keyName == column )  emails.push(obj[keyName])
        })
      })  
    }
    console.log("onAddNewEmail TOP - emails: ",selected, emails)
    // Add email list agaisnt the setExistingUsersByEmail
    emails.map((email, index) => {
      if( existingUsersByEmail.includes(email) )
        console.log("onAddNewEmail TOP - Found exisitng: ",email)
      else
      {
        const tss = Date.now()
        const newUser = {
          origin: datasetName,
          name: "no name",
          addr: "no addr",
          email: email, 
          valid: '0',
          ts: tss
        }
        database.users.add(newUser)    
      }
    })
    ///////////////////////////
    const ts = Date.now()
    const emailRef = firedb.database().ref(`sys_emails_${action}`);
    var attachmentsPresent = 0
    var newRef = emailRef.push(); // this does *not* call the server
    var newKey = ""
    newKey = newRef.key;  // Perhps only key as a property
    if (attachments.length > 0) 
      attachmentsPresent = attachments.length
    const newObj = {
      id: newKey,
      attachments: attachmentsPresent,
      subject: subject,
      body: body,
      state: action, 
      to: emails,
      ts: ts
    }
    if (attachments.length > 0) 
    { // Explain thi shack
      // https://stackoverflow.com/questions/64142108/react-js-image-uploder-code-with-firebase-cloud-storage-gives-error
      // with an attachment we first up;oad and then ge the URL and then set the email entry and then call the server
      let urlArray = []
      let nameArray = []
      const attachmentFirst = attachments[0]
      const attachmentNameFirst = attachments[0].name
      console.log("Uploading attachments - starting array: ",attachments, action)
      for (var i = 0; i < attachments.length; i++)
      {
        let urlTmp = ''
        console.log("Uploading: ", attachments[i].name)
        await storage
          .ref(`sys_attachments/${newKey}/${attachments[i].name}`)
          .put(attachments[i])
        urlTmp = await storage.ref(`sys_attachments/${newKey}`)
                                .child(`${attachments[i].name}`)
                                .getDownloadURL()

        console.log("Adding to arrays: ", urlTmp, attachments[i].name)
        urlArray.push(urlTmp)
        nameArray.push(attachments[i].name)
      }
      const newObj = {
        id: newKey,
        attachments: attachmentsPresent,
        attachName: nameArray,
        attachURL: urlArray,
        subject: subject,
        body: body,
        state: action, 
        to: emails,
        ts: ts
      }                              
      database.emails.add(newObj).then(() => {
/*                                     const apiUrl = `https://prev.allhavenhomes.com/mail/send/${newKey}`;
 */                                    const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
/*                                     const apiUrl = `http://localhost:452/mail/send/${newKey}`;
 */        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => console.log('This is your data', data));                        
      })

// https://stackoverflow.com/questions/41352150/typeerror-firebase-storage-is-not-a-function


/*       const uploadJob = storage
        .ref(`sys_attachments/${newKey}/${attachmentNameFirst}`)
        .put(attachmentFirst)
      uploadJob.on(
        "state_changed",
        snapshot => {},
        error => {
          console.log(error)
        },
        () => {
          console.log("UPUPUPUPLOAD OF ATTACHMENTS COMPLETE")
          const jobRef = storage.ref(`sys_attachments/${newKey}`)
                                .child(`${attachmentNameFirst}`)
                                .getDownloadURL()
                                .then((url) => {
                                  console.log("Got URL from UPLOAD: ",url)
                                  const newObj = {
                                    id: newKey,
                                    attachments: attachmentsPresent,
                                    attachName: attachmentNameFirst,
                                    attachURL: url,
                                    subject: subject,
                                    body: body,
                                    state: action, 
                                    to: emails,
                                    ts: ts
                                  }                              
                                  database.emails.add(newObj).then(() => {
                                     const apiUrl = `https://prev.allhavenhomes.com/mail/send/${newKey}`;
                                    const apiUrl = `http://localhost:452/mail/send/${newKey}`;
                                    fetch(apiUrl)
                                      .then((response) => response.json())
                                      .then((data) => console.log('This is your data', data));                        
                                  })
                                })
        }
      )
 */    
    }
    else
    { // just push the new email and .then PING the server
      if( action == "draft" )
        database.emails.add(newObj)
      else
      {
        database.emails.add(newObj).then(() => {
/*           const apiUrl = `https://prev.allhavenhomes.com/mail/send/${newKey}`;
 */          const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;

/*           const apiUrl = `http://localhost:452/mail/send/${newKey}`;
 */            fetch(apiUrl)
              .then((response) => response.json())
              .then((data) => console.log('This is your data', data));
          })
      }
    } 
    // Store files in storage under <newKey>
/*     if (fileObjs.length > 0) 
    {
      for (var i = 0; i < fileObjs.length; i++)
      {
        console.log("Uploading: ")
        const uploadJob = storage
          .ref(`sys_tickets/${newKey}/${fileObjs[i].name}`)
          .put(fileObjs[i])
        uploadJob.on(
          "state_changed",
          snapshot => {},
          error => {
            console.log(error)
          },
          () => {
            console.log("UPLOAD COMPLETE")
            setTicketCnt(ticketCnt+1)
          }
        )  
      }
    }
 */
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
          editingDatasetName={editingDatasetName}
          datasetName={datasetName}
          setDatasetName={setDatasetName}
          datasetsAvailableFromCloud={datasetNamesInDB}
          setDatasetSelectedFromCloud={setDatasetSelectedFromCloud}
          onAddNewEmail={onAddNewEmail}
          hdrArray={headerArray}
          selection={selected}
          numSelected={selected.length}
          setToolAction={setToolAction}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataArrayFiltered.length}
              headers={headerArray}
            />
            <TableBody>
              {stableSort(dataArrayFiltered, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => { 
                  /// use index rather then row.name - unique key for "selected" array
                  const isItemSelected = isSelected(index) //row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

//                  console.log("Iterating DATA - Why are we running through the rerendering on tool choice??: ",row, index, isItemSelected)

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, index)} //row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index} //{row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                      />
                      </TableCell>

                      {headerArray.map((y, k) => (
//                        console.log("Iterating header inside a row: ",row, index, y)
//                        console.log("Iterating header BY-LABEL inside a row: ",y.label)
//////////////////////////  y.label is wrong - it's the id thats the first true key
                           <TableCell align="right">
                            {row[y.id]}
                          </TableCell>
                      ))}


{/*                       {headerArray.map((y, k) => (
                        <TableCell align="right">
                          {row.calories}                             x[y.label]
                        </TableCell>
                      ))}




                        <TableRowColumn key={`trc-${k}`}
                        onClick={(e) => { onCatch() }}  
 */}


{/*                       <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell>
 */}

{/*                       </Table   Cell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell>
 */}                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataArrayFiltered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
{/*       <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
 */}    </div>
  );
}
