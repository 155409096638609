import React, { useState } from 'react';
//import Title from './comps/Title';
//import UploadForm from './comps/UploadForm';
import ImageGrid from './ImageGrid';
import Modal from './Modal';

import Navbar from "../Navbar"

function ICVpage({selection}) {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <div className="ImgGrid">
{/*       <Navbar title='Viewing Image Collection' />
 */}{/*       <Title/>
      <UploadForm />
 */}   


{/*       {selection.map(sel => (
                    <div key={sel.id}>
                      <p2>{sel.value},{sel.url}</p2>
                      </div>
      ))}
 */}                    
                    


      <ImageGrid selection={selection} setSelectedImg={setSelectedImg} />
      { selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </div>
  );
}

export default ICVpage;
