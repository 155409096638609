import React from "react"
import { Container } from "react-bootstrap"

import { useState, useEffect } from "react"

import Navbar from "../google-drive/Navbar"
import './Units.css'; 

import * as XLSX from "xlsx";


import EnhancedTable from "./../TableEnhanced/EnhancedTable"
import EnhancedTableSearch from "./../TableEnhanced/EnhancedTableSearch"


import { faDownload } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const headerIn = [
  { id: 'jkname', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
  { id: 'jkcalories', numeric: true, disablePadding: false, label: 'Calories' },
  { id: 'jkfat', numeric: true, disablePadding: false, label: 'Fat (g)' },
  { id: 'jk1carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
  { id: 'jkcarbs', numeric: true, disablePadding: false, label: 'jkCarbs (g)' },
  { id: 'jkprotein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const dataIn = [
  createData('jkCupcake', 305, 3.7, 67, 4.3),
  createData('jkDonut', 452, 25.0, 51, 4.9),
  createData('jkEclair', 262, 16.0, 24, 6.0),
  createData('jkFrozen yoghurt', 159, 6.0, 24, 4.0),
  createData('jkGingerbread', 356, 16.0, 49, 3.9),
  createData('jkHoneycomb', 408, 3.2, 87, 6.5),
  createData('jkIce cream sandwich', 237, 9.0, 37, 4.3),
  createData('jkJelly Bean', 375, 0.0, 94, 0.0),
  createData('jkKitKat', 518, 26.0, 65, 7.0),
  createData('jkLollipop', 392, 0.2, 98, 0.0),
  createData('jkMarshmallow', 318, 0, 81, 2.0),
  createData('jkNougat', 360, 19.0, 9, 37.0),
  createData('jkOreo', 437, 18.0, 63, 4.0),
];

/* const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
 */
/* const Input = styled('input')({
  display: 'none',
});
 */

const invertDirection = {
  asc: "desc",
  desc: "asc"
};

export default function Units() {

  const classes = useStyles();

  const [inputFile, setInputFile] = useState("")
  const [firstSheetName, setFirstSheetName] = useState("No Name")

/*   useEffect(()=>{
    console.log("FileName being set: ",inputFile)
    console.log("First Sheet Name: ",firstSheetName)
  },[inputFile, firstSheetName])

 */
  ///////////////////////////////////////////////////////////

  const [dbTabNames, setDBTabNames] = useState([])
  const [currDBTabName, setCurrDBTabName] = useState("")

  const [headerProp, setHeaderProp] = useState([])
//  const [dataSrc, setDataSrc] = useState([]) // ARRAY OF OBjects
  const [dataProp, setDataProp] = useState([]) // ARRAY OF OBjects

  const [querySpec, setQuerySpec] = useState("Bol")
  const [columnSpec, setColumnSpec] = useState("Owner")

  const [disabled, setDisabled] = useState(false);

  function handleDataSetNameClick() {
    setDisabled(!disabled);
  }

//  const [ checked, setChecked ] = useState(true)
//  const [items, setItems] = useState([]);

  ///////////////////////////////////////////////////////////

  const readExcel = (file) => {
    setInputFile(file)
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        setFirstSheetName("")
        const bufferArray = e.target.result;        
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        console.log("FISRT SHHET NAME: ",wsname)
        setFirstSheetName(wsname)
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((d) => {
      let tmpArr = []
      Object.keys(d[0]).forEach(function(key) {
        console.log("ITERATION: ",key)
        tmpArr.push({ 
          "id": key,
          "numeric": "false", 
          "disablePadding": "true", 
          "label": key})
      });
      console.log("Produced array: ",tmpArr[0].id,tmpArr)
      setColumnSpec(tmpArr[0].id)
      setHeaderProp(tmpArr)
      setDataProp(d)
    });
  };

/*   useEffect(()=>{
    // dataSrc to dataProp
    if( querySpec.length > 0 )
    {
      let tmpData = [...dataSrc]
      dataProp = tmpData.filter(x =>
        x[columnSpec].toString().includes(lowerCaseQuery)
      )
      dataProp = dataSrc.filter(x =>
      x[columnSpec]
        .toString()
        .toLowerCase()
        .includes(lowerCaseQuery)
      )
      console.log("NewSet: ")
    }
//    console.log("First Sheet Name: ",firstSheetName)
  },[querySpec, columnSpec])
 */
    
/*   const readExcel = (file) => {
    setInputFile(file)
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];
        setFirstSheetName(wsname)

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setRecordsArr(d)
      setItems(d);
    });
  };
 */
/*   const [columnToSort, setColumnToSort] = useState("")
  const [sortDirection, setSortDirection] = useState("desc")
 */
/*   const handleSort = (columnName) => {
    console.log("TESTING: ")

    setColumnToSort(columnName)
    setSortDirection(
      columnToSort === columnName
        ? invertDirection[sortDirection]
        : "asc"
    )
  };
 */
//  const lowerCaseQuery = querySpec.toLowerCase();

  return (
    <>
      <Navbar title='Units Listings' />
      <Container fluid>

      <div style={{ display: "flex", marginRight: "700px" }}>
        <div style={{ display: "flex", margin: "auto" }}>

          <div style={{ display: "inline", marginTop: "30px", marginRight: "200px" }}>
            <label class="custom-file-upload">
              <input type="file"
                onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                }}/>
                <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                    From Excel File
            </label>
          </div>

          <EnhancedTableSearch 
            headerProp={headerProp} 
            setQuerySpec={setQuerySpec} 
            setColumnSpec={setColumnSpec}
            columnSpec={columnSpec}
          />
        </div>
      </div>

      <table className="table container">
        <EnhancedTable  
            querySpec={querySpec}
            columnSpec={columnSpec}
            firstSheetName={firstSheetName} 
            headerProp={headerProp} 
            setHeaderProp={setHeaderProp}
            setColumnSpec={setColumnSpec}
            dataProp={dataProp}
        />
      </table>
      </Container>
    </>
  )
}