import React, {Component} from 'react'

import Card from './CardUI'

import img1 from './Assets/pool1.jpg'
import img2 from './Assets/pool2.jpg'
import img3 from './Assets/pool3.jpg'

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="container-fluid d-flex justify-content-center">
              <div className="row">
                <div className="col-md-3"><Card imgsrc={img1} title="Deck"/></div>
                <div className="col-md-3"><Card imgsrc={img2} title="Play"/></div>
                <div className="col-md-3"><Card imgsrc={img3} title="Swim"/></div>
                <div className="col-md-3"><Card imgsrc={img1} title="Deck"/></div>
              </div>
            </div>
        );
    }
}

export default Cards;