import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useRef, useState, useEffect } from "react"

export default function NewComp(props) {

  const titlestring = props.title;
  const [ checkBoxOne, setCheckBoxOne ] = useState(false)

  const handleCheckTicket = (e) => { 
    console.log("f18: handleCheckTicket: ", e.target.checked)
    setCheckBoxOne(e.target.checked)
  }

  useEffect(() => {
    console.log("f18: UseEffect", checkBoxOne)
  }, [checkBoxOne])

  console.log({titlestring});

  return (
    <Navbar bg="light" expanded="sm">
      <Navbar.Brand as={Link} to="/">
         Indeed {titlestring}
      </Navbar.Brand>
      <Nav>
        <Nav.Link as={Link} to="/user">
          Profile
        </Nav.Link>

        <div class="ml-3 form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                 checked={checkBoxOne} onClick={(e)=>handleCheckTicket(e)}/>
          <label class="ml-3 form-check-label" for="inlineCheckbox1">1</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label class="form-check-label" for="inlineCheckbox2">2</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" disabled/>
          <label class="form-check-label" for="inlineCheckbox3">3 (disabled)</label>
        </div>

      </Nav>
    </Navbar>
  )
}
