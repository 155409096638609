import React, { useState, useEffect } from "react"

import { Button, Modal, Form } from "react-bootstrap"
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import firedb from "../../firebase" 
import { database } from "../../firebase"

import { useAuth } from "../../contexts/AuthContext"

import DateTimePicker from "react-datetime-picker"

const mCollectionUsers = database.users // FireStore Database

export default function CallForMeeting({enableDisableMeeting}) {   
  const { currentUser } = useAuth()
  const [open, setOpen] = useState(false)
  const [actionName, setActionName] = useState("No Title")
  const [actionSubject, setActionSubject] = useState("Enter Description")

  const [tValue, onChange] = useState(new Date());

  const [timeMeeting, setTimeMeeting] = useState("");
  const [upcomingMeeting, setUpcomingMeeting] = useState("Upcoming Meeting: ")

  useEffect(() => {
    console.log("f11: Start: ")
    mCollectionUsers.where("email", "==", "gm@sixty01.com")
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          if( doc.data().email == "gm@sixty01.com" )
          {
            console.log("f18: : ",doc.data().meeting)
            setTimeMeeting( doc.data().meeting )
          }
      });
    })
  }, [currentUser])


  function SetUpdateString() {
    const parts = timeMeeting.split("@")
    const tMeeting = parseInt(parts[1])
    const nMeeting = parts[0]
    console.log("f11: Time Meeting: ", tMeeting)
    
    var myDate = new Date(1601528702*1000);
    console.log(" f11: TESTING OUT: ", myDate.toLocaleString()); // 01/10/2020, 10:35:02

    const strMeetingDate = new Date(tMeeting+(7*60*60*1000))
    console.log("f11: Time Meeting0: ", tMeeting, strMeetingDate)
    
    const tNow = Date.now()-21600000-3600000// subtracting 7 hours as we are 7 hours behind GMT 
    const tDelta = (tMeeting - tNow)/1000
    console.log("f11: T Delta: ", tDelta)
    if( tDelta > 1000 && tDelta < 86400 )
    {
      var date = new Date(0);
      date.setSeconds(tDelta); // specify value for SECONDS here
      var timeString = date.toISOString().substr(11, 8);
      console.log(timeString)  
      if( tDelta < 120 )
      {
        setUpcomingMeeting( " Meeting Starting Anytime Now" )  
      }
      else
      {
        setUpcomingMeeting( `Upcoming Meeting: ${timeString}   ( ${nMeeting} )` )  
        setTimeout(SetUpdateString, 5000);  
      }
    }
    else
    {
      console.log("f11: Time Meeting2: ", strMeetingDate)
      const parts = strMeetingDate.toLocaleString().split(" ")
      setUpcomingMeeting( `Upcoming Meeting:  ${parts[0]} ${parts[1]} ${parts[2]}  ( ${nMeeting} )` )  
    }  
  }

  useEffect(() => {
    console.log("f11: NOW: ")
    if( timeMeeting.includes("@"))
    {
      SetUpdateString()
    }
  }, [timeMeeting])

  function setAction(index, value) {
  }
  function openModal() {
    setOpen(true)
  }
  function closeModal() {
    setOpen(false)
  }
  function setName(value) {
    console.log("setName: ",value)
    setActionName(value)
  }
  function setSubject(value) {
    console.log("setSubject: ",value)
    setActionSubject(value)
  }
  function handleSubmit(e) {
    e.preventDefault()
    const dtr = `${actionName}-${actionSubject}-${tValue}`
//    alert(dtr)
    const parts = dtr.split('-')
    console.log("FOUND a (f9:) => ", parts);
    const times = parts[2].split(' ')
    console.log("FOUND b (f9:) => ", times);
    const secs = Date.parse(`${times[2]} ${times[1]} ${times[3]} ${times[4]} ${times[5]}`)
    console.log("FOUND c (f9:) => ", secs);
    const tNow = Date.now()-21600000-3600000// subtracting 7 hours as we are 7 hours behind GMT 
    console.log("FOUND d (f9:) => ", tNow);
    console.log("FOUND e (f9:) => ", secs-tNow);
    if( (tNow+3600000) < secs ) // any scheduling requires 1 hr lead time
    {
      // Let's update the meeting string in GM user profile
      mCollectionUsers.where("email", "==", currentUser.email)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          const str = `${actionName}@${secs}@${actionSubject}`
          mCollectionUsers.doc(doc.id).update({meeting: str});
          console.log("f16: storing => ", str);
          setTimeMeeting( str )
        });
      })
      closeModal()
    }
    else{
      alert("Meeting must be scheduled in the future")
    }
  }
  function cancelMeeting() {
    mCollectionUsers.where("email", "==", currentUser.email)
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          mCollectionUsers.doc(doc.id).update({meeting: ""});
          setTimeMeeting("")
      });
    })
  }

  // timeMeeting.length > 0

  return (
    <>
      { currentUser && currentUser.email == "gm@sixty01.com" && 
          <>
          { timeMeeting.length > 0 ? 
            (
              <Button onClick={cancelMeeting} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
                <span>Cancel Meeting</span>
              </Button>
            ):(
              <Button onClick={openModal} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
                <span>Schedule Meeting</span>
              </Button>
            )
          }
          </>
      }

      { timeMeeting.length > 0 &&
        <div class="mt-2 ml-4">
          <span>{upcomingMeeting}</span>
{/*           <a href="#" id="" class="close-note btn btn-primary mt-3">click</a>
 */}        </div>
      }

      <Modal show={open} onHide={closeModal}>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Schedule a Meeting</Form.Label>
            </Form.Group>

            <MuiThemeProvider>
              <Form.Label>Title of Meeting</Form.Label>
              <Form.Control
                type="text"
                placeholder="Give the meeting a title"
                
                value={actionName}
                onChange={e => setName(e.target.value)}
              />
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Set an Agenda for the Meeting"
/*                 required
 */                value={actionSubject}
                onChange={e => setSubject(e.target.value)}
              />
              <div class="mt-2">
                <Form.Label class="ml-3">Time of Meeting</Form.Label>
                <DateTimePicker
                  onChange={onChange}
                  value={tValue}
                />
              </div>
            </MuiThemeProvider>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Schedule
            </Button>
          </Modal.Footer>
        </Form>

     </Modal>
    </>
  )
}
