import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "react-router-dom"

import CallForMeeting from './CallForMeeting'

export default function NavbarComponent(props) {

  const titlestring = props.title;

  console.log({titlestring});

  return (
    <Navbar bg="light" expanded="sm">
      <Navbar.Brand as={Link} to="/">
         {titlestring}
      </Navbar.Brand>
      <Nav>
        <Nav.Link as={Link} to="/user">
          Profile
        </Nav.Link>
        <CallForMeeting/>
      </Nav>
    </Navbar>
  )
}
