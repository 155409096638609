import React from "react"
import { useState, useEffect } from "react"

import './card-style.css'

export default function Card(props) {

  const [ checked, setChecked ] = useState(true)

  return (
    <>
    <div className="card">
      <h1 style={{ color: "black" }}>Pool</h1>
    </div>
    <div className="card text-center shadow">
      <div className="overflow">
        <img src={props.imgsrc} alt="Image 1" className="card-img-top" />
      </div>
      <div className="card-body text-dark">
        <h4 className="card-title">{props.title}</h4>
        <p className="card-text text-secondary">Lorem, ipit quaerat ducimus architecto voluptatibus accusantium a minima cumque dolore aut.</p>
        <a href="#" className="btn btn-outline-success">Go Anywhere</a>
      </div>
    </div>
    </>
  )
}


