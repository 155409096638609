import React from 'react';

export const Day = ({ day, onClick }) => {
  const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : '' } ${day.weekday == false ? 'weekend' : '' }`;
  return (
    <div onClick={onClick} className={className}>
      {day.value === 'padding' ? '' : day.value}

      {day.event && day.event.length > 1 && 
        <>
          <div className='event'>{day.event[0].title}</div>
          <div className='event'>{day.event[1].title}</div>
        </>
      }

      {day.event && day.event.length == 1 && <div className='event'>{day.event[0].title}</div>}
{/*       {day.event && day.event && <div className='event'>{day.event.title}</div>}
      {day.event && day.event && <div className='event'>{day.event.title}</div>}
 */}    </div>
  );
};
