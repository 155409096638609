import { faFile, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "bootstrap"
import { Checkbox, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap"

import * as XLSX from "xlsx";

/* import * from "java.net"
import java.io.*;
 */

import React from "react"

export default function File({ file, setFileUrl }) {

  const handleClick = () => {
    console.log("FILESEDESD: ",file)
    window.open(file.url);



//    URL oracle = new URL("http://www.oracle.com/");
//    BufferedReader in = new BufferedReader(
//    new InputStreamReader(oracle.openStream()));

//    String inputLine;
//    while ((inputLine = in.readLine()) != null)
//        System.out.println(inputLine);
//    in.close();



/*     const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file.url);
//      fileReader.readAsArrayBuffer(file.url);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];
        console.log("FILESEDESD-wsname: ",wsname)
        //        setFirstSheetName(wsname)

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
 */


    setFileUrl(file.url)
  }

  return (
      <a
        target="_blank"
/*         className="btn btn-outline-dark text-truncate w-100"
 */        className="btn btn-outline-dark text-truncate w-auto"
             onClick={handleClick}
      >
        <FontAwesomeIcon icon={faFile} className="mr-2" />
        {file.name}
      </a>
  )
}
