import React from "react"
import Navbar from "./Navbar"
import { useState, useEffect } from "react"
import { storage, database } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import PeopleGallery from "./PeopleGallery/PeopleGallery"
import ConversationList from "./ConversationList/ConversationList"
import MessageList from "./MessageList/MessageList"
import './MsgCenter.css';

import { Container } from "react-bootstrap"

//import { faExpand, faFile, faLock, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import { storage } from "../../firebase"
//import firedb from "../../firebase" 

//const componentName = "messages"

export default function MsgCenter() {
  const [ dbRef, setDbRef] = useState(null)
  const [ referMsgId, setReferMsgId ] = useState("")
  const [ searchTerm, setSearchTerm ] = useState("")
  const [ first, setFirst ] = useState()
  const [ announcements, setAnnouncements ] = useState([])
  const [ filteredAnnouncements, setFilteredAnnouncements ] = useState([])

  const { currentUser } = useAuth()
  const [ userAcct, setUserAcct ] = useState()
  const [ userGUID, setUserGUID ] = useState()
  const [ showAnnouncement, setShowAnnouncement ] = useState()

  const onEdit = (key) => {
    console.log("onEdit: ",key)
  }

  const DeleteCurrent = () => {
    console.log("DeleteCurrent: ",showAnnouncement)
    // https://firebase.google.com/docs/firestore/manage-data/delete-data
    database.emails.doc(showAnnouncement.docID).delete().then(() => {
      console.log("Document successfully deleted!");
      setConversation()
      setSearchTerm('')
      getEmail()
      setShowAnnouncement()
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

  const getEmail = async (msgID) => {
    try {
      let announcementArr = []
      const emails = database.emails;
      const data = await emails.get();
      if(data.empty) {
        console.log("MsgCenter -> EMPTY: ");
      }
      else {
        console.log("MsgCenter -> FOUND for : ", data, msgID );
        let data_out = "";
        data.forEach(doc => {
          if( (doc.data().type == "ANNOUNCE") && (doc.data().show || currentUser.email === "gm@sixty01.com" ))
          {
            console.log("MsgCenter -> FOREACH: ", doc.id, doc.data().id);
            announcementArr.push({docID: doc.id, ...doc.data()})
            if( doc.data().id == msgID )
            {
              console.log("MsgCenter -> FOUND for : ", doc.data(), msgID );
              setFirst(doc.data())
              setShowAnnouncement(doc.data())
            }  
          }
        })
      }
      console.log("MsgCenter -> set: ", announcementArr);
      setAnnouncements(announcementArr)
      setFilteredAnnouncements(announcementArr)
    } catch (error) {
      console.log("MsgCenter -> ERROR: ");
    }
  }

  useEffect(() => {
    const parts = window.location.href.split('?')
    let msgID = ''
    if( parts.length > 1 )
    {
      console.log("INITIALIZING (msgID): ", window.location.href, parts[1])
      setReferMsgId(parts[1])
      msgID = parts[1]

    }
    else
    {
      console.log("MsgCenter - INITIALIZING (msgID NOT PRESENT: ", window.location.href)
    }
    window.history.pushState("messagecenter", "Title", "/messagecenter");
    ////////////////////////////
    const emails = database.emails;
    const unSub_Announcements = emails.onSnapshot(snapshot => { 
      console.log("SNAPSHOT FIRED: ");
      getEmail(msgID)
    })
    // Let's get user account filled
    database.users.where("email", "==", currentUser.email).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log("MsgCenter - PULLED USER ACCT: ", doc.data())
            setUserGUID(doc.id)
            setUserAcct(doc.data())
          });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    return function cleanup() {
      unSub_Announcements()
    };
  }, [])

  const setConversation = (key) => {
    console.log("MsgCenter - setting conversation: ", key)
    setSearchTerm('')
    getEmail()
    if( key > -1 )
      setShowAnnouncement(filteredAnnouncements[key])
    else
    {
      console.log("FOUND MUNIS KEY: ", key)
      setSearchTerm('')
      getEmail()
      setShowAnnouncement()
    }
  }

  useEffect(() => {
    let tgtArr = announcements.filter(ann => 
    {
      console.log("MsgCenter Filtering - Handling: ", ann.subject, ann.body)
      return(
        ( ann.subject.toLowerCase().indexOf(searchTerm) !== -1 ||
          ann.body.toLowerCase().indexOf(searchTerm) !== -1 )
      )
    })
    setFilteredAnnouncements(tgtArr)    
  }, [searchTerm])

  return (
    <div id="msg-center">
      <Navbar title='Message Center' />
      <Container fluid>
        <div className="d-flex align-items-center">
            <h2>Welcome to the Message Center</h2> <hr/>
{/*             <PeopleGallery/>
 */}        </div>

          <div id="messenger-panel" className="messenger-panel-show">
            <div className="scrollable sidebar">
              <ConversationList announcements={filteredAnnouncements} 
                                currConversation={showAnnouncement}
                                setConversation={setConversation} 
                                setSearchTerm={setSearchTerm}
                                deleteCurrent={DeleteCurrent}
//                                clearCurrent={ClearCurrent}
                                />
            </div>
            <div className="scrollable content">
              <MessageList dbRef={dbRef} showAnnouncement={showAnnouncement} user={userAcct} userGUID={userGUID}/>
            </div>
          </div>

      </Container>

    </div>
  )
}
