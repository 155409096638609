import React, { useRef, useState, useEffect } from "react"
import ReactDOM from "react-dom"
import DragDropFiles from './DragDropFiles'

import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { database } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import { storage } from "../../firebase"

import axios from 'axios'
import fileDownload from 'js-file-download'


export default function FileSharePublic() {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState("no file")
  const [note, setNote] = useState("")
  const { currentUser } = useAuth()
  const [loading,setLoading] = useState(true)

  const [files, setFiles] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [openText, setOpenText] = useState("Select from list above")
  const [selectionPresent,setSelectionPresent] = useState(false)
 // const inputRef = useRef(null);

  const [selection, setSelection] = useState([])
  const [image, setImage] = useState(0)
  const [url, setUrl] = useState(0)
  const [urlFileName, setUrlFileName] = useState("")

  useEffect(() => {
    console.log("loading files from Public Share -> ")
    if( loading || files.length == 0)
    {
      //      const fileRef = storage.ref(`sys_public_share`)
//      const fileList = fileRef.child(`${currentUser.email}`)
      const fileList = storage.ref(`sys_public_share`);
      let tmpFiles = []
      let tmpNames = []
      fileList.listAll().then((res) => {
        res.items.forEach((itemRef, index) => {
          console.log("loading files from Public share -> 1",itemRef)
          console.log("loading files from Public share -> 2",itemRef.name)
          tmpFiles.push(itemRef.name)
          tmpNames.push(itemRef.name)
        })
      })
      setFiles(tmpFiles)
      setFileNames(tmpNames)
      setLoading(false)
    }
  }, [loading])

  async function handleSetImage(i) {
    console.log("1 handleSetImage: ",i, fileNames[i])
    setUrlFileName(fileNames[i])
    const urlTmp = await storage.ref(`sys_public_share`)
        .child(`${fileNames[i]}`)
        .getDownloadURL()
    setUrl(urlTmp)
    setSelectionPresent(true)
//    inputRef.current.disabled = false
    setOpenText(`Open ${fileNames[i]}`)
    console.log("2 handleSetImage: ",urlTmp)
  }

  const handleDrop = (files) => {
    if( files )
    {
      // Single upload
      console.log("Uploading: ",files, files[0].name)
      setFileName(files[0].name)
      setFile(files[0])
    }
  }

  function openModal() {
    setOpen(true)
    setFileName('no file')
//    inputRef.current.disabled = true
    setFile()
  }

  function closeModal() {
    setOpen(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if( file && fileName.length > 0 ) 
    {
      console.log("Uploading file/note: ",file, fileName, note, currentUser)

      await storage
        .ref(`sys_public_share/${fileName}`)
        .put(file)
      const urlTmp = await storage.ref(`sys_public_share`)
                              .child(`${fileName}`)
                              .getDownloadURL()
      console.log("Uploaded and setting image url: ", urlTmp, currentUser.email )

      // Set Notification
      const ts = Date.now()
      const newNotification = {
        fileName: fileName,
        event: "file-share-public", 
        from: currentUser.email, 
        note: note,
        linkURL: urlTmp,
        ts: ts
      }
      database.notifications.add(newNotification)
    }
    setFileName("no file")
    setLoading(true)
    closeModal()
  }

  function handleDownloadFile() {
    window.open(url);
//    axios.get(url, {
//      responseType: 'blob',
//    })
//    .then((res) => {
//      fileDownload(res.data, urlFileName)
//    })
  }

  return (
    <>
      <Button title="This shows the content shared Publically for Community" 
        onClick={openModal} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
        <span>Public Share</span>
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Here you can access files made publically available</Form.Label>
              <hr/>

              { currentUser.email == "gm@sixty01.com" && 
                <div>
                  <label class="custom-file-upload">
                    <input type="file" onChange={(e) => 
                      { handleDrop(e.target.files) }}/>
                      <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                        Choose file
                  </label>
                  {fileName}
                  <Button variant="success" type="submit" className="btn float-right btn-sm ml-3">
                    Upload File
                  </Button>
                </div>
              }
              <hr/>
              <Form.Label>Files currently in the shared folder: </Form.Label>
              <div style={{display: "flex"}}>
                <DragDropFiles handleDrop={handleDrop} filecount={fileNames.length}>
                  <div style={{overflowY: "scroll", height: 110, width: 'auto', marginLeft: "20px"}}>
{/*                     {fileNames.map((file,i) => <div key={i} onClick={handleSetImage(i) ---- This breaks the rendering  }>{file}</div>)}
 */}                    {fileNames.map((file,i) => <div style={{cursor: "pointer"}} key={i} onClick={()=>handleSetImage(i)}>{file}</div>)}
                  </div>
                </DragDropFiles>
{/*                 <div>
                  <img src={url} width="150px" alt="Preview Panel (Sixty01)"/>
                </div>
 */}              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>

{/*           <button onClick={() => {this.handleDownload('https://your-website.com/your-image.jpg', 'test-download.jpg')
}}>Download Image</button>
 */}

          </Modal.Footer>
          <div style={{ display: "flex", justifyContent: 'space-between' }}>
            <Button disabled={!selectionPresent}
                    variant="success" 
                    style={{ marginLeft: "1em", marginBottom: "1em" }}
                    onClick={handleDownloadFile}>
              {openText}
            </Button>
            <Button variant="secondary"
                                style={{ marginRight: "1em", marginBottom: "1em" }}
                                onClick={closeModal}>
              Close
            </Button>
        </div>
        </Form>
      </Modal>
    </>
  )
}
