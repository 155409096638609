import React, {useState, useEffect} from 'react';
import ConversationSearch from './ConversationSearch/ConversationSearch';
import ConversationListItem from './ConversationListItem/ConversationListItem';
import Toolbar from '../Toolbar/Toolbar';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import axios from 'axios';
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import { motion } from 'framer-motion';
import { useAuth } from "../../../contexts/AuthContext"

import './ConversationList.css';
import $ from 'jquery';

const img2 = "https://picsum.photos/80/40"


export default function ConversationList({announcements, currConversation, setConversation, setSearchTerm, deleteCurrent}) {
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState()
  const [iframe, setIframe] = useState(false)
  const { currentUser } = useAuth()
  const [dispBody, setDispBody] = useState("")

  const [typeIsImage, setTypeIsImage] = useState(false)

//  const [conversation, setConversation] = useState()

/* window.onload=function(){
  document.querySelector('.xclose').addEventListener('click', function() {
    console.log('close');
  })
}
 */

  useEffect(() => {
    if(currConversation && currConversation.body.length > 0)
    {
      const str = $(currConversation.body).text();
      setDispBody(str)
      ///
      var div = document.createElement('div');
      div.setAttribute("id", "preview-node")
      div.innerHTML = currConversation.body.trim();
      document.getElementById("preview").appendChild(div)
    }
    if( currConversation && currConversation.attachments > 0 && currConversation.attachURL[0] )
    {
      const ext = currConversation.attachName[0].split(".")
      if( ext.length > 1 && ( ext[1] == "png" || ext[1] == "jpg" ) )
      {
        setTypeIsImage( true )
        setSelected(currConversation.attachURL[0])
      }
    }
  }, [currConversation])

  const HandleSetSelected = (k, url) => {
    console.log("1   ConversationList - HandleSetSelected: ", currConversation.attachName[k], k)
    const ext = currConversation.attachName[k].split(".")
    if( ext.length > 1 && ( ext[1] == "png" || ext[1] == "jpg" ) )
    {
      setTypeIsImage( true )
      setSelected(url)
    }
    else // open link in new window
    {
      window.open(url);
    }
  }

  const HandleClose = () => {
    console.log("ConversationList - HandleClose: ", currConversation)
    setConversation(-1)
    setSelected()
    setSearchTerm('')
  }
  const HandleDelete = () => {
    console.log("ConversationList - HandleDelete: ", currConversation)
    deleteCurrent()
  }
  const UpdateSearchTerm = (term) => {
    console.log("ConversationList - UpdateSearchTerm: ", term)
    setSearchTerm(term)
  }

  return (
    <div className="conversation-list">
      <Toolbar
        title="Announcements by GM"
        leftItems={[
          <ToolbarButton key="cog" icon="ion-ios-cog" />
        ]}
        rightItems={[
          <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
        ]}
        ClearConversation={setConversation}
      />
 
      { currConversation? 
        (
          <div class="wrapper">
            <Card style={{ width: '98%' }}>
              <Button title="Close Card" 
                onClick={HandleClose} variant="outline-success" 
                size="sm" className="btn btn-sm mb-1 mr-2">
                <span>close</span>
              </Button>  
              <Card.Body>
                <Card.Title>{currConversation.subject}</Card.Title>
                <div id="preview">
                </div>

                { currentUser.email === "gm@sixty01.com" &&
                  <Button title="Delete Announcement" 
                    onClick={HandleDelete} variant="outline-success" 
                    size="sm" className="xdelete btn btn-sm mb-1 mr-2">
                    <span>delete</span>
                  </Button>  
                }

                <hr/> 
                <Card.Text>
                  {currConversation.attachURL &&
                  <>
                    <ul>
                      <span>Attachments</span>
                      { currConversation.attachURL.map((url, k) => (
/*                           <li style={{marginLeft: "15px"}} onClick={ () => HandleSetSelected(url)}>
 */                          <li style={{marginLeft: "15px"}} >
                            <a href="#" onClick={ () => HandleSetSelected(k, url)}>{currConversation.attachName[k]}
                            </a></li>
                        ))}
                    </ul>

                    { typeIsImage && 
                      <img src={selected} width="290" height="auto" alt="bar"/> }
                  </>
                  }
                </Card.Text>
{/*                 <Button variant="primary">Go somewhere</Button>
 */}          

              </Card.Body>
            </Card>  

{/*
             <motion.div className="backdrop" 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <motion.img src={img2} alt="enlarged pic" 
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
              />
            </motion.div>
 */}
          </div>
        ):(
          <>
            <ConversationSearch UpdateSearchTerm={UpdateSearchTerm} />
            {
              announcements.map(( announcement, index ) =>
                <ConversationListItem
                  setConversation={setConversation}
                  index={index}
                  data={announcement}
                />
              )
            }
          </>
        )}
    </div>
 );
}