import React, { useState } from "react"
import { Button, Modal, Form } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"

import EventCalendarApp from "./evCalendarApp"

import "./evCalendarModal.css"

export default function EvCalendarModal() {
  const [open, setOpen] = useState(false)
  const { currentUser } = useAuth()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    closeModal()
  }

  return (
    <>
      {currentUser && 
        <div className="ml-5">
          <Button onClick={openModal} variant="outline-success" size="sm">
            <h4>Event Calendar</h4>
          </Button>
        </div>}
      <Modal size="lg" show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label><h3>View event details by clicking on date</h3></Form.Label>
            </Form.Group>
            <EventCalendarApp/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
