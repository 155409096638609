import React from "react";

//import UploadPost from "./Comps/UploadPost"

export default class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      show: true,
      scrollPos: 0,
      scrollPos1: 0
    };
  }
  componentDidMount() {
    document.getElementById("gid-container").addEventListener("scroll", this.handleScroll);
//    document.getElementById("gid-content").addEventListener("scroll", this.handleScroll);
//    document.getElementById("root").addEventListener("scroll", this.handleScroll);
//    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("click", e => {
      const isDropdownButton = e.target.matches("[data-dropdown-button]")
      if (!isDropdownButton && e.target.closest('[data-dropdown]') != null) 
      {
        console.log("1 .clicked and handler called");
        return
      }

      let currentDropdown
      if (isDropdownButton) {
        console.log("2 .clicked and handler called");
        currentDropdown = e.target.closest('[data-dropdown]')
        currentDropdown.classList.toggle('active')
      }

      document.querySelectorAll("[data-dropdown].active").forEach(dropdown => {
        console.log("3 .clicked and handler called");
        if (dropdown === currentDropdown) return
        dropdown.classList.remove('active')
      })

      console.log("0 -> .clicked and handler called");

    });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
//    console.log("called");
//    console.log(document.getElementById("gid-container").parentElement.getBoundingClientRect().top);
    this.setState({

      scrollPos: document.getElementById("gid-container").getBoundingClientRect().top,
      scrollPos1: document.getElementById("gid-content").getBoundingClientRect().top,
//      show: document.body.getBoundingClientRect().top > this.state.scrollPos
      show: document.getElementById("gid-content").getBoundingClientRect().top > (this.state.scrollPos1 - 11)
    });
  };
  render() {
    console.log(this.state);
    return (
      <>
        <nav className={this.state.show ? "active" : "hidden"} id="nav-header">
          <a href="http://www.sixty01.com" className="link">Home</a>
          <div className="dropdown" data-dropdown>
            <button className="link" data-dropdown-button>Information</button>
            <div className="dropdown-menu information-grid">
              <div>
                <div className="dropdown-heading">Section A</div>
                <div className="dropdown-links">
                  <a href="#" className="link">1 All</a>
                  <a href="#" className="link">2 All</a>
                  <a href="#pg-four" className="link">Page Four</a>
                </div>
              </div>
              <div>
                <div className="dropdown-heading">Section B</div>
                <div className="dropdown-links">
                  <a href="#" className="link">1 Bll</a>
                  <a href="#" className="link">2 Bll</a>
                  <a href="#" className="link">3 Bll</a>
                </div>
              </div>
              <div>
                <div className="dropdown-heading">Section C</div>
                <div className="dropdown-links">
                  <a href="#" className="link">1 Cll</a>
                  <a href="#" className="link">2 Cll</a>
                  <a href="#" className="link">3 Cll</a>
                </div>
              </div>
              <div>
                <div className="dropdown-heading">Section D</div>
                <div className="dropdown-links">
                  <a href="#" className="link">1 Dll</a>
                  <a href="#" className="link">2 Dll</a>
                  <a href="#" className="link">3 Dll</a>
                </div>
              </div>
            </div>
          </div>        
{/*           <a href="#" className="link">Pricing</a>
 */}          
          <button className="link" data-dropdown-button>Login</button>
{/*           <UploadPost/>
 */}  
       </nav>;

{/*         <div className={this.state.show ? "active" : "hidden"}>dividivi r</div>;
 */}
{/*         <div className="nav-header">
          <div classNAme="dropdown">
            <button class="link">Information</button>
            <div clasName="dropdown-menu">
              Dropdown Content
            </div>
          </div>
          <a href="#" class="link">Pricing</a>
          <button class="link">Login</button>
        </div>
 */}      </>
    )
  }
}











/* import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "react-router-dom"

import './style.css'; 

export default function NavbarComponent(props) {

  const titlestring = props.title;

  console.log({titlestring});

  return (
    <Navbar bg="light" expanded="sm">
      <Navbar.Brand as={Link} to="/">
         {titlestring}
      </Navbar.Brand>
      <div class="header">
        <button class="link">Site Map</button>
        <a href="#" class="link">Pricing</a>
        <button class="link">Login</button>
      </div>
      <Nav>
        <Nav.Link as={Link} to="/contact">
          Contact
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}
 */