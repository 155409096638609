import React, { useState, useEffect } from "react"

import { Button, Modal, Form } from "react-bootstrap"
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import firedb from "../../firebase" 
import { database } from "../../firebase"

import { useAuth } from "../../contexts/AuthContext"

const dbComponentName = "0_vendors"
const mCollectionActions = database.sys_actions // FireStore Database

export default function CallForAction({dataSelection}) {   
  const { currentUser } = useAuth()
  const [open, setOpen] = useState(false)
  const [actionList, setActionList] = useState([])
  const [actionIndex, setActionIndex] = useState(-1)
  const [currActionName, setCurrActionName] = useState("")
  const [actionName, setActionName] = useState("")
  const [actionSubject, setActionSubject] = useState("")
  const [actionBody, setActionBody] = useState("")
  const [actionEmail, setActionEmail] = useState("")

  const [emailList, setEmailList] = useState([])

  function setAction(index, value) {
    setActionIndex(index);
    if( actionList[index].action_name.length > 0 )
    {
      setCurrActionName(value);
      setActionName(value);
      setActionSubject(actionList[index].action_subject);
      setActionBody(actionList[index].action_body);
    }
  }

  function openModal() {
    setOpen(true)
/*     if( dataSelection.length > 0 )
    {
      let tmpArr = []
      dataSelection.forEach((item, index) => {
        Object.values(item).forEach((value, index) => {
          if( (typeof value) == 'string' )
          {
            const parts = value.split('@')
            if( parts.length == 2 && parts[1].includes(".") && !(value.indexOf(' ') >= 0) )
            {
              EnableEmailForUser(value)
              tmpArr.push(value)
              console.log("======> Found Email: ", value)
            }
          }
        })
      })
      if( tmpArr.length > 0 )
      {
        setActionEmail(tmpArr.join(","));
        setEmailList(tmpArr)
        console.log("======> SETTING EMAIL LIST: ", tmpArr)
      }
    }
 */  }

  function closeModal() {
    setActionIndex(-1);
    setActionName("");
    setActionSubject("");
    setActionBody("");
    setActionEmail("");
    setOpen(false)
  }
  function setName(value) {
    setActionName(value)
  }
  function setSubject(value) {
    setActionSubject(value)
  }
  function setBody(value) {
    setActionBody(value)
  }
  function setEmail(value) {
    setActionEmail(value)
  }

  function handleSubmit(e) {
    e.preventDefault()
    // Let's explain the sequence here
    SavingAction()
    // EnableEmailForUSer checks for array emailList to be == 1 and then adds the user
    SubmitEmail()
    closeModal()
  }


{/* <h2> Welcome to Sixty01,</h2><p>You are invited to register your email by opening the link below.</p><br/>https://sixty01.allhavenhomes.com/contact?REGISTRATION <br/><br/> Once a registered user, you will be able to login and follow announcements at the Message Center below. <br/><br/>
 */}

  function EnableEmailForUser(emailIn) {
    // If we submit a CFA (call for action) we assume the emails should be enabled in users collection so we test here
    const mCollectionUsers = database.users // FireStore Database
    if( emailIn.toLowerCase().indexOf("@") >= 0 ) // input is a valid email - Better check needed
    {
      mCollectionUsers.where('email', '==', emailIn).get().then(function (querySnapshot) {
        let bInsert = true
        querySnapshot.forEach(function (doc) {
            console.log(doc.id, ' Found exisitng user =========> ', doc.data())
            bInsert = false
        })
        if( bInsert )
        {
          console.log(' New User being stored in DB=========> ')
          const newUser = {
            group: ["PUBLIC"],
            origin: "",
            profileURL: "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/sys_profile_pictures%2Fgm%40sixty01.com%2Fprofileimage?alt=media&token=bb959e6b-b2b8-43c9-9ed9-1cfd6dc05ad2",
            name: "no name",
            addr: "no addr",
            email: emailIn, 
            valid: '1',
            ts: Date.now()
          }
          database.users.add(newUser)
        }
      })
    }
  }

  useEffect(() => {
    console.log("INITIALIZING-CAll for action: ")
    mCollectionActions.onSnapshot(snapshot => {
//      setReady(false)
      let tmpActions = []
      snapshot.docs.map( doc => {
//        const reqObj = { actionSubject: doc.data().name_text, email: doc.data().email_text, msg:doc.data().body_text }
        const actionObj = { ...doc.data() }
//        const obj = { id: doc.id, ...doc.data() }
//        if( doc.data().subject_text == "REGISTRATION" ) {  // Idea of using 'useReduce' to bucket the 'bodies' for each 'subject'
//          console.log("mCollectionRegReq: FOUND REGISTRATION REQUEST: ",obj, reqObj)
//          tmpArr.push(obj)
          tmpActions.push(actionObj)
//        }
/*           console.log("FOUND REGISTRATION REQUEST: ",obj)
          if( profImg && profImg.length > 0 )
            tmpListImageUrls.push(doc.data().profileURL)
          else
            tmpListImageUrls.push(noperson)
 */ 
      })
      setActionList(tmpActions)
//      setReady(true)
      console.log("mCollectionActions: Completed: ",tmpActions)
    })

  }, [])

  function SavingAction() {
    const ts = Date.now()
    const newAction = {
      action_name: actionName,
      action_subject: actionSubject,
      action_body: actionBody,
      ts: ts
    }
    if( !(actionName==currActionName) )
      database.sys_actions.add(newAction)
  }

  function SubmitEmail1() {
    const emailArr = actionEmail.split(",")
    console.log("Submit Email () looking at: ",actionEmail, emailList, emailArr)
    const testemails = ["frontdesk@sixty01.com","jari@havenrealestateservices.com", ...emailArr]
    console.log("Submit Email () resulting email array: ",testemails)
  }

  function SubmitEmail() {
    const def_subject = "Default"
    const def_body = 'Default'
    const test_subject = "Your Email has been enabled for Signup"
    const test_body = '<h2> Dear Sixty01 Resident,</h2><p>Your email has been enabled for Signup with the Sixty01 Online Community.</p><p>Please visit link below to create your account:<br/> <a href="http://sixty01.allhavenhomes.com/signup">Signup here</a> <br/> Regard, the staff at Sixty01</br></br> You can follow future Community Announcements in the Message Center below. <br/><br/>'
//    const onAddNewEmail = async (subject, body, name_text, email_text, subject_text, body_text) => {
//    const testemails = ["frontdesk@sixty01.com","jari@havenrealestateservices.com","mortensenviggo0@gmail.com"]
//    const testemails = ["frontdesk@sixty01.com","jari@havenrealestateservices.com", ...emailList]
    // if actionEmail string contains @ - we assume a valid - optionally comma seperated sring of emails - BETTER test needed
    const emailArr = actionEmail.split(",")
    console.log("Submit Email () looking at: ",actionEmail, emailList, emailArr)
    const testemails = ["frontdesk@sixty01.com","jari@havenrealestateservices.com", ...emailArr]
    console.log("Submit Email () resulting email array: ",testemails)

    const emailRef = firedb.database().ref(`sys_emails_contact`);
    var newRef = emailRef.push(); // this does *not* call the server
    var newKey = ""
    newKey = newRef.key;  // Perhps only key as a property
    const ts = Date.now()

    const tgtSubject = actionSubject
    const tgtBody = actionBody

    const newObj = {
        id: newKey,
        attachments: 0,
        name_text: "",
        email_text: "",
        subject_text: "",
        body_text: "",
        subject: tgtSubject,
/*         body: '<h2> Dear Sixty01 Resident,</h2><p>Your email has been enabled for Signup with the Sixty01 Online Community.</p><br/>Please visit link below to create your account by signing up: https://sixty01.allhavenhomes.com/signup <br/><br/> You can follow future Community Announcements in the Message Center below. <br/><br/>',
 */        body: tgtBody,
        state: "SEND", 
        to: testemails,
        ts: ts
      }
//      inputRef.current.disabled = false
    database.emails.add(newObj)
    
    .then(() => {
        const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => console.log('This is your data', data));
      })
  }
  
  return (
    <>
      <Button onClick={openModal} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
        <span>Call for action</span>
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Send a 'Call for action' by email</Form.Label>
            </Form.Group>

            <MuiThemeProvider>
              <Form.Label>Action</Form.Label>
              <SelectField
                style={{marginLeft: "50px"}}
                value={actionName}
                floatingLabelText="Select a pre-defined Action"
                onChange={(event, index, value) =>
                  setAction(index, value)
                }>
                { actionList.length > 0 && 
                  actionList.map(iterObj => ( 
                    <MenuItem value={iterObj.action_name} primaryText={iterObj.action_name}  /> 
                  )) 
                }
                <MenuItem value="test3" primaryText="test4"  />
              </SelectField>

              <Form.Control
                type="text"
                placeholder="(opt) Select pre-defined OR enter new (ONE WORD IN CAPS)"
                
                value={actionName}
                onChange={e => setName(e.target.value)}
              />
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Write here the Subject line"
                required
                value={actionSubject}
                onChange={e => setSubject(e.target.value)}
              />
              <Form.Label>Body</Form.Label>
              <Form.Control
                type="text"
                placeholder="Write here the Body text"
                required
                value={actionBody}
                onChange={e => setBody(e.target.value)}
              />

              <Form.Label>Enter Email of Recipient</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here a comma-separated list of emails"
                value={actionEmail}
                onChange={e => setEmail(e.target.value)}
              />

            </MuiThemeProvider>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
