import React from 'react';
import { useState, useEffect } from "react"

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import './Compose.css';

export default function Compose({SendReply}) {

  const [ reply, setReply ] = useState("")

  const handleReply = (e) => {
    console.log("Handle Reply: ",e.target.value)
    setReply(e.target.value)
  }

  const submitReply = () => {
    console.log("Submitting Reply: ",reply)
    if( reply.length > 0 )
      SendReply(reply)
    document.getElementById('msginput').value='';
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitReply()
    }
  }

  return (
    <div className="compose">
      <Tooltip title="Write Message">
        <IconButton aria-label="edit"
          onClick={() => submitReply()}>
{/*           <EditIcon className="mr-2"/>
 */}          Send
        </IconButton>
      </Tooltip>
      <input id="msginput" style={{paddingLeft: "15px"}}
        type="text"
        className="compose-input"
        onChange={(e) => handleReply(e)}
        onKeyDown={(e) => _handleKeyDown(e)}
        placeholder="Type a message"
      />
    </div>
  );
}