import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import { database } from "../../../firebase" 
import { faExpand, faFile, faLock, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './evCalendarModal.css'; 

const mCollectionEvents = database.events // FireStore Database

export const NewEventModal = ({onClose, date}) => {
  const { currentUser, logout } = useAuth()
  const [ready, setReady] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [eventsArr, setEventsArr] = useState([])  
  const [currEventIndex, setCurrEventIndex] = useState(-1)

  const [newEvent, setNewEvent] = useState(false);

  const [error, setError] = useState(false);

  useEffect(() => {
    console.log("Events INIT", date )
    /////////////////////////////////////////////////
    const unSub_Events = mCollectionEvents.onSnapshot(snapshot => {
      setReady(false)
      let tmpArr = []
      snapshot.docs.map( doc => {
        console.log("f14: mCollectionEvents: Iteration: ", doc.data().date, date.date)
        if( date.date == doc.data().date )
        {
          const tmpObj = { guid: doc.id, ...doc.data() }
          tmpArr.push(tmpObj)  
        }
      })
      if( tmpArr.length > 0 )
      {
        setCurrEventIndex(0)
        setTitle( tmpArr[0].title )
        setDescription( tmpArr[0].description )
      }
      setEventsArr(tmpArr)
      console.log("mCollectionEvents: Completed: ",tmpArr)
    })
    /////////////////////////////////////////////////////
    const timer = setTimeout( () => {
      console.log("CLEARING LOADING SIGNALS ON TIMEOUT!") }
                                                                , 5000)
    return function cleanup() {
      unSub_Events()
      clearTimeout(timer)
    };
  }, [])

  const onNewEvent = () => 
  {
    console.log("On New Event: ")
    setTitle("")
    setDescription("")
    setNewEvent(true)
  }
  const onDelEvent = () => 
  {
    console.log("On Del Event: ", eventsArr[currEventIndex].ts)
    var ts_query = mCollectionEvents.where('ts','==',eventsArr[currEventIndex].ts);
    ts_query.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        doc.ref.delete();
      });
    });
  }

  const onSaveEvent = () => 
  {
    console.log("On Save Event: ", title, description)
    const ts = Date.now()
    setNewEvent(false)
    const newEvent = {
      title: title, 
      description: description,
      date: date.date,
      ts: ts
    }
    database.events.add(newEvent)
  }

  const HandleSetSelected = (index) =>
  {
    console.log("f14: HandleSetSelected: ", index)
    setTitle(eventsArr[index].title)
    setDescription(eventsArr[index].description)
    setCurrEventIndex(index)
    setNewEvent(false)
  }

  return(
    <>
      <div id="newEventModal">
        <h2>Events for: {date.date}</h2>
        <hr/>
        { eventsArr.length > 0 ? 
          (
            <div>
              { eventsArr.map(
                  ( ev, k ) => 
                  <li key={ev.guid} style={{marginLeft: "15px"}} >
                                <a href="#" onClick={ () => HandleSetSelected(k)}>{ev.title}
                                </a></li>
              )}
            </div>  
          ):(
            <div>
              <span> No Events Scheduled</span>
            </div>
          )
        }
        
        <hr/>

        { (eventsArr.length > 0 || newEvent ) &&
          <> 
            <input 
              className={error ? 'error' : ''}
              value={title} 
              onChange={e => setTitle(e.target.value)} 
              id="eventTitleInput" 
              placeholder="Title of New Event" 
            />
            <input 
              className={error ? 'error' : ''}
              value={description} 
              onChange={e => setDescription(e.target.value)} 
              id="eventTitleInput" 
              placeholder="Description of Event" 
            />
          </>
        }

        { currentUser && currentUser.email == "gm@sixty01.com" && !newEvent &&
            <Button 
              onClick={() => {
                onNewEvent();
              }} 
              id="newEventButton">New
            </Button>
        }
        { currentUser && currentUser.email == "gm@sixty01.com" && !newEvent &&
            <Button
              variant="secondary"
              onClick={() => {
                onDelEvent();
              }} 
              id="delEventButton">Delete
            </Button>
        }

        { currentUser && currentUser.email == "gm@sixty01.com" && newEvent &&
            <Button 
              variant="success"
              onClick={() => {
                if (title) {
                  setError(false);
                  onSaveEvent();
                } else {
                  setError(true);
                }
              }} 
              id="saveEventButton">Create
            </Button>
        }

        { currentUser && currentUser.email == "gm@sixty01.com" && newEvent &&
            <Button 
              variant="secondary"
              onClick={() => {
                setTitle(eventsArr[0].title)
                setDescription(eventsArr[0].description)
                setCurrEventIndex(0)
                setNewEvent(false)
              }} 
              id="cancelCreateButton">Cancel
            </Button>
        }

        <Button 
          variant="secondary"
          onClick={onClose}
          id="cancelButton">
            Close
        </Button>
      </div>

      <div id="modalBackDrop"></div>
    </>
  );
};
