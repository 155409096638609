import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from "./CenteredContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faGlobe } from "@fortawesome/free-solid-svg-icons"
import { storage, database } from "./../../firebase"

const profileimage = "profileimage"

export default function UpdateProfile() {
  const siteRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [image,setImage] = useState("https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/sys_profile_pictures%2Fgm%40sixty01.com%2Fprofileimage?alt=media&token=82a6a569-7620-4f9d-9ee9-f8a8b653ff6e")

  useEffect(() => {
    storage.ref(`sys_profile_pictures/${currentUser.email}`)
        .child(`${profileimage}`)
        .getDownloadURL().then(function(url) {
          console.log("Uploaded and setting image url: ", url)
          setImage(url)
          return url;
        }).catch(function(error) {
          // Handle any errors here
        });
  }, [currentUser])

  const UpdatePublicSite = async(site) => {
    console.log("Uploading new public site:", site)
    const snap = await database.users.where("email", "==", currentUser.email).get()
    snap.forEach((doc) => {
      const tgtObj = { publicSite: site, ...doc.data() }
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data(), " => ", site, " => ", tgtObj);
      database.users.doc(doc.id).update( { publicSite: site } );
    });
  }

  const handleDrop = async(files) => {
    if( files )
    {
      for (var i = 0; i < files.length; i++) {
        if (!files[i].name) return
        let urlTmp = ''
        console.log("Uploading: ",files[i].name, "for user email: ", currentUser.email)
        await storage
          .ref(`sys_profile_pictures/${currentUser.email}/${profileimage}`)
          .put(files[i])
        urlTmp = await storage.ref(`sys_profile_pictures/${currentUser.email}`)
                                .child(`${profileimage}`)
                                .getDownloadURL()
        console.log("Uploaded and setting image url: ", urlTmp, currentUser.email )
        setImage(urlTmp)
        // Update profileURL in user object -- https://firebase.google.com/docs/firestore/query-data/get-data
        const snap = await database.users.where("email", "==", currentUser.email).get()
        snap.forEach((doc) => {
          const tgtObj = { profileURL: urlTmp, ...doc.data() }
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data(), " => ", urlTmp, " => ", tgtObj);
          database.users.doc(doc.id).update( { profileURL: urlTmp } );
        });
/*           .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data());
                  database.users.doc(doc.id).set({
                    profileURL: urlTmp, ...doc.data() });
            });
          })
 */ 
/*          .catch((error) => {
              console.log("Error getting documents: ", error);
          });
 */      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    UpdatePublicSite(siteRef.current.value)

    Promise.all(promises)
      .then(() => {
        history.push("/user")
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
    <CenteredContainer>
      <h2 className="text-center mb-4">Update Profile</h2>
      <Card>
        <Card.Body>
          <div class="d-flex flex-row">
            <div className="viewmg">
              <img id="prof-rcorners-img" src={image} width="13vw" height="auto" alt="bar"/>
            </div>
            <div class="d-flex flex-column">
              <label class="custom-file-upload">
                <input type="file" 
                  onChange={(e) => 
                  { handleDrop(e.target.files) }} />
                  <FontAwesomeIcon icon={faDownload} className="mr-2 fa-lg" /> 
                    Click to replace profile picture
              </label>
{/*               <label className="mt-3 custom-file-upload">
                <input type="file" 
                  onChange={(e) => 
                  { handleDrop(e.target.files) }} />
                  <FontAwesomeIcon icon={faGlobe} className="mr-2 fa-lg" /> 
                    Replace Public Site
              </label>
 */}            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
            <div class="d-flex flex-row">
              <Form.Label>Youtube Video</Form.Label>
                <Form.Control
                  type="url"
                  ref={siteRef}
                  required
                  placeholder="Paste a link to your YT video"
                />
              </div>
              <div class="d-flex flex-row">
                <Form.Label>Acct Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  required
                  defaultValue={currentUser.email}
                />
              </div>
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <div class="d-flex flex-row">
              <div className="w-100 text-center mt-2">
                <Link to="/user">Cancel</Link>
              </div>
              <Button disabled={loading} className="w-30" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </CenteredContainer>
    </>
  )
}
