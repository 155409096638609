import React, { useState, useEffect } from "react"
import HomePage from "./web-pages/HomePage"
import GeneralInfo from "./web-pages/GeneralInfo/GeneralInfo"
import Amenities from "./web-pages/Amenities"
import Contact from "./Contact/Contact"
import HiddenAdmin from "./web-pages/HiddenAdmin/HiddenAdmin"
import CarouselContainer from "./Carousel/CarouselContainer"
import Units from "./Units/Units"
import Townhall from "./Townhall/Townhall"
import Tickets from "./Tickets/Tickets"
import MsgCenter from "./MessageCenter/MsgCenter"

import Signup from "./authentication/Signup"
import { AuthProvider, useAuth } from "../contexts/AuthContext"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import MyTickets from "./MyTickets/MyTickets"
import Profile from "./authentication/Profile"
import Login from "./authentication/Login"
import PrivateRoute from "./authentication/PrivateRoute"
import ForgotPassword from "./authentication/ForgotPassword"
import UpdateProfile from "./authentication/UpdateProfile"
import Dashboard from "./google-drive/Dashboard"


import AdminJK from "./AdminJK/AdminJK"


function App() {

//  const { currentUser, logout } = useAuth()
  const [loggedInName, setLoggedInName] = useState(null);

/*   useEffect(() => {
    if( currentUser !== null )
      loggedInName = currentUser.email
  }, [currentUser])
 */

  return (
    <Router>
      <AuthProvider>
        <Switch>
          {/* Drive */}
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/folder/:folderId" component={Dashboard} />

          {/* Profile */}
{/*           <PrivateRoute path="/townhall" component={Townhall} />
 */}          <PrivateRoute path="/mytickets" component={MyTickets} />
          <PrivateRoute path="/user" component={Profile} />
          <PrivateRoute path="/update-profile" component={UpdateProfile} />

          {/* Management */}
          <PrivateRoute path="/hiddenadmin" component={HiddenAdmin} />
          <PrivateRoute path="/tickets" component={Tickets} />
          <PrivateRoute path="/units" component={Units} />
          <PrivateRoute path="/messagecenter" component={MsgCenter} />
          <PrivateRoute path="/adminjk" component={AdminJK} />

          {/* Auth */}
          <Route
            exact path="/"
            component={() => <HomePage loggedInName={loggedInName} setLoggedInName={setLoggedInName}/>}
          />
          <Route
            path="/homepage"
            component={() => <HomePage loggedInName={loggedInName} setLoggedInName={setLoggedInName}/>}
          />
          <Route path="/generalinfo" component={GeneralInfo} />
          <Route path="/townhall" component={Townhall} />
          <Route path="/amenities" component={Amenities} />
          <Route path="/carousel" component={CarouselContainer} />
          <Route path="/signup" component={Signup} />
          <Route
            path='/login'
            component={() => <Login loggedInName={loggedInName} setLoggedInName={setLoggedInName}/>}
          />
{/*           <Route path="/login" component={Login} />
 */}          <Route path="/forgot-password" component={ForgotPassword} />
           <Route path="/contact" component={Contact} />

        </Switch>
      </AuthProvider>
    </Router>
  )
}

export default App
