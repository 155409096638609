import React, { useState, useEffect } from "react"

import { Button, Modal, Form } from "react-bootstrap"
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import firedb from "../../../firebase" 

import { useAuth } from "../../../contexts/AuthContext"

const dbComponentName = "0_vendors"

export default function AssignTicketButton({HandleAssign}) {   // Copied from RequesTicketButton
  // All this UI does is list the vendors in a drop down and call HAndleAssign with the choosen Vendor
  const { currentUser } = useAuth()
  const [open, setOpen] = useState(false)
  const [vendorList, setVendorList] = useState([])
  const [vendorIndex, setVendorIndex] = useState()
  const [vendorEmail, setVendorEmail] = useState("")

  function setVendorAssign(index, value) {
    setVendorIndex(index);
    setVendorEmail(value);
  }

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function handleSubmit(e) {
    const iName = vendorList[vendorIndex].obj.userName;
    const iCompany = vendorList[vendorIndex].obj.userCompany;
    const iEmail = vendorList[vendorIndex].obj.userEmail;
    const iUid = vendorList[vendorIndex].obj.uid;
    console.log("handleSubmit for name: ", iName, iCompany, iEmail, iUid)
    HandleAssign(iName, iCompany, iEmail, iUid)
    
    e.preventDefault()
    closeModal()
  }

  useEffect(() => {
    const dbReference = firedb.database().ref(`${dbComponentName}`);
    dbReference.on('value', (snapshot) => {
      const dbs = snapshot.val();
      let tmpList = [];
      for (let id in dbs) {
        console.log("INITIALIZING-ITERATION: ",dbs[id])
        tmpList.push({ key: id, obj: dbs[id]})
      }
      console.log("INITIALIZING-REPORT: ",tmpList)
      setVendorList(tmpList)
    });
/*     dbReference.on('value', items =>{
      items.forEach((child) => {
        console.log("VendorAssignment Initializing: ",child.key);
        console.log("VendorAssignment Initializing VALUE: ",child.val());
        myVendorArr.push(child.val())
      })
      setVendorList(myVendorArr)
      console.log("VendorAssignment BUILD THIS LIST ",myVendorArr);
    })
 */
  }, [])

//  { vendorList.map(iterObj => ( <MenuItem value={iterObj.obj.email} primaryText={iterObj.obj.email}  /> )) }
//{ vendorList.length > 0 && vendorList.map(iterObj => ( <MenuItem value={iterObj.obj.userEmail} primaryText={iterObj.obj.userEmail}  /> )) }

  return (
    <>
      <Button onClick={openModal} variant="outline-success" size="sm" className="btn btn-sm mb-1 ml-5">
        <span>Assign Ticket to Vendor</span>
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Assigning ticket to a Vendor - Select from Dropdown</Form.Label>
            </Form.Group>

            <MuiThemeProvider>
              <SelectField
                value={vendorEmail}
                floatingLabelText="Select a Vendor"
                onChange={(event, index, value) =>
                  setVendorAssign(index, value)
                }
                >
                { vendorList.length > 0 && vendorList.map(iterObj => ( <MenuItem value={iterObj.obj.userEmail} primaryText={iterObj.obj.userEmail}  /> )) }
                <MenuItem value="test3" primaryText="test4"  />
              </SelectField>
            </MuiThemeProvider>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Assign
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
