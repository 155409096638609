import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from "./CenteredContainer"
import { database } from "../../firebase" 

export default function Login({loggedInName, setLoggedInName}) {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, currentUser } = useAuth()
  const [user, setUser] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [errorMsg, setErrorMsg] = useState("")

  let componentMounted = true; // (3) component is mounted

  function logLogins(email, uid) {
    const ts = Date.now()
    const newUser = {
      email: email, 
      uid: uid,
      event: "LOGIN",
      ts: ts
    }
    database.signups.add(newUser)
  }

  useEffect(() => {
    if( currentUser && currentUser.email.length > 0 )
    {
      const email = currentUser.email
      const uid = currentUser.uid
      logLogins(email, uid)
    }
    if( currentUser && loggedInName && loggedInName.length > 0 && user.length > 0 )
    {
      if( user == "DELETE")
      {
        currentUser.delete()
      }
      history.push("/")
    }
  }, [user, currentUser])

  useEffect(() => {
    console.log("Login USEEFFECT: ", loggedInName);

    if( loggedInName && loggedInName.length > 0 && !(loggedInName=="none") )
    {
      database.users.where("email", "==", loggedInName)
      .get()
      .then((querySnapshot) => {
          if( querySnapshot.empty) 
          {
            console.log("User is not present: ");
            setErrorMsg(": User with that email was not found")
            if (componentMounted) // (5) is component still mounted?
              setUser("DELETE")
          }
          else
          {
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log("VALIDATED: ",doc.id, " => ", doc.data());
              if (componentMounted) // (5) is component still mounted?
                setUser(loggedInName)
            });
          }
      })
      .catch((error) => {
          console.log("Error getting documents for USER: ", error);
      });
    }
    return () => { // This code runs when component is unmounted
      componentMounted = false; // (4) set it to false if we leave the page
    }
  }, [loggedInName])

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      const email_login = emailRef.current.value.toLowerCase()
      setLoggedInName(email_login)
      setLoading(true)
      await login(email_login, passwordRef.current.value)
//      history.push("/")
    } catch {
      setLoggedInName("Failed to log in - check password")
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <Link to="/homepage">
            Home Page {loggedInName}
          </Link>
          
          <h2 className="text-center mb-4">Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required placeholder="Enter a valid email address"/>
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Log In
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </CenteredContainer>
  )
}
