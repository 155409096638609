import React from "react"
import { useReducer, useState, useEffect, useRef } from "react"
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Navbar from "./../../contexts/Navbar"
import { storage, database } from "../../firebase"
import firedb from "../../firebase" 

//import { useAuth } from "../../contexts/AuthContext"
import './Contact.css';
//import './css/style.css';

const containerStyle2 = {
  width: "100%",
  height: "100%"
};
  
const center = {
  lat: 47.662300,
  lng: -122.153490
};

const formReducer = (state, event) => {
  if(event.reset) {
   return {
     role: '',
     count: 1234,
     name: '',
     email: '',
     subject: '',
     message: '',
     'valid': true,
   }
 }
  return {
    ...state,
    [event.name]: event.value
  }
}


/* const parts = window.location.href.split('?')
let msgID = ''
if( parts.length > 1 )
{
  console.log("INITIALIZING (msgID): ", window.location.href, parts[1])
  setReferMsgId(parts[1])
  msgID = parts[1]

}
 */


//const matches = useMediaQuery('(min-width:600px)');

export default function Contact({input}) {
  const [ first, setFirst ] = useState("FIRST NAME")

  const [ initSubject, setInitSubject ] = useState("")
  const inputRef = useRef(null);
  //  const [formData, setFormData] = useReducer(formReducer, {});

  const [formData, setFormData] = useReducer(formReducer, {
    count: 100,
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
  
    if( formData.name && formData.name.length > 0 &&
        formData.email && formData.email.length > 0 &&
        formData.subject && formData.subject.length > 0 &&
        formData.message && formData.message.length > 0 ){
      setSubmitting(true);
      console.log("Submitting: ", formData )
  //      if( formData.subject.length > 0 && formData.email.length > 0 && formData.name.length > 0 ){
      const subjectIn = "Contact Query: "+formData.subject+". From: "+formData.name
      const bodyIn = formData.message+"\n -replyTo: "+formData.email
      onAddNewEmail(subjectIn, bodyIn, formData.name, formData.email, formData.subject, formData.message)
      setTimeout(() => {
        setSubmitting(false);
        setFormData({
        reset: true
      })
      }, 3000);        
    }
    else
    {
      setSubmitError(true)
      setTimeout(() => {
        setSubmitError(false);
      }, 3000);        
    }

  }

/////  https://www.digitalocean.com/community/tutorials/how-to-build-forms-in-react
/*   const handleSubmit = event => {
    event.preventDefault();
    alert('You have submitted the form.')
  }
 */
  const handleChange = event => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.name,
      email: event.target.email,
      subject: event.target.subject,
      message: event.target.message,
      value: isCheckbox ? event.target.checked : event.target.value,
    })
    setInitSubject(event.target.subject)
  }

  const onClick = (e) => {
    console.log("onClick: ",e.target.value)
  }

  useEffect(() => {
    console.log("INITIALIZING CONTACT: ")
    const parts = window.location.href.split('?')
    let msgID = ''
    if( parts.length > 1 )
    {
      // https://stackoverflow.com/questions/56110558/how-to-disable-and-enable-a-text-field-during-a-reactjs-event/56110825
      inputRef.current.disabled = true
      setInitSubject("- loading -") // this hack ensures rendering of the next set value
      formData.subject = parts[1]
      console.log("INITIALIZING CONTACT GOT MESSAGE(preset message): ", parts[1])
    }
  }, [])

  const onAddNewEmail = async (subject, body, name_text, email_text, subject_text, body_text) => {
    const testemails = ["frontdesk@sixty01.com","jari@havenrealestateservices.com","mortensenviggo0@gmail.com"]
    const emailRef = firedb.database().ref(`sys_emails_contact`);
    var newRef = emailRef.push(); // this does *not* call the server
    var newKey = ""
    newKey = newRef.key;  // Perhps only key as a property
    const ts = Date.now()
    const newContact = {
      name_text: name_text,
      email_text: email_text,
      subject_text: subject_text,
      body_text: body_text,
      ts: ts
    }
    database.contactus.add(newContact)
    const newObj = {
      id: newKey,
      attachments: 0,
      name_text: name_text,
      email_text: email_text,
      subject_text: subject_text,
      body_text: body_text,
      subject: subject,
      body: body,
      state: "SEND", 
      to: testemails,
      ts: ts
    }
    inputRef.current.disabled = false
    database.emails.add(newObj).then(() => {
        const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => console.log('This is your data', data));
      })
  }

  const styleErrorMsg = {
    color: 'red',
  };

  return (
    <div id="contact">
      <Navbar title='Contact' />

      <section class="ftco-section">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="wrapper">
                <div class="row no-gutters mb-5">
                  <div class="col-md-7">
                    <div class="contact-wrap w-100 p-md-5 p-4">
                      <h3 class="mb-4">Contact Us</h3>
                      <form onSubmit={handleSubmit}>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label" for="name">Full Name</label>
                              <input type="text" class="form-control" name="name" id="name" 
                                 value={formData.name || ''} onChange={handleChange} placeholder="Name"/>
                            </div>
                          </div>
                          <div class="col-md-6"> 
                            <div class="form-group">
                              <label class="label" for="email">Email Address</label>
                              <input type="email" class="form-control" name="email" id="email" 
                                 value={formData.email || ''} onChange={handleChange} placeholder="Email"/>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="label" for="subject">Subject</label>
                              <input ref={inputRef} type="text" class="form-control" name="subject" id="subject" 
                                 value={formData.subject || initSubject || ''} onChange={handleChange} placeholder="Subject"/>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="label" for="#">Message</label>
                              <textarea name="message" class="form-control" id="message" 
                                value={formData.message || ''} onChange={handleChange} cols="30" rows="4" placeholder="Message"></textarea>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="d-flex">
                                <button type="submit" disabled={submitting} class="btn btn-primary">Submit</button>
                                { submitting &&
                                  <span className="ml-3">Submitting Form...</span>
                                }
                                { submitError &&
                                  <span className="ml-3" style={styleErrorMsg}>Missing fields...</span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-5">
{/*                     <div id="map" class="contact-wrap h-100 p-md-5 p-4" >
 */}                    <div id="map" class="h-100">
                      <LoadScript
                        googleMapsApiKey="AIzaSyD6RWL1FjOHMCbjG_exRpFQaeXV0wIDkwo"
                      >
                        <GoogleMap
                          mapContainerStyle={containerStyle2}
                          center={center}
                          zoom={15}
                        >
                          <></>
                        </GoogleMap>
                      </LoadScript>      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="dbox w-100 text-center">
                      <div class="icon d-flex align-items-center justify-content-center">
                        <span class="fa fa-map-marker"></span>
                      </div>
                      <div class="textk">
                        <p><span>6001 140th Ave NE, Redmond WA 98052</span></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="dbox w-100 text-center">
                      <div class="icon d-flex align-items-center justify-content-center">
                        <span class="fa fa-phone"></span>
                      </div>
                      <div class="textk">
                        <p><span>(425)883-6001</span></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="dbox w-100 text-center">
                      <div class="icon d-flex align-items-center justify-content-center">
                        <span class="fa fa-paper-plane"></span>
                      </div>
                      <div class="textk">
                        <p><a href="mailto:frontdesk@sixty01.com">frontdesk@sixty01.com</a></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="dbox w-100 text-center">
                      <div class="icon d-flex align-items-center justify-content-center">
                        <span class="fa fa-globe"></span>
                      </div>
                      <div class="texxt">
                        <p><a href="https://sixty01.allhavenhomes.com">www.sixty01.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr/>
    
    </div>
  )
}

