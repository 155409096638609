import React, { useState, useEffect } from "react"
import { InputGroup, FormControl, ToggleButton, Button, ButtonToolbar, Checkbox, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
//import { Card, Button, Alert } from "react-bootstrap"
//import { useAuth } from "../../contexts/AuthContext"
//import { Link, useHistory } from "react-router-dom"
import { Link, useHistory } from "react-router-dom"
//import CenteredContainer from "./CenteredContainer"
import facebookIcon from '../../icons/facebook.png'
/* import closeIcon from '../icons/close.png'
 */import instagramIcon from '../../icons/instagram.png'
/* import menuIcon from '../icons/menu.png'
 */import twitterIcon from '../../icons/twitter.png'
import '../../stylesheets/HomePage.css'

import sixty01logo from './../../icons/sixty.png';


export default function HomePage({loggedInName, setLoggedInName}) {

  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  const [userToken, setUserToken] = useState("")

   useEffect(() => {
    if( currentUser )
    {
      const wbToken = currentUser.email.replace('@', 'at').replace('.', 'at');
      const wbUser = `https://wb.allhavenhomes.com/boards/6sd96hzHhgacJfFBrfrMCe640${wbToken}`
      setUserToken(wbUser)
      setLoggedInName(currentUser.email)
    }
    else
      setLoggedInName(null)    
  }, [currentUser])

  console.log(currentUser)

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/")
      window.location.reload()
      setLoggedInName(null)
    } catch {
      setError("Failed to log out")
    }
  }

  // swans on lake const videoSrc = "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/files%2FHyAtyd6Cpmg870mqxZ9FkGXD8T33%2FImages%2F20210803_135951.mp4?alt=media&token=ce192897-031b-440c-ac78-9153fbfbcc50"
//  const videoSrc = "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/PUBLIC%2F20210902_112132.mp4?alt=media&token=23812551-8814-4291-b377-3a1bd6512c48"
//  const videoSrc = "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/PUBLIC%2Fwelcomevideo1.mp4?alt=media&token=a3a641b1-9a9d-4cd2-bbd1-222755c8ac74"

  const videoSrc = "https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/PUBLIC%2FFinalFourVid0002-0498.mp4?alt=media&token=8e1dda46-f77f-43d9-bfca-7bfc0b8c7489"

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
    console.log(isActive ? "First" : "Second");

    setTimeout(() => {
      if( isActive )
        setActive(isActive);
      }, 60000);        
  };

  return (
    <>
    <section className={isActive ? "showcase" : "showcase active"}>
        <header>
          <h2 className="logo">Sixty01</h2>
{/*           <div onClick={e => console.log("Clicked")} class="toggle"></div>
 */}
          <div onClick={handleToggle} className={isActive ? "toggle" : "toggle active"}></div>
        </header>

        <video src={videoSrc} width='100%' height="auto" muted loop autoPlay playsInline></video>
        <div className="overlay"></div>
        <div className="text">

          <h2>Explore Living</h2> 
          <h3>In the Center of the Eastside</h3>
          <p>Centrally located on the Eastside in Redmond, Washington, Sixty01 Association provies a spacious, invitingly lush, quiet retreat from nearby urban life.  Our private 85 acres contain recreational facilities, walking areas and three lakes near flats in mid-rises and townhomes.</p>
          <p>With a variety of sizes, finding the right home with the amenities you want is easy! Come check in with our administrative staff, meet some friendly residents, and enjoy a relaxed, peaceful and convenient lifestyle close to shopping, dining, entertainment, employers and local, regional & state parks, and waterways. Welcome to the best place to live on the Eastside!</p>
{/*           <a href="#">Explore</a>
 */}        </div>
 
        <ul className="social">
          <li><a href="#"><img src={facebookIcon} width="50" height="50"></img></a></li>
          <li><a href="#"><img src={instagramIcon} width="50" height="50"></img></a></li>
          <li><a href="#"><img src={twitterIcon} width="50" height="50"></img></a></li>
        </ul>
      </section>

      <div className="menu">
        <ul>


        { !loggedInName &&
          <a href="https://sixty01.managebuilding.com/Resident/portal/login" style={{color:"#fff",textTransform:"uppercase",textDecoration:"none",background:"#60a3bc",marginBottom:"40px",padding:"20px",borderRadius:"50px"}}>
              Manage Account</a>   
        } <br/><br/><br/>

        { !loggedInName &&
          <>
          <hr/>
            <li><a href="https://www.homewisedocs.com/">Resale Certificates</a></li>
          <hr/>
          </>
        }


          { loggedInName && <li style={{fontSize:'25px'}}>Welcome</li> }
          { loggedInName && <li>{loggedInName}</li> }
          { loggedInName && <li><Button variant="secondary" size="sm" onClick={handleLogout}>Log Out</Button></li> }
          { loggedInName && <li><hr/></li> }
{/*           { !loggedInName && <li><Link to="/login">Log In</Link></li> }
 */}
{/*           <li><a onClick={handleLogin} href="/login">Log In</a></li>
 */} 
{/*           <li><a href="#">(News)</a></li>
 */}

          { loggedInName && loggedInName.indexOf("sixty01.com") !== -1 &&
              <li><Link to="/generalinfo">General Info</Link></li>
          }

          <li><Link to="/amenities">Amenities</Link></li>
{/*           <li><a href="#">(Sales/Rentals)</a></li>
          <li><a href="#">(For Realtors)</a></li>
 */}
 {/*           <li><Link to="/carousel">Carousel</Link></li>
 */}      
          <li><Link to="/messagecenter">Message Center</Link></li>

{/*           <li><Link to="/dashboard">Documents</Link></li>

           { loggedInName && 
            <li><Link to="/townhall">Townhall</Link></li> }
 */}
 
{/* <li><a href="https://meet.jit.si/hres123">TESTIT</a></li>
 */}

{/*           { loggedInName && 
          <li><Link to="/mytickets">My Tickets</Link></li> }
 */}

{/*           { loggedInName ? (
              <li><a href={userToken}>My Whiteboard</a></li>
            ):(
              <li><a href="https://wb.allhavenhomes.com/boards/anonymous">Public Whiteboard</a></li>
            )}
 */}            

          <li><Link to="/contact">Contact</Link></li>
          { loggedInName &&
            <>
              <hr/>
              <li><a href="https://www.homewisedocs.com/">Resale Certificates</a></li>
            </>
          }

          { loggedInName && loggedInName.indexOf("sixty01.com") !== -1 &&
          <>  <li><Link to="/tickets">Manage Tickets</Link></li> 
              <li><Link to="/dashboard">Documents</Link></li>
          </>
          }

          { loggedInName && 
            ( loggedInName.indexOf("sixty01.com") !== -1 ||
              loggedInName.indexOf("jk@jkwebapps.com") !== -1 ) &&
          <li><Link to="/hiddenadmin">Configure Amenities</Link></li> }
 {/*          { loggedInName && loggedInName.indexOf("admin@sixty01.com") !== -1 &&
          <li><a href="https://wb.allhavenhomes.com/boards/6sd96hzHhgacJfFBrfrMCe640vmqctkGvzkYuMY3Lx8-">Admin Whiteboard</a></li> }
          { loggedInName && loggedInName.indexOf("gm@sixty01.com") !== -1 &&
          <li><a href="https://wb.allhavenhomes.com/boards/Je1iHgq8ONnmAXphB3ryt4WM39NEGShv--IpkMCiV6E-">GM Whiteboard</a></li> }
  */}  
         { loggedInName && loggedInName.indexOf("gm@sixty01.com") !== -1 &&
          <li><Link to="/units">Import Data Units</Link></li> }

          { loggedInName && 
            ( loggedInName.indexOf("gm@sixty01.com") !== -1 ||
              loggedInName.indexOf("jk@jkwebapps.com") !== -1 ) &&
          <li><Link to="/adminjk">System Admin</Link></li> }

          <li>
            <hr/>
            <img src={sixty01logo} alt="Girl in a jacket" width="190" height="190"></img>
          </li>
        </ul>
      </div>

    </>
/*     <div className="w-100 text-center mt-2">
      <h2 className="text-center mb-4">HomePage</h2>
      <Link to="/dashboard" className="btn btn-primary w-100 mt-3">
          FileSystem
      </Link>
    </div>
 */    /*     <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>Email:</strong> {currentUser.email}
          <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
            Update Profile
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </CenteredContainer>
 */  )
}
