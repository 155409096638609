import React from "react"
import Navbar from "../../google-drive/Navbar"
import './HiddenAdmin.css'; 

import ManageAmenities from './ManageAmenities';
import ManageUsers from './ManageUsers';

export default function HiddenAdmin() {

  return (
    <>
      <Navbar title='Amenity Management' />
      <ManageAmenities/>
{/*       <ManageUsers/>
 */}    </>
  )
}