import React from "react"
import { Container } from "react-bootstrap"
import { useFolder } from "../../hooks/useFolder"
import { useState, useEffect } from "react"
import AddFolderButton from "./AddFolderButton"
import AddFileButton from "./AddFileButton"
import Folder from "./Folder"
import File from "./File"
import Navbar from "./Navbar"
import ICVpage from "./ImageCollectionViewer/ICVpage"
import Modal from './ImageCollectionViewer/Modal';

import FolderBreadcrumbs from "./FolderBreadcrumbs"
import { useParams, useLocation } from "react-router-dom"
import { faExpand, faFile, faLockOpen, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { faTimesCircle as fasCircle } from '@fortawesome/fontawesome-free-solid'
import { faCircle as farCircle } from '@fortawesome/fontawesome-free-regular'
import { InputGroup, FormControl, ToggleButton, Button, ButtonToolbar, Checkbox, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap"

import { faExpand as fasCircle } from "@fortawesome/free-solid-svg-icons"

import { database } from "../../firebase"
import './Dashboard.css'; 

const SelImgViewer = ({ filled, onClick }) => {

  return (
    <div onClick={onClick} >
      <FontAwesomeIcon icon={filled ? farCircle : fasCircle}/>
    </div>
  );
};

export default function Dashboard() {

  const [ filler, setFiller ] = useState(false)
//  state = { filled: false };
  const handleFiller = () => {
//    this.setState({ filled: !this.state.filled });
    setFiller(!filler);
  };

  const [ checked, setChecked ] = useState(true)
  const [ fileUrl, setFileUrl ] = useState("https://cdn2.vectorstock.com/i/1000x1000/48/06/image-preview-icon-picture-placeholder-vector-31284806.jpg")
  const { folderId } = useParams()
  const { state = {} } = useLocation()
  const { folder, childFolders, childFiles } = useFolder(folderId, state.folder)

/*   https://stackoverflow.com/questions/26253351/correct-modification-of-state-arrays-in-react-js
 */  
  const [ viewer, setViewer] = useState("panel")
  const [ selection, setSelection] = useState([])

  const [selectedImg, setSelectedImg] = useState(null);

/*   const DownloadButton = props => {
 */
    const handleDownloadFile = () => {
      window.location.href = "https://yoursite.com/src/assets/files/exampleDoc.pdf"
    }
/*     return (
              <button onClick={downloadFile} />
    )
  }
  export default DownloadButton;
 */


  useEffect(() => {
    setSelection([])
//    alert(fileUrl)
  }, [folder])
  
  const handleImgViewer = () => {
    if( selection.length == 0 ) // we assume to open all
    {
      let tgtArr = [];
      childFiles.map(childFile => {
        tgtArr.push({
          id: tgtArr.length,
          value: childFile.id,
          url: childFile.url
        })
        console.log("Uncheck Element:")
        /*         const key = childFile.id
        console.log("Uncheck Element:")

        setSelection([...selection, {
          id: selection.length,
          value: file.id,
          url: file.url
        }])
 */      
      })
      setSelection(tgtArr)
    }
    console.log("Viewer:",{selection})
    setViewer("img-grid")
  }

  const handleClearSelection = () => {
    setViewer("panel")
    setSelection([])
    var x = document.getElementsByClassName("cbFiles");
    for(let i=0; i<=x.length; i++) {
      console.log("Uncheck Element:", i, x[i])
      if( x[i] )
        x[i].checked = false;
    }   
  }

  const handleDelete = () => {
    console.log("Testing:",{selection})
/*     childFiles.map(childFile => (
      console.log(childFile.id)
    ))
 */
    selection.map(item => (
/*       console.log(item.value)
 */      database.files.doc(item.value).delete()
    ))
    setSelection([])
  }

  const handleChange = (file,event) => {
    const selChecked = event.target.checked
    const iid = file.id
    console.log("Handling ID:",{selChecked},{iid})
    if( selChecked )
    {
      setSelection([...selection, {
        id: selection.length,
        value: file.id,
        url: file.url
      }])
      console.log("Added: ", {selection})
    }
    else
    {
      console.log("how to Deleting")
      const arr = selection.filter((item) => item.value !== iid)
      setSelection(arr)
      console.log("rep: ", {selection})
    }
  }

  const handleImgClick = (url_in) => {
    const arr = selection.filter((item) => item.url !== url_in)
    setSelection(arr)
    console.log("rep: ", {selection})
  }

  const alerting = () => {       
    setSelectedImg(fileUrl)
    console.log("SIMPLE REPORTING:",fileUrl)
  } 

  return (
    <>
      <Navbar title='Secure File Storage' />
      <Container fluid>
        <div className="d-flex align-items-center">
          <FolderBreadcrumbs currentFolder={folder} />
          <AddFileButton currentFolder={folder} />
          <AddFolderButton currentFolder={folder} />
        </div>
        {childFolders.length > 0 && (
          <div className="d-flex flex-wrap">
            {childFolders.map(childFolder => (
              <div
                key={childFolder.id}
                style={{ maxWidth: "250px" }}
                className="p-2"
              >
                <Folder folder={childFolder} setFileUrl={setFileUrl} />
              </div>
            ))}
          </div>
        )}
        {childFolders.length > 0 && childFiles.length > 0 && <hr />}

        <div id="filesheader" className="d-flex align-items-center d-inline-flex justify-content-between">
          <label class="checkboxtext">Files in Folder</label>
          <div id="filestools" className="d-flex align-items-center d-inline-flex justify-content-between">
            { selection.length > 0 && viewer==="panel" &&
              <a
                target="_blank"
                className="btn text-truncate w-100"
                    onClick={handleDelete}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="mb-2 fa-lg" />
              </a>
            }
{/*             { selection.length > 0 &&
              <a
                target="_blank"
                className="btn text-truncate w-100"
                    onClick={handleViewer}
              >
                <FontAwesomeIcon icon={faExpand} className="mb-2 fa-lg" />
              </a>
            }
            { selection.length > 0 &&
              <input  class="cbFiles" type="checkbox" checked={state.active} onClick={(event) => handleViewer(event)}></input>
            }
            <Button class="cbFiles" type="checkbox" checked={state.active} onClick={(event) => handleViewer(event)}>
              <FontAwesomeIcon icon={faExpand} className="mb-2 fa-lg" />
            </Button>
 */}
{/*             { selection.length > 0 &&
              <a>
                <SelImgViewer filled={filler} onClick={handleFiller} />
              </a>
            }
 */} 

            { viewer==="panel" &&
              <button title="Arranges your selection in a preview mode" type="button" className="btn btn-outline-success btn-sm mb-2 ml-2" 
                      onClick={handleImgViewer}>
                View in Grid     
              </button>
            }

{/*             { selection.length > 0 && viewer==="panel" &&
              <button title="Sends selection to folder shared with GM" type="button" className="btn btn-outline-success btn-sm mb-2 ml-1" 
                      onClick={handleImgViewer}>
                GM      
              </button>
            }
 */}

{/*             { selection.length > 0 && viewer==="panel" &&
              <a
                target="_blank"
                className="btn text-truncate w-100"
                    onClick={handleImgViewer}
              >
                <FontAwesomeIcon icon={faExpand} className="mb-1 fa-lg" />
              </a>
            }
 */}{/*             { selection.length > 0 && viewer==="panel" &&
              <a
                target="_blank"
                className="btn text-truncate w-100"
              >
                <DownloadFilesButton files={childFiles}/>
              </a>
            }
 */} 
            { selection.length > 0 && viewer==="img-grid" &&
              <button type="button" class="btn btn-outline-success btn-sm mb-2 ml-2"
                      onClick={handleImgViewer}
                      onClick={handleClearSelection}>
                Exit Grid Viever      
              </button>
            }
          </div>
        </div>

        <div className="d-md-flex h-md-100">
          <div className="col-md-4 p-0 bg-indigo h-md-100">
            <div id="filesListing">
              {childFiles.length > 0 && (
                <div className="d-flex flex-wrap flex-column">
                  {childFiles.map(childFile => (
                    <div key={childFile.id}
                      style={{ maxWidth: "450px" }}
                      className="p-2">
                        <div className="d-flex align-items-center">
{/*                           <input  type="checkbox" name="optiona" id="opta" checked={state.active} onChange={handleChange} />
 */}                         
                          <input  class="cbFiles" type="checkbox" id={childFile.id} checked={state.active} 
                                  onClick={(event) => handleChange(childFile, event)}>
                          </input>
                          <File file={childFile} fileUrl={fileUrl} setFileUrl={setFileUrl} />
                        </div>
                    </div>
                  ))} 
                </div>
              )}
            </div>
          </div>
          <div className="col-md-8 p-0 bg-white h-md-100">
              <div id="preview">
                { viewer == "panel" &&
                  <div id="inner_preview" onClick={alerting}>
                    <img src={fileUrl} 
                        style={{maxWidth: "100%", height: "auto" }} 
                        alt="FYI, image alt text is required" />
                  </div>
/*                   <iframe src={fileUrl} width="100%" height="800" frameBorder="120" margin="160"></iframe>
 */             }
                { viewer == "img-grid" &&
/*                   <h2>hello</h2>
 */                  <ICVpage selection={selection}/>
                }
              </div>
          </div>  
          { selectedImg && (
            <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
          )}          
        </div>
      </Container>
    </>
  )
}
