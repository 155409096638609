import React from 'react';
import { useState, useEffect } from "react"

import { Button, Modal, Form } from "react-bootstrap"
import { storage, database } from "../../../firebase"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons"

import firedb from "../../../firebase" 
import AddEmailButton from "./AddEmailButton"

import { useAuth } from "../../../contexts/AuthContext"

import './Toolbar.css';

export default function Toolbar(props) {
    const { title, leftItems, rightItems, ClearConversation } = props;
    const { currentUser } = useAuth()
    const [ panel, setPanel ] = useState(true)

    const handlePanelToggle = () => {
      console.log("handlePanelToggle: ",panel)
      if(panel)
      {
        document.getElementById("messenger-panel").classList.add('messenger-panel-hide');
        document.getElementById("messenger-panel").classList.remove('messenger-panel-show');
      }
      else{
        document.getElementById("messenger-panel").classList.add('messenger-panel-show');
        document.getElementById("messenger-panel").classList.remove('messenger-panel-hide');        
      }
      setPanel(!panel)
    }

    const handleChange = (e) => {
      console.log("handleChange: ",e.target.value)
    }
  
    const onAddNewEmail = async (subject, body, attachments, action) => {
      ClearConversation(-1)
      // Action is either POST or SEND - POST to store the announcement and SEND to mass email
      const testemails = ["jari@havenrealestateservices.com","mortensenviggo0@gmail.com","frontdesk@sixty01.com","hres.jkwebapps@gmail.com"]
      console.log("onAddNewEmail TOP - args: ",subject, body, attachments, action)
      // ONCE we have option to select groups to add to email
/*       console.log("onAddNewEmail TOP - values: ",selected, column, attachments)
      let emails = []
      selected.map((row, index) => { 
        const obj = dataArray[row]
        console.log("onAddNewEmail TOP - Iteration: ",row, index)
        Object.keys(obj).map(function(keyName, keyIndex) {
          if( keyName == column )  emails.push(obj[keyName])
        })
      })
 */      console.log("onAddNewEmail TOP - emails: ", testemails)
      ///////////////////////////
      const ts = Date.now()
      const emailRef = firedb.database().ref(`sys_emails_${action}`);
      var attachmentsPresent = 0
      var newRef = emailRef.push(); // this does *not* call the server
      var newKey = ""
      newKey = newRef.key;  // Perhps only key as a property
      if (attachments.length > 0) 
        attachmentsPresent = attachments.length
      const newObj = {
        id: newKey,
        attachments: attachmentsPresent,
        subject: subject,
        body: body,
        state: action, 
        to: testemails,
        ts: ts
      }
      if (attachments.length > 0) 
      { // Explain thi shack
        // https://stackoverflow.com/questions/64142108/react-js-image-uploder-code-with-firebase-cloud-storage-gives-error
        // with an attachment we first up;oad and then ge the URL and then set the email entry and then call the server
        let urlArray = []
        let nameArray = []
        const attachmentFirst = attachments[0]
        const attachmentNameFirst = attachments[0].name
        console.log("Uploading attachments - starting array: ",attachments, action)
        for (var i = 0; i < attachments.length; i++)
        {
          let urlTmp = ''
          console.log("Uploading: ", attachments[i].name)
          await storage
            .ref(`sys_attachments/${newKey}/${attachments[i].name}`)
            .put(attachments[i])
          urlTmp = await storage.ref(`sys_attachments/${newKey}`)
                                  .child(`${attachments[i].name}`)
                                  .getDownloadURL()
  
          console.log("Adding to arrays: ", urlTmp, attachments[i].name)
          urlArray.push(urlTmp)
          nameArray.push(attachments[i].name)
        }
        const newObj = {
          id: newKey,
          attachments: attachmentsPresent,
          attachName: nameArray,
          attachURL: urlArray,
          subject: subject,
          body: body,
          state: action, 
          to: testemails, // NEEDS to LIST all Users
          ts: ts
        }                              
        database.emails.add(newObj).then(() => {
/*           const apiUrl = `https://prev.allhavenhomes.com/mail/send/${newKey}`;
 */          const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
  /*                                     const apiUrl = `http://localhost:452/mail/send/${newKey}`;
   */        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => console.log('This is your data', data));                        
        })
      }
      else
      { // just push the new email and .then PING the server
        if( action == "POST" )
          database.emails.add(newObj)
        else
        {
          database.emails.add(newObj).then(() => {
/*             const apiUrl = `https://prev.allhavenhomes.com/mail/send/${newKey}`;
 */            const apiUrl = `https://prev.allhavenhomes.com/slabs/send/${newKey}`;
              fetch(apiUrl)
                .then((response) => response.json())
                .then((data) => console.log('This is your data', data));
            })
        }
      } 
    }
  
    return (
    <div className="d-flex">
      { !panel && leftItems &&
        <Button title="Press to expand" 
            onClick={handlePanelToggle} variant="light" size="sm" className="btn btn-sm mb-1 ml-1">
              <FontAwesomeIcon icon={faArrowsAltH} className="mr-2" />
        </Button> }

      { leftItems && 
        <Button title="Collaps and expand left panel" 
            onClick={handlePanelToggle} variant="light" size="sm" className="btn btn-sm mb-1 ml-1">
              <FontAwesomeIcon icon={faArrowsAltH} className="mr-2" />
        </Button> }

      <h1 className="toolbar-title">{ title }</h1>

{/*       { panel && leftItems && currentUser.email === "gm@sixty01.com" &&
        <AddEmailButton 
            onAddNewEmail={onAddNewEmail}/>
      }
 */}
    </div>
  );
}