import React, { useState, useEffect } from "react"
import { Form, Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from "./CenteredContainer"

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { storage, database } from "../../firebase"

import './Profile.css'

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const profileimage = "profileimage"

export default function Profile() {
  const [error, setError] = useState("")
  const [sectionName, setSectionName] = useState("")
  const [dataString, setDataString] = useState("")
  const { currentUser, logout } = useAuth()
  const [ displayUser, setDisplayUser] = useState("")
  const history = useHistory()

  const [site,setSite] = useState('https://maps.google.com/maps?q=seattle&t=&z=13&ie=UTF8&iwloc=&output=embed')
  const [image,setImage] = useState("https://firebasestorage.googleapis.com/v0/b/auth-react-production-6e632.appspot.com/o/sys_profile_pictures%2Fgm%40sixty01.com%2Fprofileimage?alt=media&token=82a6a569-7620-4f9d-9ee9-f8a8b653ff6e")

  useEffect(() => {
    const parts = window.location.href.split('?')
    let msgID = ''
    if( parts.length > 1 )
    {
      setDisplayUser(parts[1])
    }
    else
    {
      console.log("User profile - INITIALIZING (email NOT PRESENT: ", window.location.href)
    }
    window.history.pushState("profile", "Title", "/user");
  }, [])

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }
  function handlePreviewSection() {
    console.log("DataString->>: ",dataString)
    try{
      var exnode = document.getElementById("preview-node")
      document.getElementById("preview").removeChild(exnode)
    }
    catch{
      console.log("none")
    }
    if( dataString.length > 4 )
    {
      var div = document.createElement('div');
      div.setAttribute("id", "preview-node")
      div.innerHTML = dataString.trim();
      document.getElementById("preview").appendChild(div)
    }
  }
  function handleSetSectionName(value) {
    console.log("handleSetSectionName: ", value)
    setSectionName(value)
  }

  useEffect(() => {
    let tgtEmail = currentUser.email
    if( displayUser.length > 0 )
      tgtEmail = displayUser

    database.users.where("email", "==", tgtEmail).get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setSite(doc.data().publicSite)
      })
    })
    storage.ref(`sys_profile_pictures/${tgtEmail}`)
        .child(`${profileimage}`)
        .getDownloadURL().then(function(url) {
          console.log("Uploaded and setting image url: ", url)
          setImage(url)
          return url;
        }).catch(function(error) {
          // Handle any errors here
        });
  }, [currentUser, displayUser])


/* 
  arr_html = '<div id="view-img-'+iid+'" className="viewmg" data-aos="'+fade+'" data-aos-duration="2100">'
  arr_html += '<img id="viewimg" src="'+url+'"width="480vw" height="413vw" alt="bar" />'
  arr_html += '</div>'
 */
  return (
    <CenteredContainer>
      <div class="d-flex flex-row justify-content-center" style={{maxWidth: "83vw"}}>
        <div>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Profile</h2>
              <div className="viewmg">
                <img id="prof-rcorners-img" src={image} width="13vw" height="auto" alt="bar"/>
              </div>
{/*               {error && <Alert variant="danger">{error}</Alert>}
 */}         
              <strong>Email:</strong> {displayUser.length > 0 ? displayUser : currentUser.email} 
              {displayUser.length < 1 &&
                  <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
                    Update Profile
                  </Link>  
              }
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            <Link to="/homepage">
              Home Page
            </Link>
          </div>
        </div>
        <div>
{/*           <div id="map-container" className="z-depth-1-half map-container" style={{ height: "500px"}}>
 */}
          <h2 className="text-center mb-4">Your Favorite YouTube</h2>
          <div style={{ width: "500px" }}>
            <iframe src={site} frameborder="0"
              style={{ width: "600px", height: "420px", border:"0" }} allowfullscreen></iframe>
          </div>
        </div>
      </div>

      {currentUser.email == 'gm@sixty01.com' && 
        <div id="html-builder" className="html-builder">
          <h2>HTML Section Builder</h2>
          <CKEditor
              editor={ ClassicEditor }
              data="<p>Format sections here and press 'Name Section' btn below to review and store section.</p>"
              onReady={ editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log( 'Editor is ready to use!', editor );
              } }
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setDataString(data)
                  console.log(" JkREPORTING --->", { event, editor, data } );
              } }
              onBlur={ ( event, editor ) => {
                  console.log( 'Blur.', editor );
              } }
              onFocus={ ( event, editor ) => {
                  console.log( 'Focus.', editor );
              } }
          />
          <hr/>

          <form>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Raw html string</label>
              <textarea value={dataString}
                        class="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="3"></textarea>
            </div>
          </form>

          <Form.Label>(optional) Provide a name for storing the section</Form.Label>
          <Form.Control
            type="text"
            onChange={e => handleSetSectionName(e.target.value)}
          />

          <Button className="mt-5" variant="success" onClick={handlePreviewSection}>
            Preview Section
          </Button>
          <div id="preview">
          </div>
        </div>
      }

    </CenteredContainer>
  )
}
