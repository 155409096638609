import React from 'react';
import moment from 'moment';
import './Message.css';
import { Link, useHistory } from "react-router-dom"

export default function Message(props) {
    const {
      data,
      currentAuthor,
      isMine,
      url,
      startsSequence,
      endsSequence,
      showTimestamp
    } = props;
    const history = useHistory()

    const friendlyTimestamp = moment(data.timestamp).format('LLLL');

    const handleProfileImgClick = () => {       
      //    setSelectedImg(fileUrl)
          console.log("SIMPLE REPORTING:")
          const viewUser = `/user?${currentAuthor}`
          history.push(viewUser)
          window.location.reload()
    } 

    return (
      <div className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`
      ].join(' ')}>
        {
          showTimestamp &&
            <div className="timestamp">
              { friendlyTimestamp }
            </div>
        }

          <div className="bubble-container">
            <div onClick={() => handleProfileImgClick()}>
              { url &&
                <img id="img-profile" src={url} height="42" width="auto" alt="bar"/>
              }
            </div>
            <div className="bubble" title={friendlyTimestamp}>
              { data.message }
            </div>
          </div>
      </div>
    );
}