import React, {useState, useEffect} from 'react';
import MyTicketListingSearch from './MyTicketListingSearch/MyTicketListingSearch';
import MyTicketListingItem from './MyTicketListingItem/MyTicketListingItem';
import AssignTicketButton from './AssignTicketButton';

import Toolbar from '../Toolbar/Toolbar';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import axios from 'axios';
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import { motion } from 'framer-motion';
import { useAuth } from "../../../contexts/AuthContext"
import firedb from "../../../firebase" 
import { storage } from "../../../firebase"


import './MyTicketListing.css';

const img2 = "https://picsum.photos/80/40"

const dbComponentName = "0_tickets"


export default function MyTicketListing({tickets, currTicket, setTicket, setSearchTerm}) {
//  export default function MyTicketListing({announcements, currConversation, setConversation, setSearchTerm, deleteCurrent}) {
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState()
  const { currentUser } = useAuth()

  const [attachedNames, setAttachedNames] = useState([])
  const [attachedItemRefs, setAttachedItemRefs] = useState([])
  const [selectedItemRef, setSelectedItemRef] = useState()
  const [currUrl, setCurrUrl] = useState("")

/* window.onload=function(){
  document.querySelector('.xclose').addEventListener('click', function() {
    console.log('close');
  })
}
 */

  const LoadUrlFromRef = ( item_ref ) => {
    item_ref.getDownloadURL().then(url => {
//      console.log("item_ref.getDownloadURL: ", url)
      setCurrUrl(url)
    })
  }

  useEffect(() => {
//    console.log("item_ref.getDownloadURL: ", selectedItemRef)
    if( selectedItemRef )
      LoadUrlFromRef(selectedItemRef)
  }, [selectedItemRef])

  const GetAttachments = async( id ) => {
    let arrNames = [];
    let arrItemRefs = [];
    const imgRef = storage.ref(`tickets`)
    const imgList = imgRef.child(`${id}`)
    const list = await imgList.listAll()
    list.items.forEach( (item) => {
      arrNames.push(item.name);
      arrItemRefs.push(item)
      console.log("item ref - url: ", item.name)
    })
    if( arrNames.length > 0 )
    {
      setAttachedNames(arrNames)
      setAttachedItemRefs(arrItemRefs)
      setSelectedItemRef(arrItemRefs[0])
    }
  }

  useEffect(() => {
    if( currTicket != null )
    {
      console.log("Getting FILES location FROM CurrTicket", currTicket.files)
      // Validated id in files  - if bucket exists we wull create the two arrays
      if( currTicket.files.includes("https") )
        setCurrUrl(currTicket.files)
      else
        GetAttachments(currTicket.files)
    }
  }, [currTicket])


  const HandleAssign = ( name, company, email, uid ) => {
    const dbReference = firedb.database().ref(`${dbComponentName}`);
    const ref_key = currTicket.ref_key;
    if( currTicket.state == "DISABLED" || currTicket.state == "ENABLED" ) /// this implies it has been assigned "ASSIGNED" )
    {
      console.log("MyTicketListing - HandleAssignTicket of ASSIGNED: ", currTicket)
      dbReference.child(ref_key).update({ name: name })
      dbReference.child(ref_key).update({ company: company })
      dbReference.child(ref_key).update({ email: email })
      dbReference.child(ref_key).update({ uid: uid })
      setTicket(-1)
    }
    else
    {
      // Handling assigning a request by filing on behalf of the assignee
      console.log("MyTicketListing - HandleAssignTicket of REQUEST: ", currTicket)
      const value = currTicket.subject;
      const desc = currTicket.body;
      const ts = Date.now()
      var d = new Date(ts);
      const sDate = d.toString().substr(4, 11);      
      const newObj = {
        state: "SHOW",
        body: desc,
        company: company,
        email: email,
        files: ref_key,
        group: "HOA-REQUEST",
        name: name,
        priority: "-1",
        ref_key: ref_key,
        reply_cnt: "0",
        subject: value,
        tag: "na",
        timestamp: sDate,
        uid: uid
      }
      const assignedTicketID = dbReference.push(newObj).key;
      // Update the exsitng REQUEST ticket currTicket - ASSIGNED state and ref key to assignedTicketID
      dbReference.child(ref_key).update({ ref_key: assignedTicketID })
      dbReference.child(ref_key).update({ state: "DISABLED" }) // Newly assigned request is set to DISABLED (DEFAULT) communication
     }
  }

  const HandleClose = () => {
    console.log("MyTIcketListing - HandleClose: ", currTicket)
    setAttachedNames([])
    setCurrUrl("")
    setTicket(-1)
    setSearchTerm('')
  }
  const UpdateSearchTerm = (term) => {
    console.log("MyTIcketListing - UpdateSearchTerm: ", term)
    setSearchTerm(term)
  }
  const HandleSetSelected = (k) => {
    setSelectedItemRef(attachedItemRefs[k])
  }

  return (
    <div className="conversation-list">
      <Toolbar
        title="Ticket Listing"
        leftItems={[
          <ToolbarButton key="cog" icon="ion-ios-cog" />
        ]}
        rightItems={[
          <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
        ]}
        ClearConversation={setTicket}
      />
 
      { currTicket ? 
        (
          <div className="wrapper">
            <Card style={{ width: '98%' }}>
              <Card.Body>
                <Card.Title>{currTicket.body}</Card.Title>
                <Card.Text>
                  {currTicket.body}
                </Card.Text>

                { currentUser.email === "gm@sixty01.com" &&
                  <AssignTicketButton HandleAssign={HandleAssign}/>

/*                   <Button title="Assign this ticket to a Vendor/Staff" 
                    onClick={HandleAssign} variant="outline-success" 
                    size="sm" className="xassign btn btn-sm mb-1 mr-2">
                    <span>assign</span>
                  </Button>  
 */                }

                <hr/> 
{/*                 <Card.Text>
 */}            { attachedNames.length > 1 &&
                  <>
                    <ul>
                      <span>Attachments</span>
                      { attachedNames.map((name, k) => (
                          <li style={{marginLeft: "15px"}} onClick={ () => HandleSetSelected(k)}>
                            <a href="#" onClick={ () => HandleSetSelected(k)}>{attachedNames[k]}
                            </a></li>
                        ))}
                    </ul>
                  </>
                }
                { currUrl.length > 0  && <img src={currUrl} width="290" height="auto" alt="bar"/>}
{/*                 </Card.Text>
 */}

{/*                 <Button variant="primary">Go somewhere</Button>
 */}          

              </Card.Body>
            </Card>  
            <Button title="Close Card" 
              onClick={HandleClose} variant="outline-success" 
              size="sm" className="xclose btn btn-sm mb-1 mr-2">
              <span>close</span>
            </Button>  
{/*
             <motion.div className="backdrop" 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <motion.img src={img2} alt="enlarged pic" 
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
              />
            </motion.div>
 */}
          </div>
        ):(
          <>
            <MyTicketListingSearch UpdateSearchTerm={UpdateSearchTerm} />
            {
              tickets.map(( announcement, index ) =>
                <MyTicketListingItem
                  setTicket={setTicket}
                  index={index}
                  data={announcement}
                />
              )
            }
          </>
        )}
    </div>
 );
}