import React from 'react';
import { Button } from "react-bootstrap"

export const CalendarHeader = ({ onNext, onBack, dateDisplay }) => {

  return(
    <div id="header">
      <div id="monthDisplay">{dateDisplay}</div>
      <div>
        <Button variant="secondary" onClick={onBack} id="backButton">Back</Button>

{/*         <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
 */}
        <Button Button variant="secondary" onClick={onNext} id="nextButton">Next</Button>
      </div>
    </div>
  );
};
