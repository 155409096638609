import React, { useState, useEffect } from 'react';
//import { storage } from "./../../../firebase"
import { database } from "../../../firebase" 

import { CalendarHeader } from './evCalendarHeader';
import { Day } from './evDay';
import { NewEventModal } from './evNewEventModal';
import { DeleteEventModal } from './evDeleteEventModal';
import { useDate } from './evUseDate';

import { useAuth } from "../../../contexts/AuthContext"

import './evCalendar.css'; 

const mCollectionEvents = database.events // FireStore Database

export default function EventCalendarApp() {

  const [dayEnabled, setDayEnabled] = useState(true);

  const { currentUser, logout } = useAuth()
  const [ready, setReady] = useState(false);

  const [eventsArr, setEventsArr] = useState([])

  const [nav, setNav] = useState(0);
  const [clicked, setClicked] = useState();
  const [clickedResList, setClickedResList] = useState([])
  const [events, setEvents] = useState(
    localStorage.getItem('events') ? 
      JSON.parse(localStorage.getItem('events')) : 
      []
  );

  const eventForDate = date => events.find(e => e.date === date);

//  const { days, dateDisplay } = useDate(events, nav);
  const { days, dateDisplay } = useDate(eventsArr, nav);

  useEffect(() => {
    console.log("Events INIT")

    /////////////////////////////////////////////////
    const unSub_Events = mCollectionEvents.onSnapshot(snapshot => {
      console.log("f14: mCollectionEvents: Starting: ")
      setReady(false)
      let tmpArr = []
      snapshot.docs.map( doc => {
        const tmpObj = { guid: doc.id, ...doc.data() }
        tmpArr.push(tmpObj)
      })
      setEventsArr(tmpArr)
      console.log("f14: mCollectionEvents: Completed: ",tmpArr)
    })
    /////////////////////////////////////////////////////
    const timer = setTimeout( () => {
      console.log("CLEARING LOADING SIGNALS ON TIMEOUT!") }
                                                                , 5000)
    return function cleanup() {
      unSub_Events()
      clearTimeout(timer)
    };
  }, [])

  useEffect(() => {
    console.log("Updating events array", )
    setReady(true)
  }, [eventsArr])

  const handleSaveReservation = (duration, start, checked, title) => 
  {
    const dateKey = clicked.date.replace("/", "-").replace("/", "-");
    const newObj = {
        title: title,
        slotsArr: checked
    }
  //  const reserveRef = firedb.database().ref(`sys_amenities_reservations/${name}/${dateKey}`);
  //  reserveRef.push(newObj);
//    console.log("Handling reservation: ",dateKey, newObj, name, clicked, duration, start, checked, title)
/*     const uploadJob = storage
      .ref(`sys_amenities_reservations/${name}/${clicked.date}`)
      .put(newObj)
    uploadJob.on(
      "state_changed",
      snapshot => {},
      error => {
        console.log(error)
      },
      () => {
        console.log("UPLOAD COMPLETE")
      }
    )  
 */  }

  const validateDateOnClick = (date) => 
  {
    let rtnval = true
    console.log("Day WAS CLICKED: ", date )

    if(rtnval) 
    {
/*       const dateKey = date.date.replace("/", "-").replace("/", "-");
      const reserveRef = firedb.database().ref(`sys_amenities_reservations/${name}/${dateKey}`);
      reserveRef.on('value', (snapshot) => {
        const dbs = snapshot.val();
        let resList = [];
        for (let id in dbs) {
          console.log("ITERATION KK: ",dbs[id])
          resList.push(...dbs[id].slotsArr);
        }
        console.log("REPORTING5: ",resList)
        setClickedResList(resList)
      });  
 */      setClicked(date);
    }

    return rtnval
  }

/*   useEffect(() => {
    localStorage.setItem('events', JSON.stringify(events));
  }, [events]);
 */

  return(
    <>
      <div id="container">
        <CalendarHeader 
          dateDisplay={dateDisplay}
          onNext={() => setNav(nav + 1)}
          onBack={() => setNav(nav - 1)}
        />

        <div id="weekdays">
          <div>Sunday</div>
          <div>Monday</div>
          <div>Tuesday</div>
          <div>Wednesday</div>
          <div>Thursday</div>
          <div>Friday</div>
          <div>Saturday</div>
        </div>

        <div id="calendar">
          {days.map((d, index) => (
            <Day
              key={index}
              day={d}
              onClick={() => {
                if (d.value !== 'padding') {
                  validateDateOnClick(d);
/*                   setClicked(d.date);
 */                }
              }}
            />
          ))}
        </div>
      </div>

      {
        clicked && dayEnabled && !eventForDate(clicked) &&
        <NewEventModal
          resList={clickedResList}
          onClose={() => setClicked(null)}
          date={clicked}
/*           onSave=handleSaveReservation{title => {
            setEvents([ ...events, { title, date: clicked }]);
            setClicked(null);
          }}
 */

          onSave={(duration, start, checked, title) => {
            handleSaveReservation(duration, start, checked, title)
          }}
        />
      }

      {
        clicked && eventForDate(clicked) &&
        <DeleteEventModal 
          eventText={eventForDate(clicked).title}
          onClose={() => setClicked(null)}
          onDelete={() => {
            setEvents(events.filter(e => e.date !== clicked));
            setClicked(null);
          }}
        />
      }
    </>
  );
};
