import React, {useState, useEffect} from 'react';
//import shave from 'shave';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useAuth } from "../../../../contexts/AuthContext"

import { storage, database } from "../../../../firebase"
import $ from 'jquery';

import './ConversationListItem.css';

export default function ConversationListItem(props) {
//  useEffect(() => {
//    shave('.conversation-snippet', 20);
//  })
    const [ isVisible, setIsVisible ] = useState(props.data.show)
    const [ toggleToolTip, setToggleToolTip ] = useState("")
    const index = props.index;
    const { currentUser } = useAuth()
    const { docID, id, subject, body } = props.data;
    const [ dispBody, setDispBody ] = useState(body)

    useEffect(() => {
      let str = body
      // https://stackoverflow.com/questions/28899298/extract-the-text-out-of-html-string-using-javascript
      var span = document.createElement('span');
      span.innerHTML = body;
      str = (span.textContent || span.innerText);
      if( str.length > 35 )
        str = str.substring(0, 35) + " ..."
      setDispBody(str)
      ///////  Preview HMTL 
/*       var div = document.createElement('div');
      div.setAttribute("id", "preview-node")
      div.innerHTML = body.trim();
      document.getElementById("preview").appendChild(div)
 */    }, [body])  

    useEffect(() => {
      isVisible ? (  // if true -> update to false
        setToggleToolTip("Announcement is Visible - click to Hide It")
      ) : (              // if false -> update to true
        setToggleToolTip("Announcement is Hidden - click to make it Visible")
      )
    }, [isVisible])  

    const handleSelectAnnouncement = () => {
      console.log("handleSelectAnnouncement: ",index, id)
      props.setConversation(index)
    }

    const ToggleShow = () => {
      console.log("ToggleShow: ",isVisible, props.data, docID)
      isVisible ? (  // if true -> update to false
        database.emails.doc(docID).update("show", false)
      ) : (              // if false -> update to true
        database.emails.doc(docID).update("show", true)
      )
      setIsVisible(!isVisible)
    }
  
    return (
      <div className="d-flex conversation-info">
        {currentUser.email === "gm@sixty01.com" && 
          <div>
            <Tooltip title={toggleToolTip}>
              <IconButton aria-label="show"
                onClick={() => ToggleShow()}>
                { isVisible?
                  <VisibilityIcon /> : 
                  <VisibilityOffIcon /> }
              </IconButton>
            </Tooltip>          
          </div> }
        <div className="conversation-list-item" onClick={() => handleSelectAnnouncement()}>
          <div className="conversation-info">
            <h1 className="conversation-title">{ subject }</h1>

{/*             <div id="preview">
            </div>
 */}
            <p className="conversation-snippet">{ dispBody }</p>
      </div>
        </div>
      </div>
    );
}


{/*         <img className="conversation-photo" src={photo} alt="conversation" />
 */} 
